import { useEffect, useState } from 'react';

import { useHasProtectedIdentity } from '@mindoktor/patient-app/adapters/hooks/useHasProtectedIdentity';
import { useFeatureFlag } from '@mindoktor/patient-app/utils/featureFlags';

import { useMarketingConsentApi } from './useMarketingConsentApi';

export const ALWAYS_ASK_MARKETING_CONSENT_FEATURE_FLAG =
  'always-ask-marketing-consent';

export const useShouldShowMarketingConsent = () => {
  const alwaysAskMarketingConsent = useFeatureFlag(
    ALWAYS_ASK_MARKETING_CONSENT_FEATURE_FLAG,
  );
  const marketingConsent = useMarketingConsentApi();
  const [shouldShow, setShouldShow] = useState(false);
  const hasProtectedIdentity = useHasProtectedIdentity();

  useEffect(() => {
    if (alwaysAskMarketingConsent.isEnabled) {
      return;
    }

    if (hasProtectedIdentity) {
      setShouldShow(false);
      return;
    }

    if (!marketingConsent.isLoading && marketingConsent.data != null) {
      const shouldAsk: boolean = marketingConsent.data.consents.reduce(
        (acc, { shouldAsk }) => acc || shouldAsk,
        false,
      );
      setShouldShow(shouldAsk);
    }
  }, [marketingConsent.isLoading, hasProtectedIdentity]);

  if (alwaysAskMarketingConsent.isEnabled) {
    return { data: true, isLoading: false };
  }

  return { data: shouldShow, isLoading: marketingConsent.isLoading };
};
