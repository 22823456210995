import {
  ArticleDrugSchema,
  PrescriptionDrugSchema,
} from '@mindoktor/aph/src/api/schema/drugSchema';
import { z } from 'zod';

import { MessageType } from '../models/conversation';

export const AuthorSchema = z.object({
  userId: z.number(),
  firstName: z.string().min(1).or(z.literal('')),
  lastName: z.string().min(1).or(z.literal('')),
  avatarURL: z.string().url().or(z.literal('')),
});

const BaseParticipantsSchema = z.object({
  userId: z.number().positive(),
  lastReadMessageId: z.number(),
});

const ParticipantsSchemaNoLastRead = BaseParticipantsSchema.extend({
  lastReadMessageId: z.literal(0),
  lastReadMessageTimestamp: z.null(),
});

const ParticipantsSchemaWithLastRead = BaseParticipantsSchema.extend({
  lastReadMessageId: z.number().positive(),
  lastReadMessageTimestamp: z.string().datetime(),
});

export const ParticipantsSchema = z.union([
  ParticipantsSchemaNoLastRead,
  ParticipantsSchemaWithLastRead,
]);

const BaseMessageSchema = z.object({
  id: z.number().positive(),
  author: z.optional(AuthorSchema).or(z.null()).or(z.undefined()),
  created: z.string().datetime(),
  type: z.nativeEnum(MessageType),
  payload: z.null(), // We can override this in the extended objects
});

// Type: GreetingCase
export const GreetingCaseMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.GreetingCase),
  payload: z.object({
    caseId: z.number().positive(),
    isMDK: z.boolean(),
  }),
});

// Type: ThankYou
export const ThankYouMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.ThankYou),
  payload: z.object({
    isChildCase: z.boolean(),
    /** @deprecated */
    isMDK: z.boolean(), // TODO: to be removed, no longer necessary
    caseId: z.number().positive(),
    entrywayId: z.number().optional(), // TODO: optional until we handle revisits from BE
    patientUUID: z.string().optional(), // TODO: optional until we handle revisits from BE
    revisitId: z.number().optional(), // TODO: optional until we handle revisits from BE
  }),
});

// Type: Freepass
export const FreepassMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.Freepass),
});

// Attachment schema for TextMessage
export const AttachmentSchema = z.object({
  fileId: z.number().positive(),
  fileReferenceNumber: z.number().positive().optional(),
});

// Type: TextMessage
export const TextMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.TextMessage),
  payload: z.object({
    content: z.string(),
    attachments: z.array(AttachmentSchema).optional(),
  }),
});

// Type: Invoice
export const InvoiceMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.Invoice),
  payload: z.object({
    url: z.string(),
  }),
});

// Type: Prescription
export const PrescriptionMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.Prescription),
  payload: z.object({
    drugs: z.array(PrescriptionDrugSchema),
    showInfoText: z.boolean(),
  }),
});

// Type: APH - Apoteket Hjärtat
export const APHMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.APH),
  payload: z.object({
    drugs: z.array(ArticleDrugSchema),
    showInfoText: z.boolean(),
  }),
});

// A Message is one of the definitions above based on its type
export const MessageSchema = z.discriminatedUnion('type', [
  TextMessageSchema,
  GreetingCaseMessageSchema,
  ThankYouMessageSchema,
  InvoiceMessageSchema,
  FreepassMessageSchema,
  PrescriptionMessageSchema,
  APHMessageSchema,
]);

export const ConversationResponseSchema = z.object({
  data: z.object({
    messages: z.array(MessageSchema),
    participants: z.array(ParticipantsSchema),
  }),
});
