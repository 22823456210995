import { useEffect, useState } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';

import {
  isValidFreePass,
  translateErrorMessage,
  validateFreePassExpireDate,
  validateFreePassIssuer,
  validateFreePassNumber,
} from './utils';
import { FreePassEditData, FreePassState } from './types';
import { submitFreePass } from './actions';
import { useAppDispatch } from '../hooks';

type Errors = { [key: string]: string };

/**
 * useFreePassValidation hook to handle freepass states while editing.
 */
export const useFreePassEdit = (
  freePass: FreePassState,
  onClose: () => void,
  submitCallback?: (freepass: FreePassEditData) => void,
  immediateValidation?: boolean
) => {
  const [currentFreePass, setCurrentFreePass] = useState(freePass);
  const [isValid, setIsValid] = useState(isValidFreePass(freePass));
  const [errors, setErrors] = useState<Errors>();
  const [submitted, setSubmitted] = useState(immediateValidation);
  const [isChanged, setIsChanged] = useState(false);

  const dispatch = useAppDispatch();

  const handleChange = (key: string, value: string) => {
    switch (key) {
      case 'issuer':
        setErrors({
          ...errors,
          [key]: translateErrorMessage(validateFreePassIssuer(value)),
        });
        break;
      case 'number':
        setErrors({
          ...errors,
          [key]: translateErrorMessage(validateFreePassNumber(value)),
        });
        break;
      case 'expires':
        setErrors({
          ...errors,
          [key]: translateErrorMessage(validateFreePassExpireDate(value)),
        });
        break;
      default:
        console.error('Non existing freepass change type');
    }
    setIsChanged(true);
    setCurrentFreePass({
      ...currentFreePass,
      [key]: value,
    });
  };

  useEffect(() => {
    if (!freePass?.hasError) return;
    // if there are error in the freepass sent from the server
    // we update the corresponding error
    switch (freePass.errorMessage) {
      case 'LNGFreePassInvalidIssuer':
        setErrors({
          ...errors,
          ['issuer']: _t('freePass.invalidIssuer'),
        });
        break;
      case 'LNGFreePassInvalidNumber':
        setErrors({
          ...errors,
          ['number']: _t('freePass.invalidNumber'),
        });
        break;
      case 'LNGFreePassInvalidExpires':
        setErrors({
          ...errors,
          ['expires']: _t('freePass.invalidExpires'),
        });
        break;
      case 'LNGFreePassExpired':
        setErrors({
          ...errors,
          ['expires']: _t('freePass.expired'),
        });
        break;
      default:
        break;
    }
  }, [freePass]);

  const validateFreePass = (freepass: FreePassState) => {
    const noExistingErrors = !errors
      ? true
      : Object.values(errors).every((val: string) => val === '');
    return (
      !!freepass?.expires &&
      !!freepass.issuer &&
      !!freepass.number &&
      noExistingErrors
    );
  };

  useEffect(() => {
    setIsValid(validateFreePass(currentFreePass));
  }, [currentFreePass]);

  const handleSubmit = async () => {
    if (!submitted || isValid) {
      if (isValid) {
        const freePassData = {
          // Ugly workaround until we find out issue with Select and '' vs '-' values.
          issuer:
            currentFreePass.issuer !== '-'
              ? (currentFreePass.issuer ?? '')
              : '',
          number: currentFreePass.number ?? '',
          expires: currentFreePass.expires ?? '',
        };

        const ok: boolean = await dispatch(
          submitFreePass({
            ...freePassData,
            type: 'sweden-freepass',
          })
        );

        if (ok) {
          onClose();
        }
        !!submitCallback &&
          submitCallback({
            ...freePassData,
            isValid: ok,
            isChanged,
          });
      } else {
        setErrors({
          ['issuer']: !!currentFreePass?.issuer
            ? translateErrorMessage(
                validateFreePassIssuer(currentFreePass.issuer)
              )
            : _t('freePass.invalidIssuer'),
          ['number']: !!currentFreePass?.number
            ? translateErrorMessage(
                validateFreePassNumber(currentFreePass.number)
              )
            : _t('freePass.invalidNumber'),
          ['expires']: !!currentFreePass?.expires
            ? translateErrorMessage(
                validateFreePassNumber(currentFreePass.expires)
              )
            : _t('freePass.invalidExpires'),
        });
      }
    }
    setSubmitted(true);
  };

  return {
    currentFreePass,
    isValid,
    submitted,
    errors,
    handleChange,
    handleSubmit,
  };
};
