import React from 'react';
import { DEFAULT_LOCALE } from '@mindoktor/localization/src/constants';
import LocaleProvider from '@mindoktor/localization/src/context/LocaleProvider';

import { resources } from '../resources';

const APHLocalizationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <LocaleProvider
      initialLocale={DEFAULT_LOCALE}
      languageResources={resources}
    >
      {children}
    </LocaleProvider>
  );
};

export default APHLocalizationProvider;
