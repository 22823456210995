// @ts-check

import base from './base';

/** @typedef {import("../../types").Config} Config */

const AppStoreAppId = '1104213750';

/** @type {Config} */
const Config = {
  ...base,

  ConfigName: 'se-test',
  IsDev: true,
  ShowVersionName: true,
  LoginWithEmailAndPassword: true,
  LoginEmail: 'test+kvinna@mindoktor.se',
  LoginPassword: 'asdf1234',

  Urls: {
    base: {
      domain: 'https://api.se.doclytest.io',
    },
    web: {
      domain: 'https://se.doclytest.io',
    },
    kiosk: {
      domain: 'https://kiosk.se.doclytest.io',
    },
    guides: {
      domain: 'https://guides.se.doclytest.io',
    },
    inquisitor: {
      domain: 'https://develop-inquisitor.now.sh',
    },
  },

  Locales: ['sv', 'en'],
  DefaultLocale: 'sv',
  ShowLocalePicker: true,

  AppStoreAppId,
  GooglePlayAppId: 'se.mindoktor.android',

  Tracking: true,

  SegmentIoKeys: {
    android: 'nUIqyuvef7O7LX38A77idthWc5IzzFQN',
    ios: 'nUIqyuvef7O7LX38A77idthWc5IzzFQN',
  },
};

export default Config;
