import { type ThemeOptions } from '@mui/material/styles';

import { desktopFonts, fonts } from '../../../fonts';

const overrides: ThemeOptions['components'] = {
  MuiTypography: {
    styleOverrides: {
      root: {},
      h1: {
        fontSize: fonts.h1.size,
        '@media (min-width:600px)': {
          fontSize: desktopFonts.h1.size,
        },
        lineHeight: fonts.h1.lineHeight,
        fontWeight: fonts.h1.weight,
      },
      h2: {
        fontSize: fonts.h2.size,
        '@media (min-width:600px)': {
          fontSize: desktopFonts.h2.size,
        },
        lineHeight: fonts.h2.lineHeight,
        fontWeight: fonts.h2.weight,
      },
      h3: {
        fontSize: fonts.h3.size,
        lineHeight: fonts.h3.lineHeight,
        fontWeight: fonts.h3.weight,
      },
      body1: {
        fontSize: fonts.body1.size,
        lineHeight: fonts.body1.lineHeight,
        fontWeight: fonts.body1.weight,
        '&.MuiTypography-gutterBottom': {
          marginBottom: `${fonts.body1.gutter}rem`,
        },
      },
      body2: {
        fontSize: fonts.body2.size,
        lineHeight: fonts.body2.lineHeight,
        fontWeight: fonts.body2.weight,
        '&.MuiTypography-gutterBottom': {
          marginBottom: `${fonts.body2.gutter}rem`,
        },
      },
    },
  },
};

const muiTypographyOverrides = overrides.MuiTypography;
export default muiTypographyOverrides;
