import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

import config from '@mindoktor/env/Config';

import { TIME } from '../../utils/time/constants';

const ThrottleWaitTime = 2 * TIME.SECOND;
const LastActivityCheckInterval = 10 * TIME.SECOND;

/**
 * useUserBrowserActivity listens for and stores user activity in a throttled manner.
 * It is responsible for flagging when the user gets inactive by checking last
 * registered activity.
 */
export const useUserBrowserActivity = () => {
  const [isActive, setIsActive] = useState(true);
  const lastActivityRef = useRef<number>(Date.now());

  const setLastActivityCheckIntervalIdRef = useRef<null | ReturnType<
    typeof setInterval
  >>(null);

  const updateLastActiveTime = (now: number) => {
    lastActivityRef.current = now;
  };

  useEffect(() => {
    const eventHandlerThrottled = throttle(
      () => {
        setIsActive(true);

        // Update the last active time for later comparison.
        updateLastActiveTime(Date.now());

        // Always clear the interval if there is one...
        if (setLastActivityCheckIntervalIdRef.current != null) {
          clearInterval(setLastActivityCheckIntervalIdRef.current);
        }

        // ...and immediately start a new interval
        setLastActivityCheckIntervalIdRef.current = setInterval(() => {
          const now = Date.now();
          // If the user has been inactive for a certain (UserActivityTimeout) time, flag it.
          if (now - lastActivityRef.current > config.UserActivityTimeout) {
            setIsActive(false);
          }
        }, LastActivityCheckInterval);
      },
      ThrottleWaitTime,
      { leading: true, trailing: true },
    );

    // To initialize the timeout for the first time.
    eventHandlerThrottled();

    // The events that we see as user activity. All these will reset the timer.
    const events = [
      'focus',
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
      'navigate',
    ];
    events.forEach((name) => {
      document.addEventListener(name, eventHandlerThrottled, true);
    });

    return () => {
      if (setLastActivityCheckIntervalIdRef.current != null) {
        clearInterval(setLastActivityCheckIntervalIdRef.current);
      }
      events.forEach((name) => {
        document.removeEventListener(name, eventHandlerThrottled);
      });
    };
  }, []);

  return {
    isActive,
  };
};
