import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import { caseReferralType } from '../../../../state/cases/constants';

import {
  loadReferralLabs,
  chooseReferralLab,
  centerMapOnUserHome,
} from '../../../../state/referrals/actions';
import { labLevels, labOrgGroups } from '../../../../state/referrals/constants';
import { getLabs, getNearestMDK } from '../../../../state/referrals/selectors';

import {
  getApiProfile,
  profileIdentityProtection,
} from '../../../../state/profile/selectors';

import { formatRoute, routes } from '../../../../state/routing';

import { showModal, hideModal } from '../../../routing';

import { getSiteOptions } from '../../../utils/site';

import Screen from '../../../components/screen';
import Button from '../../../components/button/button';
import Map from '../../../components/map';
import TruncatedFooter from '../../../components/footer/truncated';
import MediaQuery from '../../../components/media_query';
import BackBar from '../../../components/bar/back';

import ReferralLocationInfoModal from '../../modals/referral_location_info_modal';
import ReferralLabModal from '../../modals/referral_lab_modal';
import ReferralSupportModal from '../../modals/referral_support_modal';
import MinDoktorKlinikenModal from '../../../../common/cases/referrals/location/MinDoktorKlinikenModal';
import HiddenIdentityModal, {
  HiddenIdentityTextVariant,
} from '../../../helpcenter/HiddenIdentityModal';

import defaultMarkerIconSource from '../../../../../images/referrals/location_pin@3x.png';
import eReferralMarkerIconSource from '../../../../../images/referrals/location_pin_e@3x.png';
import apoteketMarkerIconSource from '../../../../../images/referrals/location_pin_apoteket@3x.png';
import mdkMarkerIconSource from '../../../../../images/referrals/location_pin_mdk@3x.png';
import insuranceGuaranteedMarkerIconSource from '../../../../../images/referrals/location_pin_insuranceok@3x.png';
import insuranceGuaranteedEReferralMarkerIconSource from '../../../../../images/referrals/location_pin_e_insuranceok@3x.png';
import insuranceNotGuaranteedMarkerIconSource from '../../../../../images/referrals/location_pin_noguarantee@3x.png';
import insuranceNotGuaranteedEReferralMarkerIconSource from '../../../../../images/referrals/location_pin_e_noguarantee@3x.png';
import supportIconSource from '../../../../../images/icon/icon_support@3x.png';
import { withRouting } from '@mindoktor/patient-app/routing/web';
import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';

const markerIconSize = { width: 34, height: 48 };

const styles = {
  body: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
  },

  legend: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  supportButton: {
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
  },

  supportIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
};

const getMarkerIconSource = (referralType, labOrgGroup, eReferrals, level) => {
  const selectIcon = () => {
    if (
      getSiteOptions().showLabGuaranteeLevel &&
      referralType === caseReferralType.LabTest
    ) {
      return level >= labLevels.GUARANTEED
        ? eReferrals
          ? insuranceGuaranteedEReferralMarkerIconSource
          : insuranceGuaranteedMarkerIconSource
        : eReferrals
          ? insuranceNotGuaranteedEReferralMarkerIconSource
          : insuranceNotGuaranteedMarkerIconSource;
    } else if (labOrgGroup === labOrgGroups.APOTEKET) {
      return apoteketMarkerIconSource;
    } else if (labOrgGroup === labOrgGroups.MDK) {
      return mdkMarkerIconSource;
    } else if (eReferrals) {
      return eReferralMarkerIconSource;
    }

    return defaultMarkerIconSource;
  };

  return getImageSrc(selectIcon());
};

// type Props = {
//   caseId: number,

//   referralType?: string,

//   labs: Array<Object>,

//   profileLat?: number,
//   profileLng?: number,

//   fetchLabs: () => any,
//   chooseLab: (labId: number) => any,

//   nearestMDK?: Object,
// };

export class LocationScreen extends Component {
  state = {
    height: 0,
    showInfoModal: false,
    mdkModalShown: false,
  };

  openCase = () => {
    const caseId = this.props.routing.params?.caseId;

    window.location = `#${formatRoute(routes.CASE_MESSAGES, { id: caseId })}`;
  };

  onBodyResize = (height) => this.setState({ height });

  chooseLab = async (labId) => {
    const { chooseLab } = this.props;

    await chooseLab(labId);

    this.openCase();
  };

  showLabModal = (lab = {}) => {
    const referralType = this.props.routing.queryParams?.get('referralType');
    const { id } = lab;
    showModal(ReferralLabModal, {
      ...lab,
      referralType,
      submit: () => this.chooseLab(id),
    });
  };

  showInfoModal = () => {
    // show infoModal about lab guarantee level
    if (getSiteOptions().showLabGuaranteeLevel) {
      this.setState({ showInfoModal: true });
    }
  };

  hideInfoModal = () => this.setState({ showInfoModal: false });

  handleSupportCenterClick = async () => {
    if (this.props?.hasIdentityProtection) {
      this.showHiddenIdentityModal();
      return;
    }
    this.showSupportModal();
  };

  showHiddenIdentityModal = () =>
    showModal(HiddenIdentityModal, {
      textVariant: HiddenIdentityTextVariant.LAB_REFERRAL,
    });
  showSupportModal = () => showModal(ReferralSupportModal);

  componentDidUpdate(prevProps) {
    if (
      this.props.nearestMDK &&
      this.props.nearestMDK !== prevProps.nearestMDK &&
      !this.state.mdkModalShown
    ) {
      this.setState({ mdkModalShown: true });
      showModal(MinDoktorKlinikenModal, { hideModal });
    }
  }

  componentDidMount() {
    const { fetchLabs } = this.props;

    fetchLabs();
    this.showInfoModal();
  }

  render() {
    const { routing, labs, profileLat, profileLng } = this.props;
    const { height } = this.state;
    const referralType = routing.queryParams?.get('referralType');

    return (
      <Screen
        header={
          <BackBar
            title={_t('referral.location.title')}
            dark={true}
            maxWidth={800}
            onBack={this.openCase}
          />
        }
        body={
          <div style={styles.body}>
            <Map
              initialPosition={
                profileLat && profileLng
                  ? {
                      lat: profileLat,
                      lng: profileLng,
                    }
                  : undefined
              }
              markers={labs.map((lab) => {
                const { level, coordinate, eReferrals, labOrgGroup } = lab;

                return {
                  icon: {
                    url: getMarkerIconSource(
                      referralType,
                      labOrgGroup,
                      eReferrals,
                      level
                    ),
                    scaledSize: markerIconSize,
                  },
                  position: {
                    lat: coordinate.latitude,
                    lng: coordinate.longitude,
                  },
                  onClick: () => this.showLabModal(lab),
                };
              })}
              style={{
                height,
                width: '100%',
              }}
            />

            <div style={styles.overlay}>
              <Button
                color="white"
                style={styles.supportButton}
                onClick={this.handleSupportCenterClick}
              >
                <Img src={supportIconSource} style={styles.supportIcon} />
                {_t('referral.location.contactSupport')}
              </Button>
            </div>

            {/* inline modal since theres additional modals on this page already, only displayed for partners with showLabGuaranteeLevel */}
            {this.state.showInfoModal && (
              <div style={styles.legend}>
                <ReferralLocationInfoModal
                  referralType={referralType}
                  hideModal={this.hideInfoModal}
                />
                ;
              </div>
            )}
          </div>
        }
        footer={
          <MediaQuery minWidth={801}>
            <TruncatedFooter />
          </MediaQuery>
        }
        onBodyResize={this.onBodyResize}
      />
    );
  }
}

const ReferralsLocationScreen = withRouting(
  connect(
    (state) => {
      const { lat: profileLat, lng: profileLng } = getApiProfile(state);

      return {
        labs: Object.values(getLabs(state) || []),
        hasIdentityProtection: profileIdentityProtection(state),
        profileLat,
        profileLng,

        nearestMDK: getNearestMDK(state),
      };
    },
    (dispatch, props) => {
      const caseId = props.routing.params?.caseId;
      const referralId = parseInt(props.routing.params?.referralId);
      return {
        fetchLabs: async () => {
          await dispatch(
            loadReferralLabs({
              caseId,
              messageId: referralId,
            })
          );
          dispatch(centerMapOnUserHome());
        },
        chooseLab: async (labId) =>
          await dispatch(
            chooseReferralLab({
              caseId,
              messageId: referralId,
              labId,
            })
          ),
      };
    }
  )(LocationScreen)
);

export default ReferralsLocationScreen;
