/**
 * Asserts that the code is not reachable.
 *
 * Useful to have in switch to guarantee exhaustive checking.
 *
 * @throws `Error` whenever it gets run.
 *
 * @see https://stackoverflow.com/a/39419171/2219492
 */
export const assertUnreachable = (_: never): never => {
  throw new Error('This code is supposed to be unreachable');
};

/**
 * Checks that the code is not reachable. If it ever is run, runs a provided callback.
 *
 * Useful to have in switch for exhaustive checking.
 *
 * @see https://stackoverflow.com/a/39419171/2219492
 */
function checkUnreachable<T extends never = never>(
  param: T,
  callback?: (param: T) => void,
): never;
function checkUnreachable<T = unknown>(
  param: T,
  callback?: (param: T) => void,
): null {
  callback?.(param);
  return null;
}

export { checkUnreachable };
