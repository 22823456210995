import React, { useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Button, Typography } from '@mui/material';

import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetActions from '@mindoktor/pulse/src/components/BottomSheetActions/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';
import MDLogoHeartIcon from '@mindoktor/pulse/src/icons/MDLogoHeartIcon/MDLogoHeartIcon.web';

import { useAuthentication } from '@mindoktor/patient-app/auth';
import { routes, useNavigation } from '@mindoktor/patient-app/routing/web';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutSheet: React.FC<Props> = ({ isOpen, onClose }) => {
  const [submitting, setSubmitting] = useState(false);
  const t = useTranslation('user', 'policy');
  const authentication = useAuthentication();
  const navigation = useNavigation();

  const onLogoutClick = async () => {
    setSubmitting(true);
    try {
      await authentication.logout();
      navigation.navigate(routes.LOGIN);
    } catch (error) {
      // let hook handle errors
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <BottomSheetHeader
        ariaLabelForClose={t('logoutHeader')}
        onClose={onClose}
        icon={<MDLogoHeartIcon sx={{ fontSize: '4rem' }} />}
      >
        <Typography variant="h2">{t('logoutHeader')}</Typography>
      </BottomSheetHeader>

      {/* Direction row-reverse here because if the layout goes into row layout,
          we want the hero button to the right otherwise we want it on top */}
      <BottomSheetActions direction={'row-reverse'}>
        <Button
          color="hero"
          onClick={() => {
            void onLogoutClick();
          }}
          variant="contained"
          disabled={submitting}
          sx={{ flex: 1 }}
        >
          {t('logout')}
        </Button>
        <Button
          color="primary"
          onClick={onClose}
          variant="outlined"
          disabled={false}
          sx={{ flex: 1 }}
        >
          {t('cancel')}
        </Button>
      </BottomSheetActions>
    </BottomSheet>
  );
};

export default LogoutSheet;
