import { DEFAULT_LOCALE, supportedLanguageLocaleMap } from './constants';
import { type SupportedLocale } from './types';

export const getDefaultLocaleForLanguage = (
  language: string,
): SupportedLocale => {
  const localeForLanguage = (
    supportedLanguageLocaleMap as Record<string, SupportedLocale | undefined>
  )[language];

  if (localeForLanguage == null) {
    console.warn(
      `Language ${language} not found, resorting on default language`,
    );
    return DEFAULT_LOCALE;
  }

  return localeForLanguage;
};
