/**
 * Breakpoint value between small and large screens.
 *
 * @example
 *   <MediaQuery maxWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT}>
 *    <SmallScreenBar />
 *   </MediaQuery>
 *   <MediaQuery minWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT + 1}>
 *     <LargeScreenBar />
 *   </MediaQuery>
 */
export const RESPONSIVE_SCREEN_WIDTH_BREAKPOINT = 800; // px
