import React, { useEffect, useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';
import { useQueryClient } from '@tanstack/react-query';

import { Box, Button, Stack, Typography } from '@mui/material';

import TextLink from '@mindoktor/pulse/src/components/TextLink/web';
import CheckmarkIcon from '@mindoktor/pulse/src/icons/CheckmarkIcon/CheckmarkIcon.web';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { useRedirectionNavigation } from '@mindoktor/patient-app/routing/hooks/useRedirectionNavigation';
import { getRouteWithRedirect } from '@mindoktor/patient-app/routing/utils/getRouteWithRedirect/web';
import {
  DataTrackingEvents,
  DataTrackingJourneyEvents,
} from '@mindoktor/patient-app/tracking/api/models/dataTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useDataTracking } from '@mindoktor/patient-app/tracking/hooks/useDataTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import { UserStatusStatuses } from '../../../api/models/userStatus';
import { useAcceptPrivacyMutator } from '../../../hooks/useAcceptPrivacyMutator';
import { useAcceptTosMutator } from '../../../hooks/useAcceptTosMutator';
import {
  userStatusQueryKey,
  useUserStatusApi,
} from '../../../hooks/useUserStatusApi';
import PrivacySheet from '../../PrivacySheet/web';
import TosSheet from '../../TosSheet/web';

const TosAndPrivacyContent: React.FC = () => {
  const [showTOSSheet, setShowTOSSheet] = useState(false);
  const [showPrivacySheet, setShowPrivacySheet] = useState(false);
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const redirectionNavigation = useRedirectionNavigation();

  const t = useTranslation('user', 'policy');
  const { data: userStatus } = useUserStatusApi(true);
  const { tosAccepted, privacyAccepted, contactDetailsComplete } = userStatus;

  const acceptTosMutator = useAcceptTosMutator();
  const acceptPrivacyMutator = useAcceptPrivacyMutator();

  const marketingTracking = useMarketingTracking();
  const dataTracking = useDataTracking();
  useEffect(() => {
    if (userStatus?.status === UserStatusStatuses.UserStatusPending) {
      void marketingTracking.track(
        MarketingTrackingActions.RegistrationStarted,
      );
      dataTracking.track(DataTrackingEvents.PageViewed, {
        journeyEvent: DataTrackingJourneyEvents.RegistrationStarted,
      });
    }
  }, [userStatus?.status]);

  const onConsentOrContinue = async () => {
    const sendConsents = async () => {
      if (!tosAccepted) {
        await acceptTosMutator.mutateAsync();
      }
      if (!privacyAccepted) {
        await acceptPrivacyMutator.mutateAsync();
      }
      // Invalidate here instead of in query hooks to avoid two invalidations.
      await queryClient.invalidateQueries([userStatusQueryKey]);
    };

    // If any consent is needed we send those
    await sendConsents();

    if (userStatus?.status === UserStatusStatuses.UserStatusLoginAllowed) {
      dataTracking.track(DataTrackingEvents.PageViewed, {
        journeyEvent: DataTrackingJourneyEvents.TOSPrivacyUpdated,
      });
    }

    if (contactDetailsComplete) {
      redirectionNavigation.redirect();
    } else {
      navigation.navigate(getRouteWithRedirect(routes.REGISTER));
    }
  };

  const Privacy = () => (
    <Typography variant="body1">
      {t('infoPrivacy')}
      <TextLink
        noUnderline
        onClick={() => {
          setShowPrivacySheet(true);
        }}
      >
        {t('linkPrivacy')}
      </TextLink>
      {'.'}
    </Typography>
  );

  const PrivacyUpdated = () => (
    <Typography variant="body1">
      {t('genericUpdated')}
      <TextLink
        noUnderline
        onClick={() => {
          setShowPrivacySheet(true);
        }}
      >
        {t('linkPrivacy')}
        {'. '}
      </TextLink>
      {t('infoPrivacyUpdated')}
    </Typography>
  );

  const TosFirstTime = () => (
    <Typography variant="body1">
      {t('infoTosFirstTime')}
      <TextLink
        noUnderline
        onClick={() => {
          setShowTOSSheet(true);
        }}
      >
        {t('linkTos')}
      </TextLink>
      {'.'}
    </Typography>
  );

  const TosUpdated = () => (
    <Typography variant="body1">
      {t('genericUpdated')}
      <TextLink
        noUnderline
        onClick={() => {
          setShowTOSSheet(true);
        }}
      >
        {t('linkTos')}
        {'. '}
      </TextLink>
      {t('infoTosUpdated')}
    </Typography>
  );

  const TosAccepted = () => (
    <Stack spacing="1rem" sx={{ marginTop: '2rem !important' }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            backgroundColor: colors.white,
            width: '2rem',
            minWidth: '2rem',
            height: '2rem',
            minHeight: '2rem',
            borderRadius: 16,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            marginRight: '0.5rem',
          }}
        >
          <CheckmarkIcon />
        </Box>
        <Typography variant="body1" sx={{ marginTop: '5px' }}>
          {t('consented')}
          <TextLink
            noUnderline
            onClick={() => {
              setShowTOSSheet(true);
            }}
          >
            {t('linkTos')}
          </TextLink>
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <Stack padding="2rem" paddingBottom={0} spacing="1rem" flexGrow={1}>
      {userStatus?.status === UserStatusStatuses.UserStatusPending ? (
        <>
          <Typography variant={isMobile ? 'h2' : 'h1'}>
            {t('header1')}{' '}
            <Typography
              component={'span'}
              variant={isMobile ? 'h2' : 'h1'}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('header2')}
            </Typography>
          </Typography>
          <Typography variant="body1">{t('subtitle')}</Typography>
        </>
      ) : (
        <Typography
          variant={isMobile ? 'h2' : 'h1'}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {t('safeCare')}
        </Typography>
      )}

      <Stack spacing="1rem" paddingBottom={'2rem'} flex={1} width="100%">
        {/*
            Conditions for the Privacy texts:
            - if this is a new user, show the info text.
            - if an old user and the privacy document is updated, show the update text.
            - if an old user and already seen (in BE/db - approved) it, show no text at all.
            */}
        {userStatus?.status === UserStatusStatuses.UserStatusPending && (
          <Privacy />
        )}
        {!privacyAccepted &&
          userStatus?.status === UserStatusStatuses.UserStatusLoginAllowed && (
            <PrivacyUpdated />
          )}

        {/*
            Conditions for the ToS texts:
            - if this is a new user who hasn't accepted it yet, show the first time info text.
            - if this is a new user who has accepted it already, show it as approved with
              a checkmark and link to the text.
            - if this is an old user and the TOS document has been updated in the meanwhile, show the updated text.
            */}
        {!tosAccepted &&
          userStatus?.status === UserStatusStatuses.UserStatusPending && (
            <TosFirstTime />
          )}
        {!tosAccepted &&
          userStatus?.status === UserStatusStatuses.UserStatusLoginAllowed && (
            <TosUpdated />
          )}

        {tosAccepted &&
          userStatus?.status === UserStatusStatuses.UserStatusPending && (
            <TosAccepted />
          )}

        <Stack flex={1} maxHeight={isMobile ? 'unset' : '8rem'} />

        {/* Only show a stepper ("Step 1 of 2") for new users */}
        {userStatus?.status === UserStatusStatuses.UserStatusPending && (
          <Typography
            variant="body2"
            color={colors.gray[900]}
            sx={{ alignSelf: 'center' }}
          >
            {t('stepper')}
          </Typography>
        )}

        <Button
          color="hero"
          onClick={() => {
            void onConsentOrContinue();
          }}
          variant="contained"
          disabled={false}
          sx={{ alignSelf: 'center', width: isMobile ? '100%' : '70%' }}
        >
          {tosAccepted ? t('continue') : t('consent')}
        </Button>
      </Stack>
      <TosSheet
        isOpen={showTOSSheet}
        onClose={() => {
          setShowTOSSheet(false);
        }}
      />

      <PrivacySheet
        isOpen={showPrivacySheet}
        onClose={() => {
          setShowPrivacySheet(false);
        }}
      />
    </Stack>
  );
};

export default TosAndPrivacyContent;
