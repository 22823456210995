import { useParams as useParamsWeb } from 'react-router-dom';

import { type ParamsDefaultType } from './types';

export const useParams = <
  TParams extends Record<string, unknown> = ParamsDefaultType,
>(): Readonly<TParams> => {
  const params = useParamsWeb() as Readonly<ParamsDefaultType>;
  return params as Readonly<TParams>;
};
