import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isUploading } from '../../../state/files/selectors';
import ContinueButton from '../../components/button/continue';
import UploadButton from '../../components/upload/button';

const styles = {
  root: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  upload: {
    flex: 1,
  },

  next: {
    marginLeft: 20,
  },
};

// type Props = {
//   uploading?: boolean,
//   answer?: any,
//   error?: string,
//   types?: string,
//   max?: number,
//   update: () => any,
//   next: () => any,
// };

export class Upload extends Component {
  upload = ({ fileId }) => {
    const { answer, update } = this.props;

    update([...(answer || []), fileId]);
  };

  render() {
    const { uploading, answer, max = 20, error, types, next } = this.props;

    const showUpload = !answer || answer.length < max;

    return (
      <div style={styles.root}>
        <div
          style={{
            ...styles.footer,
            justifyContent: showUpload ? 'space-between' : 'flex-end',
          }}
        >
          {showUpload && (
            <UploadButton
              types={types}
              max={answer ? max - answer.length : max}
              type={error ? 'solid' : 'ghost'}
              color="black"
              disabled={uploading}
              onUpload={this.upload}
              style={styles.upload}
              location={'formulary'}
            >
              {uploading
                ? _t('formulary.upload.uploading')
                : answer
                  ? error
                    ? _t('formulary.upload.uploadNext')
                    : _t('formulary.upload.uploadMore')
                  : _t('formulary.upload.uploadFirst')}
            </UploadButton>
          )}

          {!error && (
            <ContinueButton
              onClick={next}
              disabled={uploading}
              style={styles.next}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  uploading: isUploading(state),
}))(Upload);
