import {
  useMutation,
  type UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { messagingApi } from '../api/messaging';
import { type MarkLastReadRequest } from '../api/models/message';

import { CONVERSATION_QUERY_KEY } from './useConversationApi';

type MessageReadMutation = UseMutationResult<
  void,
  ResponseError,
  MarkLastReadRequest
>;

export const useMessageReadMutator = (
  conversationId: number | null,
): MessageReadMutation => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ messageId, readTime }) => {
      if (conversationId == null) {
        return undefined;
      }
      const response = await messagingApi.postMarkMessageRead(conversationId, {
        messageId,
        readTime,
      });
      if (!response.ok) {
        throw new ResponseError(response);
      }
    },

    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          conversationId,
          CONVERSATION_QUERY_KEY,
        ]);
      },
    },
  );
};
