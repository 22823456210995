import { marketingTrackingActionLabelMapping } from './models/growthAnalytics';
import {
  type MarketingAPI,
  type MarketingTrackingAction,
  marketingTrackingCategories,
} from './models/marketingTracking';
import {
  growthAnalyticsName,
  isGrowthAnalyticsWindow,
  loadGrowthAnalyticsScript,
} from './growthAnalyticsScript.web';

const initialize: MarketingAPI['initialize'] = (isEnabled) => {
  loadGrowthAnalyticsScript(isEnabled);
};

const isInitialized: MarketingAPI['isInitialized'] = () =>
  isGrowthAnalyticsWindow(window) && window.growthAnalytics.isInitialized();

export const getGrowthAnalytics = () => {
  if (
    !isGrowthAnalyticsWindow(window) ||
    !window.growthAnalytics.isInitialized()
  ) {
    console.warn(
      'Attempted accessing tracking when tracking was uninitialized.',
    );
    return undefined;
  }

  return window.growthAnalytics;
};

const identify: MarketingAPI['identify'] = (userMarketingId) => {
  const growthAnalytics = getGrowthAnalytics();
  if (growthAnalytics == null) {
    console.warn(
      `Tried identifying when ${growthAnalyticsName} was not ready.`,
    );
    return;
  }

  growthAnalytics.userLogin(userMarketingId);
};

const isMarketingTrackingActionLabelMappingKey = (
  action: MarketingTrackingAction,
): action is keyof typeof marketingTrackingActionLabelMapping => {
  return Object.keys(marketingTrackingActionLabelMapping).includes(action);
};

const track: MarketingAPI['track'] = async (action, options) => {
  const growthAnalytics = getGrowthAnalytics();
  if (growthAnalytics == null) {
    return;
  }

  // No other category is in use
  const category = options?.category ?? marketingTrackingCategories.System;
  const label =
    options?.label ??
    (isMarketingTrackingActionLabelMappingKey(action)
      ? marketingTrackingActionLabelMapping[action]
      : 'unknown');

  growthAnalytics.sendFunnelEvent(
    category,
    action,
    label,
    options?.additionalProperties,
  );
};

const cleanupSession: MarketingAPI['cleanupSession'] = () => {
  const growthAnalytics = getGrowthAnalytics();
  if (growthAnalytics == null) {
    return;
  }

  growthAnalytics.userLogout();
};

export const growthAnalyticsApi: MarketingAPI = {
  initialize,
  isInitialized,
  identify,
  track,
  cleanupSession,
};
