import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import { getFormularyState } from '../../../state/formulary/selectors';
import { toFormularyKey } from '../../../state/formulary/utils';
import { loadGuideQuestionsAndFreePass } from '../../../state/guides/actions';
import { getGuideByEntrywayId } from '../../../state/guides/selectors';
import { getHealthProfileFormularyKey } from '../../../state/healthprofile/selectors';
import { openRoute, routes as legacyRoutes } from '../../../state/routing';
import Spinner from '../../components/spinner/screen';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';

// type LoaderProps = {
//   entrywayId: number,
//   childUuid?: string,
//   revisitId?: number,
//   dynamicCode?: string,

//   loadedGuide?: boolean,
//   loadedFormulary?: boolean,
//   loadedHealthProfile?: boolean,

//   formularyKey?: string,
//   healthProfileKey?: string,

//   childComponent: any,

//   loadGuideQuestionsAndFreePass: typeof loadGuideQuestionsAndFreePass,
// };

class Loader extends Component {
  state = {};

  load = async () => {
    const {
      entrywayId,
      childUuid,
      revisitId,
      dynamicCode,
      inquisitionId,
      loadedFormulary,
      loadedHealthProfile,
      loadGuideQuestionsAndFreePass,
      openRoute,
    } = this.props;

    if (inquisitionId) {
      openRoute(
        legacyRoutes.INQUISITION,
        {
          entrywayId,
          childUuid,
          revisitId,
          dynamicCode,
        },
        { replace: true }
      );
      return;
    }

    if (!loadedFormulary || !loadedHealthProfile) {
      loadGuideQuestionsAndFreePass({
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
      });
    }
  };

  componentDidMount() {
    const { loadedGuide } = this.props;

    if (loadedGuide) {
      this.load();
    }
  }

  componentDidUpdate(prevProps) {
    const { loadedGuide } = this.props;
    const { loadedGuide: previouslyLoadedGuide } = prevProps;

    if (loadedGuide && !previouslyLoadedGuide) {
      this.load();
    }
  }

  render() {
    const {
      loadedFormulary,
      loadedHealthProfile,
      inquisitionId,
      formularyKey,
      healthProfileKey,
      childComponent: ChildComponent,
    } = this.props;

    if (inquisitionId) {
      return null;
    }

    return ChildComponent && loadedFormulary && loadedHealthProfile ? (
      <ChildComponent
        {...this.props}
        formularyKey={formularyKey}
        healthProfileKey={healthProfileKey}
      />
    ) : (
      <Spinner />
    );
  }
}

const EnhancedLoader = compose(
  withRouting,
  connect(
    (state, props) => {
      const {
        params: { entrywayId },
        queryParams,
      } = props.routing;

      const guide = getGuideByEntrywayId(state, entrywayId);
      const dynamicCode = queryParams.get('dynamicCode');
      const childUuid = queryParams.get('childUuid');
      const revisitId = Number(queryParams.get('revisitId'));

      if (!guide) return {};

      const {
        formularyName: formularyId,
        formularyVersion,
        inquisitionId,
      } = guide;

      const formularyKey = toFormularyKey({
        entrywayId,
        formularyId,
        formularyVersion,
        childUuid,
      });

      const healthProfileKey = getHealthProfileFormularyKey(state, childUuid);

      return {
        loadedGuide: true,
        loadedFormulary: !!getFormularyState(state, formularyKey),
        loadedHealthProfile: !!getFormularyState(state, healthProfileKey),

        formularyKey,
        healthProfileKey,

        inquisitionId,
        entrywayId,
        dynamicCode,
        childUuid,
        revisitId,
      };
    },
    { loadGuideQuestionsAndFreePass, openRoute }
  )
)(Loader);

export default (childComponent) => (props) => (
  <EnhancedLoader {...props} childComponent={childComponent} />
);
