import { _t } from '@mindoktor/patient-legacy/i18n';
import throttle from 'lodash-es/throttle';
import React, { Component } from 'react';

import iconCancel from '../../../../images/generated/ic_close@4x.png';
import iconBack from '../../../../images/icon/ic_back@4x.png';
import colors from '../../../common/colors';
import { Body1, Body3, SubHead } from '../../../common/components/typography';
import MediaQuery from '../../components/media_query';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  top: {
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
    height: 58,
  },

  topWide: {
    paddingLeft: 40,
    paddingRight: 40,
    height: 68,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },

  left: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 40,
    height: 40,
    marginRight: 10,
    cursor: 'pointer',
  },

  cancelIcon: {
    width: 22,
    height: 22,
  },

  middle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  title: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },

  minutesLeft: {
    textAlign: 'center',
    color: '#888',
  },

  draftSaved: {
    fontWeight: 'bold',
    color: colors.green_500,
  },

  right: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginLeft: 10,
  },

  imageIcon: {
    width: 40,
    height: 40,
  },

  bottom: {
    height: 2,
    backgroundColor: '#EDEDED',
  },

  progress: {
    height: 2,
    backgroundColor: colors.red_500,
  },
};

const iconMap = {
  cancel: iconCancel,
  back: iconBack,
};

// type Props = {
//   title: string,
//   iconSource?: any,
//   maxWidth?: number,
//   onCancel?: () => any,
//   progress?: number,
//   savedAt?: number,
//   backgroundColor: string,
// };

// type State = {
//   showDraftSaved: boolean,
// };

export default class Bar extends Component {
  static defaultProps = {
    title: '',
    backgroundColor: 'white',
  };

  state = {
    showDraftSaved: false,
  };

  showDraftSaved = throttle(() => {
    this.setState({ showDraftSaved: true });

    setTimeout(() => this.setState({ showDraftSaved: false }), 3000);
  }, 30000);

  componentDidUpdate(prevProps) {
    const { savedAt: previouslySavedAt } = prevProps;
    const { savedAt } = this.props;

    if (savedAt !== previouslySavedAt) {
      this.showDraftSaved();
    }
  }

  componentWillUnmount() {
    this.showDraftSaved.cancel();
  }

  render = () => {
    const {
      title,
      icon = 'cancel',
      iconSource,
      maxWidth,
      onCancel,
      progress,
      backgroundColor,
    } = this.props;
    const { showDraftSaved } = this.state;

    const innerComponent = (
      <div style={styles.inner}>
        <div
          style={styles.left}
          onClick={onCancel}
          data-test-id="header-close-button"
        >
          <Img
            src={iconMap[icon] || iconMap.cancel}
            style={styles.cancelIcon}
          />
        </div>

        <div style={styles.middle}>
          <MediaQuery maxWidth={800}>
            <Body1 style={styles.title}>{title}</Body1>
          </MediaQuery>

          <MediaQuery minWidth={801}>
            <SubHead style={styles.title}>{title}</SubHead>
          </MediaQuery>

          {showDraftSaved && (
            <Body3 style={styles.draftSaved}>
              {_t('formulary.bar.savedDraft')}
            </Body3>
          )}
        </div>

        <div style={styles.right}>
          {!!iconSource && <Img src={iconSource} style={styles.imageIcon} />}
        </div>
      </div>
    );

    return (
      <div style={{ backgroundColor }}>
        <MediaQuery maxWidth={800}>
          <div
            style={{
              ...styles.top,
              maxWidth: maxWidth ? maxWidth - 40 : undefined,
            }}
          >
            {innerComponent}
          </div>
        </MediaQuery>

        <MediaQuery minWidth={801}>
          <div
            style={{
              ...styles.top,
              ...styles.topWide,
              maxWidth: maxWidth ? maxWidth - 80 : undefined,
            }}
          >
            {innerComponent}
          </div>
        </MediaQuery>

        <div style={styles.bottom}>
          {!!progress && (
            <div
              style={{
                ...styles.progress,
                width: progress * 100 + '%',
              }}
            />
          )}
        </div>
      </div>
    );
  };
}
