import React from 'react';

import {
  Box,
  Stack,
  type StackProps,
  type SxProps,
  type Theme,
} from '@mui/material';

import MDLogoHeartIcon from '@mindoktor/pulse/src/icons/MDLogoHeartIcon/MDLogoHeartIcon.web';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';

import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import maskDesktop from '../../../images/mask-desktop.png';
import maskMobile from '../../../images/mask-phone-normal.png';

const maskDesktopSrc = getImageSrc(maskDesktop);
const maskMobileSrc = getImageSrc(maskMobile);

interface Props {
  children: React.ReactNode;
  imgSrcDesktop: string;
  imgSrcMobile: string;
}

type AllProps = Props &
  Omit<StackProps, 'sx' | 'flexGrow' | 'height' | 'position' | 'overflow'>;

const BubbleScreenLayout: React.FC<AllProps> = ({
  children,
  imgSrcDesktop,
  imgSrcMobile,
  ...stackProps
}) => {
  return (
    <Stack
      id="bubblescreen-root"
      {...stackProps}
      flexGrow={1}
      position={'relative'}
      overflow={'hidden'}
    >
      <Box
        sx={[
          ...(Array.isArray(classes.bubbleImage)
            ? classes.bubbleImage
            : [classes.bubbleImage]),
          {
            backgroundImage: `url(${isMobile ? imgSrcMobile : imgSrcDesktop})`,
          },
        ]}
      />

      <Box sx={classes.bubbleCircle} />

      <MDLogoHeartIcon sx={classes.heart} />

      {children}
    </Stack>
  );
};
export default BubbleScreenLayout;

const classes: Record<string, SxProps<Theme>> = {
  heart: ({ breakpoints: { down, up } }) => ({
    position: 'absolute',
    top: '21vh',
    left: '10vw',
    fontSize: '5rem',
    [up('sm')]: {
      display: 'none',
    },
  }),

  // Masked image top right on the screen
  bubbleImage: ({ breakpoints: { down, up } }) => ({
    position: 'absolute',
    top: 0,
    width: '70vw',
    height: '30vh',
    left: '30vw',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    maskImage: `url(${maskDesktopSrc})`,
    maskSize: '130% 100%',
    zIndex: -1,

    [down('sm')]: {
      width: '100%',
      left: 0,
      backgroundPosition: 'top center',
      maskImage: `url(${maskMobileSrc})`,
      maskSize: '100% 100%',
    },
  }),

  // pink bubble to the bottom left in the screen
  bubbleCircle: ({ breakpoints: { down, up } }) => ({
    position: 'absolute',
    top: '50vh',
    height: '100vw',
    width: '100vw',
    left: '-55vw',
    right: '45vw',
    backgroundColor: colors.red[100],
    borderRadius: '100vw',
    zIndex: -1,

    [down('sm')]: {
      top: '45vh',
      height: '75vh',
      width: '75vh',
      left: 'unset',
      right: '15vw',
      borderRadius: '100vh',
    },
  }),
};
