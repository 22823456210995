import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Box, IconButton, Modal, Stack } from '@mui/material';

import ImageWithLoader from '@mindoktor/pulse/src/components/ImageWithLoader/ImageWithLoader.web';
import CloseIcon from '@mindoktor/pulse/src/icons/CloseIcon/CloseIcon';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { rem } from '@mindoktor/pulse/src/styles/conversions';

import { getFileUri } from '@mindoktor/patient-app/files/api/utils';
import { useNotifications } from '@mindoktor/patient-app/notifications';

import { type FullscreenImageModalProps } from './types';

const FullscreenImageModal: React.FC<FullscreenImageModalProps> = ({
  attachment,
  isOpen,
  onClose,
}) => {
  const notifications = useNotifications();
  const t = useTranslation('messaging', 'attachment');

  const onError = () => {
    notifications.pushError(t('loadingImageError'));
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
      closeAfterTransition
    >
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Box maxHeight="75%">
          <ImageWithLoader
            src={getFileUri(attachment.fileId)}
            alt={t('altImageText')}
            height="100%"
            maxWidth="100%"
            maxHeight="100%"
            onError={onError}
          />
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: rem(1),
            right: rem(1),
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          }}
        >
          <CloseIcon fontSize={1} color={colors.black} />
        </IconButton>
      </Stack>
    </Modal>
  );
};

export default FullscreenImageModal;
