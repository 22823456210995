import React, { Component } from 'react';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import { Body1 } from '../../common/components/typography';
import constants from '../../common/constants';
import LimitedHtml from '../components/html/limited';

const styles = {
  inner: {
    paddingBottom: constants.spacing12,
  },

  label: {
    paddingBottom: constants.spacing12,
    fontWeight: 500,
  },

  prologue: {
    paddingBottom: constants.spacing12,
  },

  description: {
    paddingBottom: constants.spacing12,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
  },

  icon: {
    width: 35,
    height: 27,
    marginRight: constants.spacing16,
  },
};

// type Props = {
//   icon?: any,
//   prologue?: string,
//   label?: string,
//   description?: string,
// };

export default class QuestionnaireHeader extends Component {
  render() {
    const { icon, prologue, label, description } = this.props;

    return (
      <div style={styles.header}>
        {!!icon && (
          <div>
            <Img style={styles.icon} src={icon} />
          </div>
        )}
        <div style={styles.inner}>
          {!!prologue && (
            <LimitedHtml html={prologue} style={styles.prologue} />
          )}

          {!!label && (
            <Body1
              style={{
                ...styles.label,
              }}
            >
              {label}
            </Body1>
          )}

          {!!description && (
            <LimitedHtml html={description} style={styles.description} />
          )}
        </div>
      </div>
    );
  }
}
