import { getIndependentCareAgeFromHost } from '@mindoktor/patient-legacy/src/state/api/selectors';
import { getResumeCaseInfo } from '@mindoktor/patient-legacy/src/state/cases/selectors';

import { type LegacyCase } from '../types';

import { useLegacySelector } from './legacyState';
import { useUserProfile } from './useUserProfile';

interface ResumeCaseData {
  entrywayId: number;
  patientUUID: string;
  revisitId: number;
}

export const useResumeCase = (case_: LegacyCase): ResumeCaseData | null => {
  const independentCareAge = useLegacySelector(({ api }) => {
    if (api == null) {
      return null;
    }
    return getIndependentCareAgeFromHost({ api });
  });
  const guideEntrywayIds = useLegacySelector(
    (state) => state?.guides?.guideEntrywayIds,
  );

  const userProfile = useUserProfile();

  if (
    case_ == null ||
    independentCareAge == null ||
    guideEntrywayIds == null ||
    userProfile?.invitationsById == null ||
    userProfile?.invitationsByParentCaseId == null
  ) {
    return null;
  }

  const resumeCaseInfo = getResumeCaseInfo(
    userProfile?.invitationsByParentCaseId,
    userProfile?.invitationsById,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    case_,
    guideEntrywayIds,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    independentCareAge,
  );

  if (resumeCaseInfo == null) {
    return null;
  }

  return {
    entrywayId: resumeCaseInfo.entrywayId,
    patientUUID: resumeCaseInfo.patientUUID,
    revisitId: resumeCaseInfo.revisitId ?? 0,
  };
};
