import React, { useEffect } from 'react';

import { Box, Card } from '@mui/material';
import Stack, { type StackProps } from '@mui/material/Stack';

import MDLogoFullIcon from '@mindoktor/pulse/src/icons/MDLogoFullIcon/MDLogoFullIcon';
import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';
import { useBreakpointStyle } from '@mindoktor/pulse/src/utils/mediaQueries/useBreakpointStyle';

import { FixedContentLayout } from '@mindoktor/patient-app/layout/web';
import {
  DataTrackingAppStates,
  DataTrackingEvents,
  DataTrackingJourneyEvents,
} from '@mindoktor/patient-app/tracking/api/models/dataTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useDataTracking } from '@mindoktor/patient-app/tracking/hooks/useDataTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { useAuthorization } from '@mindoktor/patient-app/user/hooks/useAuthorization';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import backgroundImage from '../../../images/login_background.webp';
import backgroundImageMobile from '../../../images/login_background_mobile.webp';
import LoginCardContentDesktop from '../../LoginCardContentDesktop/web';
import LoginCardContentMobile from '../../LoginCardContentMobile/web/LoginCardContentMobile';

const backgroundImageSrc = getImageSrc(backgroundImage);
const backgroundImageMobileSrc = getImageSrc(backgroundImageMobile);

const LoginScreen: React.FC = () => {
  const authorization = useAuthorization();
  const marketingTracking = useMarketingTracking();
  const dataTracking = useDataTracking();
  const { matches } = useBreakpointStyle();
  useEffect(() => {
    if (!authorization.isAuthorized) {
      void marketingTracking.track(MarketingTrackingActions.LoginStarted);
      dataTracking.track(DataTrackingEvents.PageViewed, {
        state: DataTrackingAppStates.login,
        journeyEvent: DataTrackingJourneyEvents.LoginStarted,
      });
    }
  }, []);

  return (
    <FixedContentLayout showFooter={false} showMenuBarActions={false}>
      {isMobile ? (
        // On mobile we use a bottom sheet like design.
        <FixedBackgroundStack
          id="loginscreen-fixed-bgstack"
          justifyContent="flex-end"
        >
          <Card
            id="loginscreen-card"
            sx={[
              {
                '&.MuiPaper-root': {
                  padding: '2rem',
                  boxSizing: 'border-box',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
                width: '100%',
                minHeight: '55%',
                display: 'flex',
              },
            ]}
          >
            <LoginCardContentMobile />
          </Card>
        </FixedBackgroundStack>
      ) : (
        // On desktop, use a centered box design.
        <>
          <FixedBackgroundStack
            id="loginscreen-fixed-bgstack"
            justifyContent="center"
          >
            <Card
              id="loginscreen-card"
              sx={({ breakpoints: { up, down } }) => ({
                '&.MuiPaper-root': {
                  padding: '2rem',
                  boxSizing: 'border-box',
                },
                width: '440px',
                maxHeight: '790px',
                minHeight: '580px',
                marginY: '1rem',
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                zIndex: 1,
                marginLeft: 'clamp(5rem, 8vw, 8vw)',
                [up('md')]: {
                  marginLeft: 'clamp(6rem, 8vw, 8vw)',
                },
              })}
            >
              <LoginCardContentDesktop />
            </Card>
          </FixedBackgroundStack>
          <Stack
            sx={{
              position: 'absolute',
              right: '3rem',
              bottom: { md: '2rem', sm: '1rem', xs: '0.5rem' },
              height: '5rem',
              justifyContent: 'center',
              overflowY: 'hidden',
            }}
          >
            <MDLogoFullIcon
              fontSize={matches.md ? 4 : matches.sm ? 3.15 : 2.5}
              shadowElevation={6}
              shadowColor="rgba(0, 0, 0, 0.25)"
            />
          </Stack>
        </>
      )}
    </FixedContentLayout>
  );
};

export default LoginScreen;

const FixedBackgroundStack: React.FC<
  { children: React.ReactNode } & Omit<StackProps, 'sx' | 'flexGrow' | 'height'>
> = ({ children, ...stackProps }) => {
  return (
    <Stack
      {...stackProps}
      flexGrow={1}
      height="50%"
      sx={
        // On desktop, the background image covers the whole screen
        !isMobile
          ? {
              backgroundImage: `url(${backgroundImageSrc})`,
              backgroundPosition: 'right center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : {}
      }
    >
      {/* On mobile, we want the image to be stacked above the bottomsheet content */}
      {isMobile && (
        <Box
          sx={{
            backgroundImage: `url(${backgroundImageMobileSrc})`,
            backgroundPosition: 'center top 33%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            flex: 1,
            marginBottom: '-1rem',
          }}
        />
      )}

      {children}
    </Stack>
  );
};
