import React, { useEffect, useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import {
  Button,
  Stack,
  type SxProps,
  type Theme,
  Typography,
} from '@mui/material';

import TextLink from '@mindoktor/pulse/src/components/TextLink/web';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useRedirectionNavigation } from '@mindoktor/patient-app/routing/hooks/useRedirectionNavigation';
import {
  DataTrackingEvents,
  DataTrackingJourneyEvents,
} from '@mindoktor/patient-app/tracking/api/models/dataTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useDataTracking } from '@mindoktor/patient-app/tracking/hooks/useDataTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import {
  type BankIDLoginMode,
  BankIDLoginModes,
} from '../../../api/models/bankid';
import { BankIDStatuses } from '../../../api/schema/bankid';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { useAuthErrorNotifications } from '../../../hooks/useAuthErrorNotifications';
import { useBankIDLogin } from '../../../hooks/useBankIDLogin';
import BankIDIcon from '../../BankIDIcon/BankIDIcon';
import LoginModeDevice from '../../LoginModeDevice/web';
import LoginModeQRCode from '../../LoginModeQRCode/web';

interface Props {
  onLoginProcessChange?: (isOngoing: boolean) => void;
}

const BankIDLogin: React.FC<Props> = ({ onLoginProcessChange }) => {
  // BankID login initiated
  const [isLoginProcessOngoing, setIsLoginProcessOngoing] = useState(false);
  const [loginMode, setLoginMode] = useState<BankIDLoginMode>(
    isMobile ? BankIDLoginModes.DEVICE : BankIDLoginModes.QRCODE,
  );
  const t = useTranslation('auth');
  const authError = useAuthErrorNotifications(loginMode);
  const authentication = useAuthentication();

  const { start, stop, bankIdStatus, bankIdHintCode, error, qrData } =
    useBankIDLogin();
  const redirectionNavigation = useRedirectionNavigation();
  const marketingTracking = useMarketingTracking();
  const dataTracking = useDataTracking();

  // Starting on desktop - default to QR code and polling
  const startBankIDLogin = () => {
    setIsLoginProcessOngoing(true);
    start(loginMode);
  };

  const stopBankIdLogin = () => {
    stop();
    setIsLoginProcessOngoing(false);
    setLoginMode(isMobile ? BankIDLoginModes.DEVICE : BankIDLoginModes.QRCODE);
  };

  const startQRCodeLogin = () => {
    stop();
    setLoginMode(BankIDLoginModes.QRCODE);
    setIsLoginProcessOngoing(true);
    start(BankIDLoginModes.QRCODE);
  };

  const startDeviceLogin = () => {
    stop();
    setLoginMode(BankIDLoginModes.DEVICE);
    setIsLoginProcessOngoing(true);
    start(BankIDLoginModes.DEVICE);
  };

  useEffect(() => {
    if (error != null) {
      stopBankIdLogin();
      authError.show(error);
    }
  }, [error, stop]);

  useEffect(() => {
    onLoginProcessChange?.(isLoginProcessOngoing);
  }, [isLoginProcessOngoing]);

  useEffect(() => {
    if (bankIdStatus === BankIDStatuses.COMPLETE) {
      void marketingTracking.track(MarketingTrackingActions.LoginCompleted);
      dataTracking.track(DataTrackingEvents.PageViewed, {
        journeyEvent: DataTrackingJourneyEvents.LoginCompleted,
      });
    }
  }, [bankIdStatus]);

  useEffect(() => {
    if (authentication.isAuthenticated) {
      redirectionNavigation.redirect();
    }
  }, [authentication.isAuthenticated]);

  return (
    <Stack flex="1" alignItems="center">
      <BankIDIcon fontSize={4} marginBottom={1.5} />

      {!isLoginProcessOngoing && (
        <Stack
          flex="1"
          alignItems="center"
          justifyContent="center"
          marginTop="-3rem"
        >
          <Button
            color="hero"
            onClick={startBankIDLogin}
            variant="contained"
            disabled={false}
            sx={classes.startButton}
          >
            {t('bankid.login')}
          </Button>

          {isMobile && (
            <>
              <Typography
                variant="body2"
                color={colors.gray[900]}
                fontSize={14}
                marginBottom="1.5rem"
                textAlign={'center'}
              >
                {t('bankid.autoStartInfo')}
              </Typography>
              <Stack flex={1} justifyContent={'flex-end'}>
                <TextLink
                  noUnderline
                  onClick={startQRCodeLogin}
                  sx={{ marginBottom: 1 }}
                >
                  {t('bankid.withOtherDevice')}
                </TextLink>
              </Stack>
            </>
          )}
        </Stack>
      )}

      {isLoginProcessOngoing && loginMode === BankIDLoginModes.QRCODE && (
        <LoginModeQRCode
          qrCode={qrData}
          signing={bankIdHintCode === 'userSign'}
          complete={bankIdStatus === BankIDStatuses.COMPLETE}
          onClose={stopBankIdLogin}
          onThisDeviceClick={startDeviceLogin}
        />
      )}

      {isLoginProcessOngoing && loginMode === BankIDLoginModes.DEVICE && (
        <LoginModeDevice
          signing={bankIdHintCode === 'userSign'}
          complete={bankIdStatus === BankIDStatuses.COMPLETE}
          onClose={stopBankIdLogin}
        />
      )}
    </Stack>
  );
};

export default BankIDLogin;

const classes: Record<string, SxProps<Theme>> = {
  startButton: ({ breakpoints: { down, up } }) => ({
    paddingX: '2rem',
    alignSelf: 'center',
    marginTop: isMobile ? '3rem' : 0,
    marginBottom: isMobile ? '1.5rem' : 0,
  }),
};
