// @ts-check

/** @typedef {import("../../types").BaseConfig} BaseConfig */

/** @type {BaseConfig} */
const config = {
  DefaultSite: 'mindoktor',
  InstallURIIos: 'https://itunes.apple.com/se/app/mindoktor/id1104213750?mt=8',
  InstallURIAndroid: 'market://details?id=se.mindoktor.android',

  ConfigName: 'se-base',

  // Dev props
  IsDev: false,
  StayLoggedIn: false,
  LoginWithEmailAndPassword: false,
  LoginEmail: '',
  LoginPassword: '',
  LocalMode: false,
  ShowVersionName: false,
  NavigateTo: null,

  Locales: ['sv'],
  DefaultLocale: 'sv',
  ShowLocalePicker: false,

  ShowSimpleFooter: false,
  KillSwitchEnabled: false,

  Tracking: false,

  RequireChoosingSite: false,

  ShowContactAddressField: true,
  ShowContactCityField: true,
  ShowBirthDate: true,

  ShowFreePass: true,
  ShowSupportLink: true,
  ShowComplaintsLink: true,

  ShowPaymentConsultationWarning: false,

  ShowCasePrescriptionMessage: true,

  HideGuideCategoryPrescription: false,
  ChildMaxAge: 15,
  ShowChildNewsletterCard: true,
  HideGuideCategoryAdult: false,

  RequireMedicalComplaintsInfo: true,

  HowItWorksPath: 'sa-funkar-det',
  MedicalComplaintsPath: 'synpunkter-och-klagomal',
  ContactUsPath: 'kontakta-oss',

  GoogleMapsApiKey: 'AIzaSyCPt-DfO3gch-bYjK81Fsrf9krOm2mjjms',

  MessagesPollInterval: 30000,

  HomeScreen: {
    ShowHowItWorksLink: true,
  },

  UseDoclyPolicy: false,
  UseDoclyNotificationInfo: false,

  SSNType: 1,
  FaqFile: 'se',
  FormularyQuickChoice: false,

  WebViews: {
    HowItWorks: false,
  },

  ServiceWebHomeName: 'mindoktor.se',
  DefaultIndependentCareAge: 18,
  ShowVaccinationBook: true,

  AppStoreAppId: '',
  GooglePlayAppId: '',

  SentryEnabled: false,
  SentryDsnAndroid:
    'https://1651a92e62744f048d81a8b35db014a3@o1128022.ingest.sentry.io/6170301',
  SentryDsnIos:
    'https://9d7a0b3f88fe458b9f8bb62817170ab8@o1128022.ingest.sentry.io/6501862',

  ApotekHjartat: {
    clientId: 'Dvr4nYbatxJydYZOCNJSY8ugxYga',
    key: '8_CtWTdtVZqZtN8iS6c8fyx3duca',
    authURL: 'https://ims.icagruppen.se/oauth/v2',
    productUrl:
      'https://apimgw-pub.ica.se:443/hjartat/ecom/product/v1/product/v1',
    pharmacyApiURL:
      'https://apimgw-pub.ica.se/hjartat/ecom/pharmacy/v1.0/pharmacy/v1',
    webStoreId: '1186',
  },

  /** Time before the user is logged out if inactive */
  UserActivityTimeout: 15 * 60 * 1000,
  PrefillFormulary: false,
  SegmentIoKeys: null,
  HideGuideCategoryChild: false,
};

export default config;
