import { type ThemeOptions } from '@mui/material/styles';

import { colors } from '../../../colors';

const overrides: ThemeOptions['components'] = {
  MuiInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        padding: '0.75rem 1rem',
        backgroundColor: colors.white,
        boxShadow:
          '0px 1px 4px rgba(0, 0, 0, 0.06), 0px 2px 12px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        caretColor: colors.red[500],
        fontSize: 16,
      },
    },
  },
};

const muiInputOverrides = overrides.MuiInput;
export default muiInputOverrides;
