import {
  useAppDispatch,
  useAppSelector,
} from '@mindoktor/patient-legacy/src/state/hooks';

import { type LegacyState } from '../types';

type LegacySelector<TSelectedValue = unknown> = (
  legacyState: LegacyState,
) => TSelectedValue;

export const useLegacySelector = <TSelectedValue = unknown>(
  selector: LegacySelector<TSelectedValue>,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
): TSelectedValue => useAppSelector(selector as any);

export const useLegacyDispatch = useAppDispatch;
