import React, { useRef } from 'react';

import ScrollDownButton from '@mindoktor/pulse/src/components/ScrollDownButton/web';
import ScrollView, {
  type ScrollViewProps,
} from '@mindoktor/pulse/src/components/ScrollView/web';
import { rem } from '@mindoktor/pulse/src/styles/conversions';

import { useOnLayout } from '../../../utils/layout/useOnLayout/useOnLayout.web';
import {
  adaptWeb,
  useScrollPositioning,
} from '../../../utils/useScrollPositioning';

type ScrollViewPropsSubset = Omit<
  ScrollViewProps,
  'onLayout' | 'onScroll' | 'scrollEventThrottle' | 'ref'
>;

interface Props extends ScrollViewPropsSubset {
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
  /** Enables the user to offset the scroll button vertically. */
  buttonOffset?: number;
}

const ScrollViewWithScrollDownButton: React.FC<Props> = ({
  children,
  contentRef,
  buttonOffset,
  ...scrollViewProps
}) => {
  const scrollViewRef = useRef<HTMLDivElement | null>(null);
  const scrollPositioning = useScrollPositioning({
    bottomDetectionThreshold: rem(1, null),
    initialIsBottomState: true,
  });

  useOnLayout(contentRef, ({ webEvent }) => {
    scrollPositioning.setContentHeight(webEvent.layout.height);
  });

  return (
    <>
      <ScrollView
        id="scrollview-with-down-button"
        ref={scrollViewRef}
        onScroll={(e) => {
          scrollPositioning.onScroll(adaptWeb(e));
        }}
        onLayout={({ webEvent }) => {
          scrollPositioning.setScrollElementHeight(webEvent.layout.height);
        }}
        {...scrollViewProps}
      >
        {children}
      </ScrollView>
      {scrollViewRef.current !== null && (
        <ScrollDownButton
          scrollableRef={scrollViewRef}
          hideAtBottom
          isScrollBottom={scrollPositioning.isScrollBottom}
          scrollOffsetY={scrollPositioning.scrollOffsetY}
          scrollPageSize={scrollPositioning.scrollElementHeight}
          verticalOffset={buttonOffset}
        />
      )}
    </>
  );
};

export default ScrollViewWithScrollDownButton;
