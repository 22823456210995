import React, { type FC, Fragment } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';
import dayjs from 'dayjs';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ScrollView from '@mindoktor/pulse/src/components/ScrollView/web/ScrollView';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { weights } from '@mindoktor/pulse/src/styles/fonts';

import BaseLayout from '@mindoktor/patient-app/layout/components/BaseLayout/web';
import { ScrollableContentLayout } from '@mindoktor/patient-app/layout/web';

import { type QuestionAnswer } from '../../../api/models/journal';
import { useCaseId } from '../../../hooks/useCaseId';
import { useJournalApi } from '../../../hooks/useJournalApi';
import NavigationHeader from '../../NavigationHeader/web';

const JournalSection: React.FC<{ qa: QuestionAnswer }> = ({ qa }) => {
  return (
    <Typography>
      <Typography component="span" fontWeight={weights.semibold}>
        {qa.question}:&nbsp;
      </Typography>
      {qa.answer ?? null}
      {qa.valueList?.join(', ')}
    </Typography>
  );
};

const JournalNoteScreen: FC = () => {
  const t = useTranslation('journal', 'journalNote');
  const caseId = useCaseId();
  const journal = useJournalApi(caseId).data ?? null;
  const journalEntries = journal?.journalEntries;

  if (journal == null) {
    return null;
  }

  return (
    <ScrollableContentLayout>
      <BaseLayout>
        <NavigationHeader />
        <ScrollView marginX="1rem">
          <Stack
            marginTop="1rem"
            marginBottom="1rem"
            paddingBottom="1.25rem"
            sx={{
              borderBottomStyle: 'solid',
              borderBottomColor: colors.black,
              borderBottomWidth: '2px',
            }}
          >
            <Typography variant="h3" color={colors.gray[700]} gutterBottom>
              {t('title')}
            </Typography>
            <Typography variant="h2">{journal.displayName}</Typography>
          </Stack>
          {journalEntries?.map((entry, index) => (
            <Fragment key={entry.signedAt}>
              <Stack marginTop="0.75rem" marginBottom="1rem" spacing="1rem">
                {entry.questionsAnswers.map((qa, index) => (
                  <JournalSection key={index} qa={qa} />
                ))}
              </Stack>
              <Stack
                marginBottom="1rem"
                marginTop="1rem"
                paddingBottom="1rem"
                sx={{
                  borderBottomStyle: 'solid',
                  borderBottomColor: colors.black,
                  borderBottomWidth:
                    index < journalEntries.length - 1 ? '1px' : 0,
                }}
                spacing="0.1rem"
              >
                <Typography variant="body2">{t('signed')}:</Typography>
                <Typography fontWeight={weights.semibold}>
                  {entry.signature}
                </Typography>
                <Typography gutterBottom>
                  {dayjs(entry.signedAt).format('ll')}
                </Typography>
              </Stack>
            </Fragment>
          ))}
        </ScrollView>
      </BaseLayout>
    </ScrollableContentLayout>
  );
};

export default JournalNoteScreen;
