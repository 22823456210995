import React from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { openExternalMinDoktorURL } from '@mindoktor/patient-app/utils/externalRouting/web';

import { storeURLAndroid, storeURLIOS } from '../../../constants';
import AppStoreLogoIcon from '../../AppStoreLogoIcon/web';
import DownloadAppQRCodeIcon from '../../DownloadAppQRCodeIcon/web';
import GooglePlayLogoIcon from '../../GooglePlayLogoIcon/web';

const DownloadAppWithQR: React.FC = () => {
  return (
    <Stack alignItems="center" justifyContent="center" spacing="2rem">
      <DownloadAppQRCodeIcon
        sx={{
          '&.MuiSvgIcon-root': {
            height: '15rem',
            width: 'auto',
          },
        }}
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        spacing="1rem"
      >
        <IconButton
          onClick={() => {
            openExternalMinDoktorURL(storeURLIOS);
          }}
          color="transparent"
        >
          <AppStoreLogoIcon
            sx={{
              '&.MuiSvgIcon-root': {
                height: '3.5rem',
                width: 'auto',
              },
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            openExternalMinDoktorURL(storeURLAndroid);
          }}
          color="transparent"
        >
          <GooglePlayLogoIcon
            sx={{
              '&.MuiSvgIcon-root': {
                height: '3.5rem',
                width: 'auto',
              },
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default DownloadAppWithQR;
