import React from 'react';
import { useFormatNumber } from '@mindoktor/localization/src/hooks/useFormatNumber';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { colors } from '@mindoktor/pulse/src/styles/colors';
import { fonts, weights } from '@mindoktor/pulse/src/styles/fonts';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

import NAPPDemoScreensImgSrc from '../../../images/NAPPDemoScreens.webp';
import CheckmarkIcon from '../../CheckmarkIcon/web/CheckmarkIcon';
import ReviewStars from '../../ReviewStars/web/ReviewStars';

const currentRating = 4.8;

const CheckListItem: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Stack direction="row" alignItems="center" spacing="1rem">
    <CheckmarkIcon
      sx={{
        height: (fonts.h3.size / 6) * 5,
        width: 'auto',
      }}
    />
    <Typography variant="h3" fontWeight={weights.bold}>
      {children}
    </Typography>
  </Stack>
);

const PromotionalCard = () => {
  const t = useTranslation('vaccinationBook', 'promotionalCard');
  const formatNumber = useFormatNumber();
  return (
    <Card
      sx={{
        backgroundColor: colors.red[100],
        minWidth: '22rem',
        maxWidth: '30rem',
      }}
    >
      <Stack
        spacing="2rem"
        marginTop="2rem"
        marginBottom="4rem"
        marginX="0.5rem"
      >
        <Box>
          <Img src={NAPPDemoScreensImgSrc} width="100%" />
        </Box>
        <Typography variant="h2" fontSize="24px" fontWeight={weights.bold}>
          {t('title')}
        </Typography>
        <Stack spacing="0.5rem">
          <CheckListItem>{t('paragraph1')}</CheckListItem>
          <CheckListItem>{t('paragraph2')}</CheckListItem>
          <CheckListItem>{t('paragraph3')}</CheckListItem>
        </Stack>

        <Stack direction="row" spacing="0.5rem">
          <ReviewStars rating={currentRating} height="14px" />
          <Typography fontWeight={700} fontSize="14px">
            {t('ratingAppStore', {
              currentRating: formatNumber(currentRating),
            })}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PromotionalCard;
