import React from 'react';

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Icon } from '../../../icons/ReviewStar.svg';

interface Props extends Omit<SvgIconProps, 'fontSize'> {
  iconColor?: string;
  fontSize?: string | SvgIconProps['fontSize'];
}

const ReviewStarColorIcon: React.FC<Props> = ({
  iconColor = 'black',
  fontSize,
  ...props
}) => (
  <SvgIcon
    inheritViewBox
    component={Icon}
    fontSize={fontSize as SvgIconProps['fontSize']}
    {...props}
    sx={{
      '&.MuiSvgIcon-root path': {
        fill: iconColor,
      },
    }}
  />
);

export default ReviewStarColorIcon;
