import { defaultExperimentData, useExperimentApi } from './useExperimentApi';

/**
 * Fetches an experiment with the given experimentName and enrolls the user into it.
 * @param experimentName - Name of the experiment.
 * @param shouldFetchExperiment - Condition for making the actual fetch and enroll.
 *
 * Once a user is enrolled to the experiment, it returns a variant according to APIs.
 * In any other case the default variant is returned instead.
 *
 * Note: the second parameter is useful when you have additional conditions
 * for enrolling or not a user.
 *
 * @example
 * const canUserGoThroughExperiment: boolean = useCustomHook();
 * const experiment = useExperiment('my-experiment-name', canUserGoThroughExperiment)
 */

export const useExperiment = (
  experimentName: string,
  shouldFetchExperiment = true,
) => {
  const experimentQueryResult = useExperimentApi(
    experimentName,
    shouldFetchExperiment,
  );
  return {
    ...experimentQueryResult,
    // data may be undefined for many reasons, so we prevent that
    data: experimentQueryResult.data ?? defaultExperimentData,
  };
};
