import React from 'react';

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

import { colors } from '../../styles/colors';

import { SvgIconComponent as Icon } from './CheckmarkColor.svg';

interface OwnProps {
  color?: string;
  fontSize?: string | number;
}

type Props = OwnProps & Omit<SvgIconProps, keyof OwnProps>;

const CheckmarkColor: React.FC<Props> = ({
  color = colors.green[500],
  fontSize,
  ...props
}) => (
  <SvgIcon
    inheritViewBox
    component={Icon}
    fontSize={fontSize as SvgIconProps['fontSize']}
    {...props}
    sx={{
      '&.MuiSvgIcon-root path': {
        fill: color,
      },
    }}
  />
);
export default CheckmarkColor;
