import { type ThemeOptions } from '@mui/material/styles';

import { colors } from '@mindoktor/pulse/src/styles/colors';

const overrides: ThemeOptions['components'] = {
  MuiInputLabel: {
    defaultProps: {},
    styleOverrides: {
      root: {
        color: colors.gray[600],
        '&.Mui-focused': {
          color: colors.red[400],
        },
      },
      shrink: {
        fontWeight: 600,
      },
    },
  },
};

const muiInputLabelOverrides = overrides.MuiInputLabel;
export default muiInputLabelOverrides;
