import React from 'react';

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as MDLogoHeart } from './MDLogoHeart.svg';

const MDLogoHeartIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon inheritViewBox component={MDLogoHeart} {...props} />
);

export default MDLogoHeartIcon;
