import { DEFAULT_ROUTE } from '../constants/webRoutes';
import { getRedirectRoute } from '../utils/getRedirectRoute/web';

import { useNavigationWithLegacyFallback } from './useNavigationWithLegacyFallback/web';
import { type RedirectionNavigationHook } from './useRedirectionNavigationModel';

/**
 * Redirects to the `state` param.
 *
 * If no `state` param is set, redirects to the default route instead.
 * Works both with new and legacy routes.
 */
export const useRedirectionNavigation: RedirectionNavigationHook = () => {
  const navigationWithLegacyFallback = useNavigationWithLegacyFallback();

  const redirect = () => {
    // Get redirect route from the url param 'state'.
    const redirectRoute = getRedirectRoute() ?? DEFAULT_ROUTE;

    // @ts-expect-error We are aware the redirectRoute may be not an existing or canonical route
    // (e.g. with encoded params) but we handle that possibility once we do the actual redirect.
    navigationWithLegacyFallback.navigate(redirectRoute);
  };

  return {
    redirect,
  };
};
