import React from 'react';
import ReactDOM from 'react-dom/client';

import { CapacitorWrapper, isCapacitor } from '@platform/hybrid';

import App from '@mindoktor/patient-app/main/web';

import reportWebVitals from './reportWebVitals';

import './index.css';

const _isCapacitor = isCapacitor();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <>
    {_isCapacitor ? (
      <CapacitorWrapper>
        <App />
      </CapacitorWrapper>
    ) : (
      <App />
    )}
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
