import { useMutation, type UseMutationResult } from '@tanstack/react-query';

import { type ResponseError } from '@mindoktor/patient-app/api/responseError';

import { marketingConsentApi } from '../api/marketingConsent';
import { type MarketingConsentLogPostRequest } from '../api/models/marketingConsent';

type MarketingConsentLogMutation = UseMutationResult<
  void,
  ResponseError,
  MarketingConsentLogPostRequest
>;

export const useMarketingConsentLogMutator =
  (): MarketingConsentLogMutation => {
    return useMutation(async (payload: MarketingConsentLogPostRequest) => {
      await marketingConsentApi.postMarketingConsentLog(payload);
    });
  };
