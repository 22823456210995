import React from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import BackIcon from '@mindoktor/pulse/src/icons/BackIcon/BackIcon';

import { useNavigation } from '@mindoktor/patient-app/routing/web';

const NavigationHeader: React.FC = () => {
  const navigation = useNavigation();

  return (
    <Stack direction="row" paddingX="0.5rem" paddingTop="0.5rem">
      <IconButton
        color="transparent"
        size="large"
        onClick={() => {
          navigation.goBack();
        }}
      >
        <BackIcon />
      </IconButton>
    </Stack>
  );
};

export default NavigationHeader;
