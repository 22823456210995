import { createContext } from 'react';

interface LocaleContextInterface {
  currentLocale: string;
  currentLanguage: string;
  currentCountry: string;
  setCurrentLocale: (currentLocale: string) => void;
  setDefaultLocaleForLanguage: (language: string) => void;
}

export const LocaleContext = createContext<LocaleContextInterface | undefined>(
  undefined,
);
