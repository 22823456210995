import { type Message } from '../api/models/conversation';

export const getFirstUnreadMessageId = (
  messages: Message[],
  lastReadMessageId: number,
): number | null => {
  const lastMessageIdx = messages.findIndex(
    ({ id }) => id === lastReadMessageId,
  );
  return messages[lastMessageIdx + 1]?.id ?? null;
};
