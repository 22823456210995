import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import checkmarkDone from '../../../../../images/icon/icon_done.svg';
import referral from '../../../../../images/temp/referral.png';
import referralCancelled from '../../../../../images/temp/referral_cancelled.png';
import markedDone from '../../../../../images/temp/referral_completed.png';
import colors from '../../../../common/colors';
import Checkbox from '../../../../common/components/input/Checkbox';
import { Body1, Body3 } from '../../../../common/components/typography';
import { addContextualHint } from '../../../../state/app/actions';
import {
  caseReferralStatus,
  caseReferralType,
} from '../../../../state/cases/constants';
import {
  previewReferralLab,
  referralLabDone,
  selfTestConfirm,
  selfTestSend,
} from '../../../../state/referrals/actions';
import messages from '../../../../state/referrals/messages';
import {
  getPosting,
  getReferralDocumentUrl,
} from '../../../../state/referrals/selectors';
import Button from '../../../components/button/button';
import Link from '../../../components/link';
import { showModal } from '../../../routing';
import { getPlatform, Platforms } from '../../../utils/device';
import Message from '../../components/message';
import HowToPrintModal from '../../modals/how_to_print_modal';
import ReferralHowToModal from '../../modals/referral_how_to_modal';
import SelfTestAddressModal from '../../modals/self_test_address_modal';
import Header from '../components/header';

import { withRouting, routes } from '@mindoktor/patient-app/routing/web';

const styles = {
  address: {
    paddingTop: 16,
  },

  addressLabel: {
    fontWeight: 500,
  },

  buttons: {
    paddingTop: 8,
  },

  button: {
    marginTop: 8,
  },
  confirmedWrap: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '24px 0 16px',
  },
};

const iconSources = {
  [caseReferralStatus.Canceled]: referralCancelled,
  [caseReferralStatus.MarkedDone]: markedDone,
  default: referral,
};
export class Referral extends Component {
  state = {
    isChecked: false,
    showCheckbox: false,
  };

  onUpdate = () => {
    const { onUpdate } = this.props;

    onUpdate && onUpdate();
  };

  openMap = () => {
    const { caseId, referralId, referralType, routing } = this.props;
    routing.navigation.navigate(
      routes.CASE_REFERRALS_LOCATION,
      {
        caseId,
        referralId,
      },
      { referralType }
    );
  };

  viewLab = (labId) => {
    const { eReferral, referralType, previewReferralLab } = this.props;

    previewReferralLab({ labId });

    // Can't change location on anything else then non eReferral LabTests
    const labTest = referralType === caseReferralType.LabTest;

    const readOnly = !labTest || eReferral;

    if (!readOnly) this.openMap();
  };

  openHowTo = async () => {
    const { referralType } = this.props;

    showModal(ReferralHowToModal, { referralType });
  };

  viewReferralDocumentOnWeb = () => {
    const { referralDocumentUrl } = this.props;

    window.location.href = referralDocumentUrl;
  };

  openPrintModal = () => {
    this.props.addContextualHint({
      typeName: 'printreferral',
      caseId: this.props.caseId,
      referralId: this.props.referralId,
    });

    showModal(HowToPrintModal, {
      viewDocument: this.viewReferralDocumentOnWeb,
    });
  };

  markReferralLabDone = async () => {
    const {
      caseId,
      messageId,
      labInfo: { id: labId },
      referralLabDone,
    } = this.props;

    const ok = await referralLabDone({ caseId, messageId, labId });

    if (ok) this.onUpdate();
  };

  openSelfTestAddress = () => {
    showModal(SelfTestAddressModal, {
      confirm: this.confirmSelfTest,
    });
  };

  confirmSelfTest = async (deliveryAddress = null) => {
    const { caseId, messageId, cId, selfTestConfirm } = this.props;

    const ok = await selfTestConfirm({
      caseId,
      messageId,
      cId,
      deliveryAddress,
    });

    if (ok) this.onUpdate();
  };

  sendSelfTest = async () => {
    const { caseId, messageId, cId, selfTestSend } = this.props;

    const ok = await selfTestSend({ caseId, messageId, cId });

    if (ok) this.onUpdate();
  };

  getConfig = () => {
    const { eReferral, testTags } = this.props;
    const formatMessage = _t;

    const selfTestHeader = (testTags) => {
      if (testTags.includes('selftest-candida')) {
        return formatMessage(messages.defaultNewHeaderCandida);
      }

      if (testTags.includes('selftest-coeliac-disease-predisposition')) {
        return formatMessage(
          messages.defaultNewHeaderCoeliacDiseasePredisposition
        );
      }

      if (testTags.includes('selftest-coeliac-disease-diagnosis-monitoring')) {
        return formatMessage(
          messages.defaultNewHeaderCoeliacDiseaseDiagnosisMonitoring
        );
      }

      if (testTags.includes('selftest-covid19')) {
        return formatMessage(messages.defaultNewHeaderCovid19);
      }

      if (testTags.includes('selftest-four-stis-female')) {
        return formatMessage(messages.defaultNewHeaderFourSTIsFemale);
      }

      if (testTags.includes('selftest-four-stis-male')) {
        return formatMessage(messages.defaultNewHeaderFourSTIsMale);
      }

      if (testTags.includes('selftest-lactose-intolerance')) {
        return formatMessage(messages.defaultNewHeaderLactoseIntolerance);
      }

      if (testTags.includes('selftest-nail')) {
        return formatMessage(messages.defaultNewHeaderNail);
      }

      if (testTags.includes('selftest-skin')) {
        return formatMessage(messages.defaultNewHeaderSkin);
      }

      if (testTags.includes('selftest-urine')) {
        return formatMessage(messages.defaultNewHeaderUrine);
      }

      return formatMessage(messages.defaultNewHeader);
    };

    const isDesktop = getPlatform() === Platforms.DESKTOP;

    return {
      [caseReferralType.LabTest]: {
        [caseReferralStatus.New]: {
          header: formatMessage(messages.labTestNewHeader),
          subHeader: formatMessage(messages.labTestNewSubHeader),
          buttonText: formatMessage(messages.labTestButtonNew),
          onClick: this.openMap,
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Approved]: {
          header: eReferral
            ? _t('case.referral.labtest.ereferral.approved.header')
            : formatMessage(messages.labTestApprovedHeader),
          subHeader: eReferral
            ? _t('case.referral.labtest.ereferral.approved.subheader')
            : formatMessage(messages.labTestApprovedSubHeaderOld),
          buttonText: formatMessage(messages.labTestButtonApproved),
          onClick: this.markReferralLabDone,
          isPaperBased: !eReferral,
          printButtonText: formatMessage(
            isDesktop
              ? messages.buttonShowReferralPrintDesktop
              : messages.buttonShowReferralPrint
          ),
          onPrintClick: isDesktop
            ? this.viewReferralDocumentOnWeb
            : this.openPrintModal,
          viewReferralButtonText: formatMessage(messages.buttonShowReferralPdf),
          onViewReferralClick: this.viewReferralDocumentOnWeb,
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.labTestSentHeader),
          subHeader: formatMessage(messages.labTestSentSubHeader),
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.labTestCanceledHeader),
          subHeader: formatMessage(messages.labTestCanceledSubHeader),
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.labTestMarkedDoneHeader),
          subHeader: formatMessage(messages.labTestMarkedDoneSubHeader),
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.labTestMarkedDoneHeader),
          subHeader: formatMessage(messages.labTestMarkedDoneSubHeader),
        },
      },
      [caseReferralType.XRay]: {
        [caseReferralStatus.New]: {
          header: formatMessage(messages.xRayNewHeader),
          subHeader: formatMessage(messages.xRayNewSubHeader),
          buttonText: formatMessage(messages.xRayButtonNew),
          onClick: this.openMap,
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Approved]: {
          header: formatMessage(messages.xRayApprovedHeader),
          subHeader: formatMessage(messages.xRayApprovedSubHeaderOld),
          buttonText: formatMessage(messages.xRayButtonApproved),
          onClick: this.markReferralLabDone,
          isPaperBased: true,
          printButtonText: formatMessage(
            isDesktop
              ? messages.buttonShowReferralPrintDesktop
              : messages.buttonShowReferralPrint
          ),
          onPrintClick: isDesktop
            ? this.viewReferralDocumentOnWeb
            : this.openPrintModal,
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.xRaySentHeader),
          subHeader: formatMessage(messages.xRaySentSubHeader),
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.xRayCanceledHeader),
          subHeader: formatMessage(messages.xRayCanceledSubHeader),
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.xRayMarkedDoneHeader),
          subHeader: formatMessage(messages.xRayMarkedDoneSubHeader),
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.xRayMarkedDoneHeader),
          subHeader: formatMessage(messages.xRayMarkedDoneSubHeader),
        },
      },
      [caseReferralType.ClinicalPhysiology]: {
        [caseReferralStatus.New]: {
          header: _t('case.referral.clinicalPhysiology.new.header'),
          subHeader: _t('case.referral.clinicalPhysiology.new.subheader'),
          buttonText: _t('case.referral.clinicalPhysiology.button.new'),
          onClick: this.openMap,
          howToButtonText: _t('case.referral.howToButton'),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Approved]: {
          header: _t('case.referral.clinicalPhysiology.approved.header'),
          subHeader: _t('case.referral.clinicalPhysiology.approved.subheader'),
          buttonText: _t('case.referral.clinicalPhysiology.button.approved'),
          onClick: this.markReferralLabDone,
          isPaperBased: true,
          printButtonText: formatMessage(
            isDesktop
              ? messages.buttonShowReferralPrintDesktop
              : messages.buttonShowReferralPrint
          ),
          onPrintClick: isDesktop
            ? this.viewReferralDocumentOnWeb
            : this.openPrintModal,
          howToButtonText: _t('case.referral.howToButton'),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Sent]: {
          header: _t('case.referral.clinicalPhysiology.sent.header'),
          subHeader: _t('case.referral.clinicalPhysiology.sent.subheader'),
          howToButtonText: _t('case.referral.howToButton'),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Canceled]: {
          header: _t('case.referral.clinicalPhysiology.canceled.header'),
          subHeader: _t('case.referral.clinicalPhysiology.canceled.subheader'),
        },
        [caseReferralStatus.HasResults]: {
          header: _t('case.referral.clinicalPhysiology.done.header'),
          subHeader: _t('case.referral.clinicalPhysiology.done.subheader'),
        },
        [caseReferralStatus.MarkedDone]: {
          header: _t('case.referral.clinicalPhysiology.done.header'),
          subHeader: _t('case.referral.clinicalPhysiology.done.subheader'),
        },
      },
      [caseReferralType.Freetext]: {
        [caseReferralStatus.New]: {
          header: formatMessage(messages.freeTextNewHeader),
          subHeader: formatMessage(messages.freeTextNewSubHeader),
        },
        [caseReferralStatus.Approved]: {
          header: formatMessage(messages.freeTextApprovedHeader),
          subHeader: formatMessage(messages.freeTextApprovedSubHeader),
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.freeTextSentHeader),
          subHeader: formatMessage(messages.freeTextSentSubHeader),
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.freeTextCanceledHeader),
          subHeader: formatMessage(messages.freeTextCanceledSubHeader),
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.freeTextMarkedDoneHeader),
          subHeader: formatMessage(messages.freeTextMarkedDoneSubHeader),
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.freeTextMarkedDoneHeader),
          subHeader: formatMessage(messages.freeTextMarkedDoneSubHeader),
        },
      },
      [caseReferralType.OtherEstablishment]: {
        [caseReferralStatus.New]: {
          header: formatMessage(messages.otherEstablishmentNewHeader),
          subHeader: formatMessage(messages.otherEstablishmentNewSubHeader),
        },
        [caseReferralStatus.Approved]: {
          header: formatMessage(messages.otherEstablishmentApprovedHeader),
          subHeader: formatMessage(
            messages.otherEstablishmentApprovedSubHeader
          ),
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.otherEstablishmentSentHeader),
          subHeader: formatMessage(messages.otherEstablishmentSentSubHeader),
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.otherEstablishmentCanceledHeader),
          subHeader: formatMessage(
            messages.otherEstablishmentCanceledSubHeader
          ),
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.otherEstablishmentMarkedDoneHeader),
          subHeader: formatMessage(
            messages.otherEstablishmentMarkedDoneSubHeader
          ),
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.otherEstablishmentMarkedDoneHeader),
          subHeader: formatMessage(
            messages.otherEstablishmentMarkedDoneSubHeader
          ),
        },
      },
      [caseReferralType.ContactTracing]: {
        [caseReferralStatus.New]: {
          header: formatMessage(messages.tracingNewHeader),
          subHeader: formatMessage(messages.tracingNewSubHeader),
        },
        [caseReferralStatus.Approved]: {
          header: formatMessage(messages.tracingApprovedHeader),
          subHeader: formatMessage(messages.tracingApprovedSubHeader),
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.tracingSentHeader),
          subHeader: formatMessage(messages.tracingSentSubHeader),
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.tracingCanceledHeader),
          subHeader: formatMessage(messages.tracingCanceledSubHeader),
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.tracingMarkedDoneHeader),
          subHeader: formatMessage(messages.tracingMarkedDoneSubHeader),
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.tracingMarkedDoneHeader),
          subHeader: formatMessage(messages.tracingMarkedDoneSubHeader),
        },
      },
      [caseReferralType.UrgentReferral]: {
        // TODO: Check what statuses are actually used
        [caseReferralStatus.New]: {
          header: formatMessage(messages.UrgentReferralHeader),
          subHeader: formatMessage(messages.UrgentReferralSubHeader),
          isPaperBased: true,
          printButtonText: formatMessage(messages.buttonShowReferralPdf),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
        [caseReferralStatus.Approved]: {
          header: formatMessage(messages.UrgentReferralHeader),
          subHeader: formatMessage(messages.UrgentReferralSubHeader),
          isPaperBased: true,
          printButtonText: formatMessage(messages.buttonShowReferralPdf),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.UrgentReferralHeader),
          subHeader: formatMessage(messages.UrgentReferralSubHeader),
          isPaperBased: true,
          printButtonText: formatMessage(messages.buttonShowReferralPdf),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.UrgentReferralHeader),
          subHeader: formatMessage(messages.UrgentReferralSubHeader),
          isPaperBased: true,
          printButtonText: formatMessage(messages.buttonShowReferralPdf),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.UrgentReferralHeader),
          subHeader: formatMessage(messages.UrgentReferralSubHeader),
          isPaperBased: true,
          printButtonText: formatMessage(messages.buttonShowReferralPdf),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.UrgentReferralHeader),
          subHeader: formatMessage(messages.UrgentReferralSubHeader),
          isPaperBased: true,
          printButtonText: formatMessage(messages.buttonShowReferralPdf),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
      },
      [caseReferralType.Specialist]: {
        [caseReferralStatus.New]: {
          header: _t('case.referral.specialist.header'),
          subHeader: _t('case.referral.specialist.subHeader'),
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Approved]: {
          header: _t('case.referral.specialist.header'),
          subHeader: _t('case.referral.specialist.subHeader'),
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Sent]: {
          header: _t('case.referral.specialist.header'),
          subHeader: _t('case.referral.specialist.subHeader'),
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Canceled]: {
          header: _t('case.referral.specialist.header'),
          subHeader: formatMessage(messages.defaultCanceledSubHeader),
        },
        [caseReferralStatus.HasResults]: {
          header: _t('case.referral.specialist.header'),
          subHeader: formatMessage(messages.defaultMarkedDoneSubHeader),
        },
        [caseReferralStatus.MarkedDone]: {
          header: _t('case.referral.specialist.header'),
          subHeader: formatMessage(messages.defaultMarkedDoneSubHeader),
        },
      },
      [caseReferralType.GenericInternational]: {
        [caseReferralStatus.Approved]: {
          header: _t('case.referral.generic.header'),
          subHeader: _t('case.referral.generic.subheader'),
          isPaperBased: true,
          printButtonText: _t('case.referral.button.showPdf'),
          onPrintClick: this.viewReferralDocumentOnWeb,
        },
      },
      default: {
        // default = self test
        [caseReferralStatus.New]: {
          header: selfTestHeader(testTags),
          subHeader: formatMessage(messages.defaultNewSubHeader),
          buttonText: formatMessage(messages.defaultButtonNew),
          onClick: this.openSelfTestAddress,
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Approved]: {
          header: formatMessage(messages.defaultApprovedHeader),
          buttonText: formatMessage(messages.defaultButtonApproved),
          onClick: () => {
            this.setState({ showCheckbox: true });
          },
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Sent]: {
          header: formatMessage(messages.defaultSentHeader),
          subHeader: formatMessage(messages.defaultSentSubHeader),
          howToButtonText: formatMessage(messages.howToButton),
          onHowToClick: this.openHowTo,
        },
        [caseReferralStatus.Canceled]: {
          header: formatMessage(messages.defaultCanceledHeader),
          subHeader: formatMessage(messages.defaultCanceledSubHeader),
        },
        [caseReferralStatus.HasResults]: {
          header: formatMessage(messages.defaultMarkedDoneHeader),
          subHeader: formatMessage(messages.defaultMarkedDoneSubHeader),
        },
        [caseReferralStatus.MarkedDone]: {
          header: formatMessage(messages.defaultMarkedDoneHeader),
          subHeader: formatMessage(messages.defaultMarkedDoneSubHeader),
        },
      },
    };
  };

  getReferralProps = (config, { referralType, referralStatus }) => {
    const key = config.hasOwnProperty(referralType) ? referralType : 'default';
    return config[key][referralStatus];
  };

  render() {
    const {
      referralType,
      status: referralStatus,
      labInfo,
      posting,
      timestamp,
      style,
    } = this.props;

    const { showCheckbox, isChecked } = this.state;

    const {
      header,
      subHeader,
      buttonText,
      onClick,
      isPaperBased,
      printButtonText,
      onPrintClick,
      howToButtonText,
      onHowToClick,
      viewReferralButtonText,
      onViewReferralClick,
    } = this.getReferralProps(this.getConfig(), {
      referralType,
      referralStatus,
    });

    const hasLabAddress =
      labInfo && labInfo.name && labInfo.name.length > 0 ? true : false;

    const buttons = [
      howToButtonText && onHowToClick && (
        <Button type="ghost" color="black" onClick={onHowToClick}>
          {howToButtonText}
        </Button>
      ),

      isPaperBased && onPrintClick && (
        <Button color="black" onClick={onPrintClick}>
          {printButtonText}
        </Button>
      ),

      !isPaperBased && viewReferralButtonText && onViewReferralClick && (
        <Button type="ghost" color="black" onClick={onViewReferralClick}>
          {viewReferralButtonText}
        </Button>
      ),

      !!buttonText && !showCheckbox && (
        <Button
          type={isPaperBased ? 'ghost' : 'solid'}
          color="black"
          onClick={posting ? undefined : onClick}
        >
          {buttonText}
        </Button>
      ),
    ].filter((button) => !!button);

    return (
      <Message style={style}>
        <Header
          title={header}
          icon={iconSources[referralStatus] || iconSources.default}
          timestamp={timestamp}
        />

        <Body1>{subHeader}</Body1>

        {hasLabAddress && (
          <div style={styles.address}>
            <Body1 style={styles.addressLabel}>
              {_t('case.referral.choosenLocation')}
            </Body1>
            {referralType === 'LabTest' && (
              <Link onClick={() => this.viewLab(labInfo.id)}>
                {labInfo.name}
              </Link>
            )}
            {referralType !== 'LabTest' && (
              <Body1
                style={{
                  display: 'inline',
                  color: colors.red_500,
                  fontWeight: 500,
                }}
              >
                {labInfo.name}
              </Body1>
            )}
          </div>
        )}

        {!!buttons.length && (
          <div
            style={{
              ...styles.buttons,
              justifyContent: buttons.length > 1 ? 'space-between' : 'flex-end',
            }}
          >
            {buttons.map((button, i) => (
              <div key={i} style={styles.button}>
                {button}
              </div>
            ))}

            {isPaperBased && !!hasLabAddress && referralType === 'LabTest' && (
              <div style={styles.button}>
                <Button
                  type="ghost"
                  color="black"
                  onClick={() => this.viewLab(labInfo.id)}
                >
                  {_t('case.referral.changeChosenLocation')}
                </Button>
              </div>
            )}
          </div>
        )}

        {showCheckbox && buttonText && (
          <>
            <div style={{ padding: '24px 0 16px' }}>
              <Checkbox
                label={_t('case.referral.default.checkbox.confirm')}
                checked={isChecked}
                onChange={() => this.setState({ isChecked: !isChecked })}
              />
            </div>
            <Button
              type="solid"
              color="black"
              disabled={!isChecked}
              onClick={this.sendSelfTest}
            >
              {_t('common.done')}
            </Button>
            <Body3 mt="xt">
              {_t('case.referral.default.checkbox.confirm.subheader')}
            </Body3>
          </>
        )}

        {!buttonText &&
          referralStatus === 'Sent' &&
          referralType === 'SelfTest' && (
            <div style={styles.confirmedWrap}>
              <Img src={checkmarkDone} />
              <Body1 ml="t">
                {_t('case.referral.default.checkbox.confirm.completed')}
              </Body1>
            </div>
          )}
      </Message>
    );
  }
}

export default connect(
  (state, props) => {
    const { caseId, messageId } = props;

    return {
      referralDocumentUrl: getReferralDocumentUrl(state, caseId, messageId),
      posting: getPosting(state),
    };
  },
  {
    referralLabDone,
    selfTestConfirm,
    selfTestSend,
    previewReferralLab,
    addContextualHint,
  }
)(withRouting(Referral));
