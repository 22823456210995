import { useEffect, useMemo, useState } from 'react';

import { useUserDetailsApi } from '../../user/hooks/useUserDetailsApi';
import { TIME } from '../../utils/time/constants';
import { type MarketingUser } from '../api/models/marketingUser';

import { useMarketingTrackingApi } from './useMarketingTrackingApi';
import { useMarketingUserApi } from './useMarketingUserApi';

const getDoublingIntervalFunction = (initialTime: number) => {
  let lastInterval = initialTime;
  return () => {
    const currentInterval = lastInterval;
    lastInterval *= 2;
    return currentInterval;
  };
};

export const useMarketingTrackingIdentification = ({
  isInitialized,
}: {
  isInitialized: boolean;
}) => {
  const marketingTrackingApi = useMarketingTrackingApi();
  const userDetails = useUserDetailsApi();

  const getRefetchInterval = useMemo(
    () => getDoublingIntervalFunction(5 * TIME.SECOND),
    [],
  );

  const marketingUser = useMarketingUserApi({
    refetchIntervalFunction: (data) =>
      data == null ? getRefetchInterval() : false,
  });

  const [isIdentified, setIsIdentified] = useState(false);
  const [identificationMarketingUserId, setIdentificationMarketingUserId] =
    useState<NonNullable<MarketingUser>['id'] | null | undefined>();
  const [shouldNotBeIdentified, setShouldNotBeIdentified] = useState(false);
  const isCurrentlyIdentifiedUser =
    isIdentified && marketingUser.data?.id === identificationMarketingUserId;

  useEffect(() => {
    // No need to run anymore
    if (shouldNotBeIdentified || isCurrentlyIdentifiedUser) {
      return;
    }

    // Cannot run yet
    if (!isInitialized || userDetails.isLoading || marketingUser.isLoading) {
      return;
    }

    if (userDetails.data?.identityProtection === true) {
      setShouldNotBeIdentified(true);
      return;
    }

    if (marketingUser.isLoading || marketingUser.data?.id == null) {
      return;
    }

    marketingTrackingApi.identify(marketingUser.data.id);
    setIsIdentified(true);
    setIdentificationMarketingUserId(marketingUser.data.id);
  }, [
    isInitialized,
    shouldNotBeIdentified,
    isCurrentlyIdentifiedUser,
    userDetails,
    marketingUser,
  ]);

  return {
    isIdentificationCompleted: shouldNotBeIdentified || isIdentified,
  };
};
