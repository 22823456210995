import { colors, palette } from '@mindoktor/pulse/src/styles/colors';

export const aphColors = {
  bright: '#EDF9EA',
  logoGreen: '#0BBA4E',
  linkColor: '#3B8444',
  darkGreen: '#2C5733',
};

export const aphPalette = {
  ...palette,
  aph: {
    main: aphColors.linkColor,
    light: aphColors.bright,
    dark: aphColors.darkGreen,
    disabled: colors.gray[300],
    contrastText: colors.white,
  },
};
