import { z } from 'zod';

import { UserStatusStatuses } from '../models/userStatus';

import { phonePattern } from './registration';

export const UserProfileDataSchema = z.object({
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  gender: z.string(),
  email: z.string(),
  phone: z.string(),
  address: z.string(),
  postalcode: z.string(),
  city: z.string(),
  county: z.string(),
  timeZone: z.string(),
  prefLang: z.string(),
  lat: z.number(),
  lng: z.number(),
  status: z.nativeEnum(UserStatusStatuses),
  userMarketingId: z.string().optional(),
  birthDate: z.string(),
  ssn: z.string(),
  identityProtection: z.union([z.null(), z.boolean()]),
});

export const UserProfileSchema = z.object({
  data: UserProfileDataSchema,
});

// UserContactInfo schema is a simplified version of the full userProfile only
// used for validation of the contact info form.
export const UserContactInfoSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'error.required' })
    .email({ message: 'error.email' }),
  phone: z
    .string()
    .min(1, { message: 'error.required' })
    .regex(phonePattern, { message: 'error.telephone' }),
});

export const UserContactInfoProtectedSchema = z.object({
  // email and phone fields is optional for protected users but validated if provided
  email: z.string().refine(
    (data) => {
      // Check if the data is a valid email or an empty string
      return data === '' || z.string().email().safeParse(data).success;
    },
    { message: 'error.email' },
  ),
  phone: z.string().refine(
    (data) => {
      // Check if the data is a valid phone number or an empty string
      return (
        data === '' || z.string().regex(phonePattern).safeParse(data).success
      );
    },
    { message: 'error.telephone' },
  ),
});
