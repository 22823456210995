import React from 'react';

import { Stack, Typography } from '@mui/material';

import { type ArticleProps } from './types';
// import StockInWebContainer from '../StockInWeb/StockInWebContainer';
// import StockInNearestContainer from './StockInNearest/StockInNearestContainer';

const ArticleStock: React.FC<ArticleProps> = ({ article }) => {
  const { productName: name, nplPackId: articleId } = article;
  return (
    <Stack spacing={'1rem'}>
      <Typography fontWeight={'medium'}>{name}</Typography>
      {`TODO: implement wapp UI for article ${articleId}`}
      {/* <StockInNearestContainer article={{ name, articleId }} /> */}
      {/* <StockInWebContainer articleId={articleId} /> */}
    </Stack>
  );
};

export default ArticleStock;
