import { type ThemeOptions } from '@mui/material/styles';

import { colors } from '../../../colors';

const overrides: ThemeOptions['components'] = {
  MuiIconButton: {
    defaultProps: {
      color: 'default',
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState: { color }, theme: { palette } }) => {
        const baseStyle = {
          fontSize: '1rem',
          width: '2.5rem',
          height: '2.5rem',
        };

        // If it's not a color we know, we just pass the base
        if (color == null) {
          return baseStyle;
        }

        switch (color) {
          case 'hero':
            return {
              ...baseStyle,
              color: colors.white,
              backgroundColor: palette.hero.main,
              '&:hover': {
                backgroundColor: palette.hero.light,
              },
              '&:active': {
                backgroundColor: palette.hero.dark,
              },
              '&:disabled': {
                color: palette.hero.contrastText,
                backgroundColor: palette.hero.disabled,
              },
            };

          case 'primary':
            return {
              ...baseStyle,
              backgroundColor: palette.primary.main,
              '&:hover': {
                backgroundColor: palette.primary.light,
              },
              '&:active': {
                backgroundColor: palette.primary.dark,
              },
              '&:disabled': {
                color: palette.primary.contrastText,
                backgroundColor: palette.primary.disabled,
              },
            };

          case 'secondary':
            return {
              ...baseStyle,
              border: '1px solid',
              borderColor: colors.gray[500],
              backgroundColor: palette.secondary.main,
              '&:hover': {
                borderColor: colors.black,
                backgroundColor: palette.secondary.light,
              },
              '&:active': {
                borderColor: colors.black,
                backgroundColor: palette.secondary.dark,
              },
              '&:disabled': {
                color: colors.gray[200],
                borderColor: colors.gray[200],
                backgroundColor: palette.secondary.disabled,
              },
            };

          case 'inherit':
            return baseStyle;
        }
      },
    },
  },
};

const muiIconButtonOverrides = overrides?.MuiIconButton;
export default muiIconButtonOverrides;
