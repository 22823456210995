import { type ThemeOptions } from '@mui/material/styles';

import { getElevationShadow, getShadowString } from '../../../shadows';

const overrides: ThemeOptions['components'] = {
  MuiPaper: {
    defaultProps: {
      elevation: 6,
    },
    styleOverrides: {
      root: ({ ownerState: { elevation }, theme }) => {
        return {
          boxShadow: getShadowString(
            getElevationShadow({ shadowElevation: elevation }),
          ),
          '&.MuiPaper-rounded': {
            borderRadius: '0.5rem',
            padding: '1rem',
          },
        };
      },
    },
  },
};

const paperOverrides = overrides?.MuiPaper;
export default paperOverrides;
