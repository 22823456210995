import { _t } from '@mindoktor/patient-legacy/i18n';
import {
  MdCallEnd,
  MdClose,
  MdMic,
  MdMicOff,
  MdVideocam,
  MdVideocamOff,
} from '../components/docly-md-icons';
import React from 'react';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import MdCamera from '../../../images/icon/ic_videochat_camera.svg';
import MdPhone from '../../../images/icon/ic_videochat_phone.svg';
import colors from '../../common/colors';
import { Body1, Body3, Title1 } from '../../common/components/typography';
import constants from '../../common/constants';
import Button from '../components/button/button';
import IconButton from '../components/button/icon';
import Markdown from '../components/markdown';
import Avatar from '../components/user/avatar';
import { getErrorMessage } from './utils';

const styles = {
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullscreen: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '#474E52',
    zIndex: constants.zIndex1000,
  },

  largeVideo: {
    minHeight: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  smallVideo: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    maxWidth: '30%',
    maxHeight: '30%',
    background: 'black',
  },

  remotePlaceholder: {
    width: 200,
    height: 200,
    color: 'rgba(128, 128, 128, 0.5)',
  },

  error: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: 10,
    background: colors.yellow_500,
    color: colors.off_black,
  },

  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    padding: 10,
    minHeight: 50,
  },

  errorClose: {
    padding: 10,
    cursor: 'pointer',
  },

  errorCloseIcon: {
    width: 30,
    height: 30,
    color: colors.off_black,
  },

  overlay: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 12,
    background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
  },

  overlayButton: {
    position: 'relative',
    width: 'calc(100% - 48px)',
    maxWidth: 360,
    marginBottom: 16,
  },

  overlayButtonIcon: {
    position: 'absolute',
    left: 20,
    width: 24,
    height: 24,
  },

  overlayButtonText: {
    display: 'inline-block',
    lineHeight: '26px',
  },

  icons: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: 16,
    left: 16,
  },

  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
  },

  tab: {
    fontSize: 12,
  },

  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 16,
    left: 16,
  },

  shadow: {
    boxShadow: '0 0 14px -2px rgba(0, 0, 0, 0.5)',
  },
};

// type Props = {
//   localSrc?: Object,
//   remoteSrc?: Object,
//   error?: any,
//   connected: boolean,
//   muted: boolean,
//   onMute: Function,
//   onUnmute: Function,
//   onConnect: Function,
//   onDisconnect: Function,
//   name: string,
//   signature: string,
//   disabled: boolean,
//   voiceCallOnly: boolean,
// };

// type State = {
//   fullscreen: boolean,
//   hidden: boolean,
// };

const Root = ({ fullscreen, children }) => (
  <div
    style={{
      ...styles.root,
      ...(fullscreen ? styles.fullscreen : {}),
    }}
  >
    {children}
  </div>
);

const OverlayButton = ({
  text,
  iconSrc,
  color,
  onClick,
  disabled,
  type,
  hero,
}) => {
  return (
    <Button
      color={color}
      hero={hero}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{ ...styles.overlayButton, ...styles.shadow }}
    >
      {iconSrc && <Img src={iconSrc} style={styles.overlayButtonIcon} />}

      <span
        style={{
          fontWeight: 400,
          fontSize: hero ? 20 : 18,
          ...styles.overlayButtonText,
        }}
      >
        {text}
      </span>
    </Button>
  );
};

class Video extends React.Component {
  state = {
    videoSrc: undefined,
  };

  videoElement = undefined;

  onVideoElementRef = (element) => {
    this.videoElement = element;
  };

  updateVideoSrc = (srcObject) => {
    if (this.videoElement) {
      this.videoElement.srcObject = srcObject;
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.updateVideoSrc(this.props.srcObject);
  }

  componentDidUpdate(prevProps) {
    const { srcObject } = this.props;
    const { srcObject: previousSrcObject } = prevProps;

    if (srcObject !== previousSrcObject) {
      this.updateVideoSrc(srcObject);
    }
  }

  render() {
    const { muted, autoPlay, style } = this.props;

    return (
      <video
        ref={this.onVideoElementRef}
        muted={muted}
        autoPlay={autoPlay}
        playsInline={true}
        style={style}
      />
    );
  }
}

const RemoteVideo = ({ src, connected }) =>
  connected ? (
    src ? (
      <Video srcObject={src} autoPlay={true} style={styles.largeVideo} />
    ) : (
      <Avatar size={150} initials={'Dr'} />
    )
  ) : null;

const LocalVideo = ({ src, connected }) =>
  src ? (
    <Video
      srcObject={src}
      muted={true}
      autoPlay={true}
      style={
        connected
          ? {
              ...styles.smallVideo,
              ...styles.shadow,
            }
          : styles.largeVideo
      }
    />
  ) : null;

class ErrorMessage extends React.Component {
  state = {
    hidden: false,
    error: false,
  };

  hideError = () => {
    this.setState({ hidden: true });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error !== prevState.error) {
      return {
        ...prevState,
        error: nextProps.error,
        hidden: false,
      };
    }

    return null;
  }

  render() {
    const { error } = this.props;

    return error && !this.state.hidden ? (
      <div style={styles.error}>
        <div style={styles.errorMessage}>
          <Body1>{getErrorMessage(error.code)}</Body1>
          <Markdown source={_t('videochat.error.description')} />
        </div>
        <div style={styles.errorClose}>
          <MdClose onClick={this.hideError} style={styles.errorCloseIcon} />
        </div>
      </div>
    ) : null;
  }
}

const Overlay = ({ children }) => <div style={styles.overlay}>{children}</div>;

export class Videochat extends React.Component {
  state = {
    fullscreen: true,
  };

  render() {
    const {
      localSrc,
      remoteSrc,
      error,
      connected,
      muted,
      onMute,
      onUnmute,
      onConnect,
      onDisconnect,
      name,
      signature,
      disabled,
      onDisableVideo,
      onEnableVideo,
      voiceCallOnly,
    } = this.props;

    const { fullscreen } = this.state;

    return (
      <Root fullscreen={fullscreen}>
        <RemoteVideo src={remoteSrc} connected={connected} name={name} />

        <LocalVideo src={localSrc} connected={connected} />

        {!connected && (
          <Overlay>
            <OverlayButton
              text={
                voiceCallOnly
                  ? _t('videochat.start.voicecall')
                  : _t('videochat.start')
              }
              type="solid"
              color="green_strong"
              iconSrc={voiceCallOnly ? MdPhone : MdCamera}
              onClick={onConnect}
              disabled={disabled}
              hero={true}
            />
            <OverlayButton
              text={_t('videochat.back')}
              type="ghost"
              color="white"
              iconSrc={null}
              disabled={false}
              onClick={window.history.back.bind(window.history)}
              hero={false}
            />
          </Overlay>
        )}
        {connected && remoteSrc && (
          <div style={styles.nameBlock}>
            <Title1 color="white">{name}</Title1>
            <Body1 color="white">{signature}</Body1>
          </div>
        )}

        {connected && (
          <div style={styles.icons}>
            {onDisconnect && (
              <div style={styles.iconContainer}>
                <IconButton
                  color="red"
                  size={75}
                  onClick={onDisconnect}
                  iconComponent={MdCallEnd}
                />
                <Body3 style={styles.tab} color="white">
                  {_t('videochat.cancel')}
                </Body3>
              </div>
            )}

            {onMute && onUnmute && (
              <div style={styles.iconContainer}>
                <IconButton
                  color="white"
                  size={50}
                  onClick={muted ? onUnmute : onMute}
                  iconComponent={muted ? MdMicOff : MdMic}
                />
                <Body3 style={styles.tab} color="white">
                  {muted ? _t('videochat.audioOff') : _t('videochat.audioOn')}
                </Body3>
              </div>
            )}

            {onDisableVideo && onEnableVideo && (
              <div style={styles.iconContainer}>
                <IconButton
                  color="white"
                  size={50}
                  onClick={voiceCallOnly ? onEnableVideo : onDisableVideo}
                  iconComponent={voiceCallOnly ? MdVideocamOff : MdVideocam}
                />
                <Body3 style={styles.tab} color="white">
                  {voiceCallOnly
                    ? _t('videochat.videoOff')
                    : _t('videochat.videoOn')}
                </Body3>
              </div>
            )}
          </div>
        )}

        {connected && <ErrorMessage error={error} />}
      </Root>
    );
  }
}

export default Videochat;
