import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { type UserDetailsResponse } from './models/details';
import {
  type UserFinalizeRegistrationProtectedRequest,
  type UserFinalizeRegistrationRequest,
} from './models/registration';
import {
  type UserProfileData,
  type UserProfileResponse,
} from './models/userProfile';
import { type UserStatusResponse } from './models/userStatus';
import { UserDetailsSchema } from './schema/details';
import { UserProfileSchema } from './schema/userProfile';
import { UserStatusSchema } from './schema/userStatus';

const BASE_URL = '/api/v2/user';
const BASE_URL_V1 = '/api/v1/user';

const getDetails = async (): Promise<ApiResponse<UserDetailsResponse>> => {
  return await requestHandler.get(`${BASE_URL}/details`, {
    validationSchema: UserDetailsSchema,
  });
};

const getProfile = async (): Promise<ApiResponse<UserProfileResponse>> => {
  return await requestHandler.get(`${BASE_URL_V1}/profile`, {
    validationSchema: UserProfileSchema,
  });
};

const postProfile = async (
  request: UserProfileData,
): Promise<ApiResponse<void>> => {
  return await requestHandler.post(`${BASE_URL_V1}/profile`, request);
};

const getStatus = async (): Promise<ApiResponse<UserStatusResponse>> => {
  return await requestHandler.get(`${BASE_URL}/status`, {
    validationSchema: UserStatusSchema,
  });
};

const postFinalizeRegistration = async (
  request:
    | UserFinalizeRegistrationRequest
    | UserFinalizeRegistrationProtectedRequest,
): Promise<ApiResponse<void>> => {
  return await requestHandler.post(
    `${BASE_URL}/registration/finalize`,
    request,
  );
};

export const userApi = {
  getDetails,
  getProfile,
  postProfile,
  getStatus,
  postFinalizeRegistration,
};
