import { useLocale } from '@mindoktor/localization/src/hooks/useLocale';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { casesApi } from '../casesApi';
import { type Case } from '../models/cases';

type CasesResult = UseQueryResult<Case[], ResponseError>;
const queryKey = 'Cases';

export const useCasesApi = (): CasesResult => {
  const locale = useLocale();
  return useQuery<Case[], ResponseError>(
    [queryKey, locale.currentLocale],
    async () => {
      const response = await casesApi.getCases(locale.currentLocale);
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response?.data?.data ?? [];
    },
  );
};
