import MuiAvatar from '@material-ui/core/Avatar';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { MdCallEnd, MdVideocam } from '../../../components/docly-md-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import colors from '../../../../common/colors';
import { Body1 } from '../../../../common/components/typography';
import constants from '../../../../common/constants';
import {
  acceptVideoCall,
  rescheduleVideoCall,
  showVideoWarning,
} from '../../../../state/cases/actions';
import { VideocallStatus } from '../../../../state/cases/constants';
import Button from '../../../components/button/button';
import { showModal } from '../../../routing';
import { isAndroid, isChrome, isDesktop, isIos } from '../../../utils/device';
import Message from '../../components/message';
import VideocallRescheduleModal from '../../modals/videocall_reschedule_modal';

const styles = {
  disabled: {
    backgroundColor: colors.background,
    paddingTop: 0,
    paddingBottom: 0,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 44,
    height: 44,
    marginRight: 16,
    backgroundColor: '#969EA3',
    borderRadius: '50%',
  },

  icon: {
    width: 24,
    height: 24,
    color: 'white',
  },

  avatar: {
    position: 'relative',
  },

  doctorImage: {
    display: 'block',
    width: constants.thumbDoctor,
    height: constants.thumbDoctor,
    marginRight: constants.spacing16,
  },

  avatarIcon: {
    position: 'absolute',
    top: 24,
    left: 24,
    width: 14,
    height: 14,
    padding: 5,
    color: 'white',
    borderRadius: '50%',
  },

  middle: {
    flex: 1,
  },

  startTime: {
    fontWeight: 500,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
  },

  changeButton: {
    flex: 0.5,
    marginRight: 8,
  },

  acceptButton: {
    flex: 0.5,
    marginLeft: 8,
  },
};

const getStateFromProps = (props, state = {}) => {
  const { isInPast, isOutdatedAndUnconfirmed, isPending, status } = props;
  const { current } = state;

  if (isInPast) {
    return VideocallStatus.ISINPAST;
  }

  if (isOutdatedAndUnconfirmed) {
    return VideocallStatus.ISINPAST;
  }

  if (isPending) {
    // need to make sure that user has accepted call first,
    // even though it is pending
    if (
      current === VideocallStatus.CHOICE ||
      status === VideocallStatus.CHOICE
    ) {
      return VideocallStatus.CHOICE;
    }
    return VideocallStatus.PENDING;
  }

  switch (status) {
    case 0:
      return VideocallStatus.CHOICE;
    case 1:
      return VideocallStatus.ACCEPTED;
    case 2:
      return VideocallStatus.REJECTED;
    default:
      return VideocallStatus.CHOICE;
  }
};

class Videocall extends Component {
  state = {
    current: getStateFromProps(this.props, this.state),
  };

  static getDerivedStateFromProps = (nextProps, prevState) => ({
    ...prevState,
    current: getStateFromProps(nextProps, prevState),
  });

  componentDidMount() {
    const { dispatch, caseId } = this.props;
    if (
      this.state.current !== VideocallStatus.REJECTED &&
      this.state.current !== VideocallStatus.ISINPAST
    ) {
      if ((isDesktop && !isChrome) || isAndroid || isIos) {
        dispatch(showVideoWarning(caseId));
      }
    }
  }

  rescheduleVideoCall = (message) => {
    const { dispatch, id, caseId, cId } = this.props;

    this.setState({ current: VideocallStatus.REJECTED });

    dispatch(rescheduleVideoCall(caseId, id, message, cId));
  };

  onRescheduleClick = () => {
    showModal(VideocallRescheduleModal, {
      submit: this.rescheduleVideoCall,
    });
  };

  onOkClick = () => {
    const { dispatch, id, caseId, cId, isPending } = this.props;

    // Set current status to Pending if call start time is close to now:
    const current = isPending
      ? VideocallStatus.PENDING
      : VideocallStatus.ACCEPTED;

    this.setState({ current });

    dispatch(acceptVideoCall(caseId, id, cId));
  };

  render() {
    const {
      created,
      updated,
      doctorName,
      doctorImageUrl,
      startTime,
      videoDate,
      isOutdatedAndUnconfirmed,
      style,
    } = this.props;
    const { current: status } = this.state;

    // Polyfill until we can ditch the internal state:
    const isUnanswered = status === VideocallStatus.CHOICE;
    const isAccepted = status === VideocallStatus.ACCEPTED;
    const isRejected = status === VideocallStatus.REJECTED;

    // isPending = If the video call is about to start (within 30 minutes I think)
    const isPending = status === VideocallStatus.PENDING;
    const isInPast = status === VideocallStatus.ISINPAST;

    const headerText = _t('videoCallItem.header');

    let topRow; // If the video call is about to start (within 30minutes I think)
    if (
      (isPending && !isOutdatedAndUnconfirmed) ||
      isInPast ||
      isOutdatedAndUnconfirmed
    ) {
      topRow = null;
    } else {
      let suggestionText;
      if ((created === updated && !isAccepted) || isRejected) {
        suggestionText = isRejected
          ? _t('videoCallItem.isRejected', { startTime })
          : _t('videoCallItem.suggestedTime', { doctorName });
      } else if (created !== updated || isAccepted) {
        suggestionText = isAccepted
          ? _t('videoCallItem.accepted')
          : _t('videoCallItem.unaccepted');
      } else {
        suggestionText = _t('videoCallItem.suggestionText', { doctorName });
      }

      const IconComponent = isRejected ? MdCallEnd : MdVideocam;

      topRow = (
        <div style={styles.row}>
          <div style={styles.avatar}>
            <MuiAvatar src={doctorImageUrl} style={styles.doctorImage} />

            <IconComponent
              style={{
                ...styles.avatarIcon,
                backgroundColor: isRejected ? '#F3003B' : '#1D9E6F',
              }}
            />
          </div>

          <div style={styles.middle}>
            {isAccepted && <Body1>{headerText}</Body1>}

            <Body1>
              {suggestionText}
              <span style={styles.startTime}>
                {!isRejected ? ' ' + startTime : ''}
              </span>
            </Body1>

            {isAccepted && (
              <div>
                <Body1 style={{ marginTop: 16, marginBottom: 16 }}>
                  {_t('videoCallItem.helpText')}
                </Body1>
              </div>
            )}
          </div>
        </div>
      );
    }
    let bottomRow;

    if (isInPast || isOutdatedAndUnconfirmed) {
      bottomRow = (
        <div style={styles.row}>
          <div>
            <div style={styles.iconWrapper}>
              <MdVideocam style={styles.icon} />
            </div>
          </div>

          <Body1 style={{ paddingTop: 10 }}>
            {status === VideocallStatus.ACCEPTED || !isOutdatedAndUnconfirmed
              ? _t('videoCallItem.finishedTime', { videoDate })
              : _t('videoCallItem.unfinished')}
          </Body1>
        </div>
      );
    } else if (isPending) {
      const IconComponent = isRejected ? MdCallEnd : MdVideocam;

      bottomRow = (
        <div style={styles.row}>
          <div style={styles.avatar}>
            <Img src={doctorImageUrl} style={styles.doctorImage} />

            <IconComponent
              style={{
                ...styles.avatarIcon,
                backgroundColor: isRejected ? 'red' : 'green',
              }}
            />
          </div>

          <div style={styles.middle}>
            <Body1>{headerText}</Body1>

            <Body1>
              {_t('videoCallItem.accepted')}
              <span style={styles.startTime}>
                {!isRejected ? ' ' + startTime : ''}
              </span>
            </Body1>
          </div>
        </div>
      );
    } else if (
      isUnanswered ||
      (!isInPast && created !== updated && !isAccepted && !isRejected)
    ) {
      bottomRow = (
        <div style={styles.buttons}>
          <Button
            type="ghost"
            color="black"
            onClick={this.onRescheduleClick}
            style={styles.changeButton}
          >
            {_t('videoCallItem.change')}
          </Button>
          <Button
            color="black"
            onClick={this.onOkClick}
            style={styles.acceptButton}
          >
            {_t('videoCallItem.accept')}
          </Button>
        </div>
      );
    }

    return (
      <Message
        style={{
          ...style,
          ...(isInPast ||
          isOutdatedAndUnconfirmed ||
          isPending ||
          isRejected ||
          isAccepted
            ? styles.disabled
            : undefined),
        }}
      >
        {topRow}
        {bottomRow}
      </Message>
    );
  }
}

export default connect()(Videocall);
