import Typography from '@mui/material/Typography';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { useState, FC } from 'react';
import Markdown from '../../components/markdown';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import editIcon from '../../../../images/icon/ic_edit_pen.svg';
import infoIcon from '../../../../images/icon/ic_info_alt.svg';
import { FreePassState } from '../../../state/freepass/types';
import { isValidEFrikort } from '../../../state/freepass/utils';
import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetActions from '@mindoktor/pulse/src/components/BottomSheetActions/web';
import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';
import Button from '@mui/material/Button';
import EditFreePassModal from '../freepass/edit_modal';
import Box from '@mui/material/Box';

interface Props {
  freePass: FreePassState;
  classes?: any;
}

const FreePassInfo: FC<Props> = (props) => {
  const { freePass } = props;
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const isEFrikort = isValidEFrikort(freePass);

  return (
    <>
      <Box
        sx={{ ...styles.icon, ...styles.infoIcon }}
        onClick={() => setIsInfoModalOpen(true)}
      >
        <Img src={infoIcon} />
      </Box>
      {!isEFrikort && (
        <Box
          sx={{ ...styles.icon, ...styles.editIcon }}
          onClick={() => setIsEditModalOpen(true)}
        >
          <Img src={editIcon} />
        </Box>
      )}

      <Typography
        variant="body2"
        fontWeight="semiBold"
        textTransform={'uppercase'}
        gutterBottom
      >
        {isEFrikort
          ? _t('payment.paymentSelection.efrikort')
          : _t('payment.paymentSelection.freepass')}
      </Typography>

      <Typography variant="body2" fontWeight="medium">
        {_t('freePass.issuedByCounty')}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {freePass?.issuer}
      </Typography>

      <Typography variant="body2" fontWeight="medium">
        {_t('freePass.freePassId')}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {freePass?.number}
      </Typography>

      <Typography variant="body2" fontWeight="medium">
        {_t('freePass.validity')}
      </Typography>
      <Typography variant="body2">{freePass?.expires}</Typography>

      <BottomSheet
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      >
        <BottomSheetHeader
          ariaLabelForClose={_t('common.close')}
          onClose={() => setIsInfoModalOpen(false)}
        >
          <Typography variant="h2">
            {_t('payment.paymentSelection.freecardInfoModal.header')}
          </Typography>
        </BottomSheetHeader>
        <BottomSheetContent>
          <Typography variant="body1" gutterBottom>
            {_t('payment.paymentSelection.freecardInfoModal.bodyPart1')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {_t('payment.paymentSelection.freecardInfoModal.bodyPart2')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {_t('payment.paymentSelection.freecardInfoModal.bodyPart3')}
          </Typography>
          <Typography variant="body1">
            <Markdown
              source={_t(
                'payment.paymentSelection.freecardInfoModal.bodyPart4'
              )}
            />
          </Typography>
        </BottomSheetContent>
        <BottomSheetActions
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: { display: 'none' },
          })}
        >
          <Button
            onClick={() => setIsInfoModalOpen(false)}
            color="primary"
            aria-label={_t('common.close')}
          >
            {_t('common.close')}
          </Button>
        </BottomSheetActions>
      </BottomSheet>
      <EditFreePassModal
        freePass={freePass}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        immediateValidation={true}
      />
    </>
  );
};

const styles = {
  icon: {
    position: 'absolute',
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  infoIcon: {
    top: 16,
    right: 16,
  },
  editIcon: {
    bottom: 16,
    right: 16,
  },
};

export default FreePassInfo;
