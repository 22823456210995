import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';

import { scrollViewStyle } from '@mindoktor/pulse/src/components/ScrollView/web';
import SendIcon from '@mindoktor/pulse/src/icons/SendIcon/SendIcon';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { rem } from '@mindoktor/pulse/src/styles/conversions';
import { getShadowString } from '@mindoktor/pulse/src/styles/shadows';

import CameraIcon from './CameraIcon/CameraIcon';
import MediaPreview from './MediaPreview/MediaPreview';
import { maxLengthOfMessage, maxRowsOfMultilineInputBox } from './constants';
import { type MessageComposerProps } from './types';

const MessageComposer: React.FC<MessageComposerProps> = ({
  mediaAsset,
  isSending,
  message,
  setMessage,
  onSubmit,
  onRemoveMediaAsset,
  onOpenMediaChooser,
}) => {
  const t = useTranslation('messaging');

  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        boxShadow: getShadowString('default'),
      }}
      borderRadius={rem(0.25)}
    >
      {mediaAsset != null && (
        <Box paddingX={rem(1)} paddingTop={rem(0.3)}>
          <MediaPreview
            mediaAsset={mediaAsset}
            onRemove={() => {
              void onRemoveMediaAsset(mediaAsset);
            }}
            isDisabled={isSending}
          />
        </Box>
      )}
      <Input
        inputProps={{
          maxRows: maxRowsOfMultilineInputBox,
          maxLength: maxLengthOfMessage,
          sx: { ...scrollViewStyle(), boxShadow: '0 0 0 0' },
        }}
        sx={{ boxShadow: '0 0 0 0' }}
        value={message}
        onChange={(e) => {
          if (!isSending) {
            setMessage(e.target.value);
          }
        }}
        multiline={true}
        fullWidth={true}
        placeholder={t('write')}
        disabled={isSending}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{ alignSelf: 'flex-end', paddingBottom: '0.7rem' }}
          >
            {message === '' && mediaAsset == null ? (
              <IconButton
                aria-label={t('mediaPicker.openLabel')}
                onClick={onOpenMediaChooser}
                edge="end"
              >
                <CameraIcon fontSize={1.5} />
              </IconButton>
            ) : (
              <IconButton
                color="hero"
                aria-label={t('send')}
                onClick={() => {
                  void onSubmit(message);
                }}
                edge="end"
                disabled={isSending}
              >
                <SendIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default MessageComposer;
