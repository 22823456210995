import { createTheme, type ThemeOptions } from '@mui/material/styles';

import { pulseThemeOptions } from '@mindoktor/pulse/src/styles/web/theme';

import { aphPalette } from './colors';

const aphThemeOptions: ThemeOptions = {
  ...pulseThemeOptions,
  palette: aphPalette,
};

export const aphTheme = createTheme(aphThemeOptions);
