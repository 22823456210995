import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getFormularyState } from '../../../state/formulary/selectors';
import { loadHealthProfile } from '../../../state/healthprofile/actions';
import { getHealthProfileFormularyKey } from '../../../state/healthprofile/selectors';
import Spinner from '../../components/spinner/screen';

// type LoaderProps = {
//   childUuid?: string,

//   loadedHealthProfile?: boolean,

//   healthProfileKey?: string,

//   childComponent: any,

//   loadHealthProfile: typeof loadHealthProfile,
// };

class Loader extends Component {
  state = {};

  load = async () => {
    const { childUuid, loadedHealthProfile, loadHealthProfile } = this.props;

    if (!loadedHealthProfile) {
      loadHealthProfile({
        childUuid,
      });
    }
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      loadedHealthProfile,
      healthProfileKey,
      childComponent: ChildComponent,
    } = this.props;

    return ChildComponent && loadedHealthProfile ? (
      <ChildComponent {...this.props} healthProfileKey={healthProfileKey} />
    ) : (
      <Spinner />
    );
  }
}

const ConnectedLoader = connect(
  (state, props) => {
    const { childUuid } = props;

    const healthProfileKey = getHealthProfileFormularyKey(state, childUuid);

    return {
      loadedHealthProfile: !!getFormularyState(state, healthProfileKey),

      healthProfileKey,
    };
  },
  { loadHealthProfile }
)(Loader);

export default (childComponent) => (props) => (
  <ConnectedLoader {...props} childComponent={childComponent} />
);
