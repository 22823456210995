import commonRoutes from './routes';

/** This is currently a legacy route, take it from `routes` once it has been migrated */
export const DEFAULT_ROUTE = '/guides/adult';

/**
 * Web routes.
 *
 * Temporary workaround, until we fix and align the routes
 * across native and web.
 */
export const routes = {
  ...commonRoutes,
  // Test page for faking logins from Skandia SSO
  SKANDIA_TEST_LOGIN: '/auth/skandia/test',
  /** Legacy page */
  FOLLOWUP_SURVEY: '/followup/survey',
  FOLLOWUP_SURVEY_COMPLETED: '/followup/survey/completed',
} as const;

export type RouteKeys = keyof typeof routes;
// We need to include DEFAULT ROUTE as otherwise the type is not correctly understood
// This is due to routes with params e.g. `/guides/:id` not matching correctly
export type Route = (typeof routes)[RouteKeys] | typeof DEFAULT_ROUTE;

export const routesPaths = new Set(
  Object.values(routes as Record<string, string>),
);
