import { useEffect, useState } from 'react';

import { type Case } from '../models/cases';

import { useCasesApi } from './useCasesApi';

export const useCaseById = (id: number): Case | undefined => {
  const cases = useCasesApi();
  const [currentCase, setCurrentCase] = useState<Case | undefined>(undefined);

  useEffect(() => {
    if (currentCase?.id === id) {
      return;
    }

    if (cases == null || cases.isLoading || cases.data == null) {
      return;
    }

    const _case = cases.data.find((cas) => cas.id === id);
    if (_case != null) {
      setCurrentCase(_case);
    }
  }, [id, cases]);

  return currentCase;
};
