import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { weights } from '@mindoktor/pulse/src/styles/fonts';

const QRCodeInstructions: React.FC = () => {
  const t = useTranslation('vaccinationBook', 'QRCodeInstructions');
  return (
    <Stack>
      <Typography variant="h3" fontWeight={weights.semibold} gutterBottom>
        {t('title')}
      </Typography>
      <Stack marginLeft="0.5em">
        <Typography variant="body1" gutterBottom>
          {'1. ' + t('step1')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {'2. ' + t('step2')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {'3. ' + t('step3')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default QRCodeInstructions;
