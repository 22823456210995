import { useEffect, useRef } from 'react';

/**
 * LayoutEvent object is returned in the callback as a result of component layout change,
 * for example onLayout in an HTML component.
 */
export interface LayoutEvent {
  layout: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  target: Element;
}

export type OnLayoutFunction = (event: { webEvent: LayoutEvent }) => void;

/**
 * Provides a Web implementation of the `onLayout` prop in React Native.
 * @param elementRef - Reference to the component on which to track layout events.
 * @param onLayout - Invoked whenever an observed resize occurs.
 */
export const useOnLayout = (
  elementRef: React.MutableRefObject<HTMLElement | null> | null,
  onLayout?: OnLayoutFunction,
) => {
  const observer = useRef<ResizeObserver | null>(null);
  const onLayoutRef = useRef<OnLayoutFunction | null>(onLayout ?? null);

  useEffect(() => {
    onLayoutRef.current = onLayout ?? null;
  }, [onLayout]);

  useEffect(() => {
    if (observer.current == null) {
      observer.current = new ResizeObserver((entries) => {
        if (onLayoutRef.current == null) {
          return;
        }

        for (const entry of entries) {
          if (entry.target === elementRef?.current) {
            onLayoutRef.current({
              webEvent: {
                layout: {
                  width: entry.contentRect.width,
                  height: entry.contentRect.height,
                  x: entry.contentRect.x,
                  y: entry.contentRect.y,
                },
                target: entry.target,
              },
            });
            break;
          }
        }
      });
    }

    // Update observed element
    if (elementRef?.current != null) {
      observer.current.disconnect();
      observer.current.observe(elementRef.current);
    }

    return () => {
      if (observer.current !== null) {
        observer.current.disconnect();
      }
    };
  }, [elementRef?.current]);
};
