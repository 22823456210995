import { useMutation, type UseMutationResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { trackingApi } from '../api/dataTracking';
import {
  type DataTrackingEvent,
  type DataTrackingEventData,
  type DataTrackingEventRequest,
} from '../api/models/dataTracking';

import { useDataTrackingRequestComposer } from './useDataTrackingRequestComposer';

type TrackEventMutation = UseMutationResult<
  void,
  ResponseError,
  DataTrackingEventRequest
>;

export const useDataTrackingEventMutator = (): TrackEventMutation => {
  return useMutation(async (request: DataTrackingEventRequest) => {
    const response = await trackingApi.postDataTrackingEvent(request);
    if (!response.ok) {
      throw new ResponseError(response);
    }
  });
};

export const useDataTracking = () => {
  const trackEventMutator = useDataTrackingEventMutator();

  const requestComposer = useDataTrackingRequestComposer();

  const trackAsync = async (
    eventName: DataTrackingEvent,
    data: DataTrackingEventData,
  ) => {
    await trackEventMutator.mutateAsync({
      eventName,
      data,
      ...requestComposer.getTrackingRequest(),
    });
  };

  const track = (eventName: DataTrackingEvent, data: DataTrackingEventData) => {
    trackEventMutator.mutate({
      eventName,
      data,
      ...requestComposer.getTrackingRequest(),
    });
  };

  return {
    track,
    trackAsync,
  };
};
