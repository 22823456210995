import { type Values } from '@mindoktor/patient-app/utils/types/objects';

export const UserErrorCodes = {
  UserAccessDenied: 'err.user.denied_access',
  UserEmailCollision: 'err.user.email_collision',
  UserInvalidEmail: 'err.user.invalid_email',
  UserInvalidPhone: 'err.user.invalid_phone',
} as const;

export type UserErrorCode = Values<typeof UserErrorCodes>;
