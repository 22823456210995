import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import {
  type MarketingConsentLogPostRequest,
  type MarketingConsentPostRequest,
  type MarketingConsentResponse,
} from './models/marketingConsent';
import { MarketingConsentResponseSchema } from './schema/marketingConsent';

const BASE_URL = '/api/v1/pii-consent';

const getMarketingConsent = async (): Promise<
  ApiResponse<MarketingConsentResponse>
> => {
  return await requestHandler.get(`${BASE_URL}`, {
    validationSchema: MarketingConsentResponseSchema,
  });
};

const postMarketingConsent = async (
  payload: MarketingConsentPostRequest,
): Promise<ApiResponse<MarketingConsentResponse>> => {
  return await requestHandler.post(`${BASE_URL}`, payload, {
    validationSchema: MarketingConsentResponseSchema,
  });
};

const postMarketingConsentLog = async (
  payload: MarketingConsentLogPostRequest,
): Promise<ApiResponse<void>> => {
  return await requestHandler.post(`${BASE_URL}/log`, payload);
};

export const marketingConsentApi = {
  getMarketingConsent,
  postMarketingConsent,
  postMarketingConsentLog,
};
