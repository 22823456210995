import {
  type ScrollPositionOption,
  ScrollPositionOptionDict,
} from '@mindoktor/pulse/src/components/ListView/constants';

import { type Message } from '../api/models/conversation';

export const getLandingScrollTarget = (
  firstUnreadMessageId: number | null,
  messages: Message[],
): {
  scrollTargetKey: number;
  scrollPositionOption: ScrollPositionOption;
} => {
  if (firstUnreadMessageId == null) {
    return {
      scrollTargetKey: messages[messages.length - 1].id,
      scrollPositionOption: ScrollPositionOptionDict.viewableAreaBottom,
    };
  }

  // We have found an unread message, and we scroll to its top.
  return {
    scrollTargetKey: firstUnreadMessageId,
    scrollPositionOption: ScrollPositionOptionDict.viewableAreaTop,
  };
};
