import { useContext } from 'react';

import UserActivityContext from '../context/UserActivityContext';

export const useUserActivity = () => {
  const userActivity = useContext(UserActivityContext);
  if (userActivity == null) {
    throw new Error(
      'Cannot find UserActivityContext. Are you invoking it outside of its provider?',
    );
  }

  return userActivity;
};
