import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

// for patching up the fetchDraft / payment flow
import colors from '../../common/colors';
import { fetchCases } from '../../state/cases/actions';
// import type { FreePassEditData } from '../../state/freepass/types';
import { fetchFreePass, submitFreePass } from '../../state/freepass/actions';
import { getFreePass } from '../../state/freepass/selectors';
import { treaterSwitch } from '../../state/guides/guidesUtils';
import {
  getGuideByEntrywayId,
  getGuidePrice,
} from '../../state/guides/selectors';
import {
  // type PaymentMethodType,
  paymentMethodTypes,
} from '../../state/payment/types';
import { selectContactInfoVerification } from '../../state/profile/actions';
import {
  contactInformation,
  isContactInfoVerified,
  profileIdentityProtection,
} from '../../state/profile/selectors';
import { routes } from '../../state/routing';
import Markdown from '../components/markdown';
import PaymentSelectorOld from '../payment/components/payment_selector_old';
import { getExternalIdFromHost } from '../utils/host';
import Body from './components/Body';
import Frame from './components/Frame';
import Header from './components/Header';
import Logo from './components/Logo';
import PreviousLink from './components/PreviousLink';
import ScrollIndicator from './components/ScrollIndicator';
import withLoaderScreen from './withLoaderScreen';

// type State = {
//   paymentMethod: PaymentMethodType,
//   submitting: boolean,
//   freePassData: FreePassEditData,
// };
class CompleteScreen extends React.Component {
  state = {
    paymentMethod: undefined,
    freePassData: {
      issuer: '',
      number: '',
      expires: '',
      isValid: false,
      isChanged: false,
    },
    submitting: false,
  };

  back = async () => {
    const { query, popDraft, openRoute } = this.props;

    await popDraft();

    openRoute(routes.INQUISITION, query);
  };

  submit = async () => {
    const {
      result: { answers } = {},
      openRoute,
      postCase,
      isVerified,
      hasIdentityProtection,
      fetchCases,
    } = this.props;
    const { paymentMethod, freePassData } = this.state;
    this.setState({ submitting: true });

    // If the user chose freepass and it has changed we need to save the
    // new freepass data before posting the case.
    if (
      paymentMethod === paymentMethodTypes.FREEPASS &&
      freePassData.isChanged
    ) {
      const ok = await submitFreePass(freePassData);
      if (!ok) {
        // error notification is handled in middleware FREE_PASS_ERROR
        this.setState({ submitting: false });
        return;
      }
    }

    const authParams = {
      externalId: getExternalIdFromHost(),
    };

    const result = await postCase(answers, authParams);

    const { caseId, error } = result;

    if (error) {
      this.setState({ submitting: false });
    } else {
      // Fetch cases to repopulate the state with the newly created case
      await fetchCases();

      const nextRouteArgs =
        isVerified || hasIdentityProtection
          ? [
              routes.CONTACT_INFO_VERIFICATION_GUIDE_COMPLETED_ALREADY_VERIFIED,
              { caseId, paymentMethod },
            ]
          : [
              routes.CONTACT_INFO_VERIFICATION_GUIDE_CONFIRMATION,
              { caseId, paymentMethod },
            ];

      openRoute(...nextRouteArgs);
    }
  };

  initPaymentMethod = () => {
    const { price } = this.props;
    this.setState({
      paymentMethod: price === 0 ? paymentMethodTypes.FREE : undefined,
    });
  };

  handlePaymentMethodChange = (type) => {
    const { fetchFreePass } = this.props;
    if (type === paymentMethodTypes.FREEPASS) {
      fetchFreePass();
    }

    this.setState({ paymentMethod: type });
  };

  async componentDidMount() {
    const { selectContactInfoVerification, email } = this.props;
    selectContactInfoVerification(email);

    // Set the payment type
    await this.initPaymentMethod();
    const { paymentMethod } = this.state;
    if (!!paymentMethod) {
      this.handlePaymentMethodChange(paymentMethod);
    }
  }

  handleFreePassDataChange = (data) => {
    this.setState({ freePassData: data });
  };

  render() {
    const {
      entryway: { treater, currency },
      patient,
      result,
      classes,
      price,
      allowFreePass,
      childUuid,
      freePass,
    } = this.props;
    const { submitting, paymentMethod, freePassData } = this.state;

    if (!result) return null;

    const isFreePassValid =
      paymentMethod !== paymentMethodTypes.FREEPASS || freePassData?.isValid;

    // The case is considered submittable if:
    // - we're not currently in a sumbitting process
    // - a payment method have been set
    // - if freepass is used it has to be valid.
    const submittable = !submitting && !!paymentMethod && isFreePassValid;

    const { firstName, lastName, birthDate } = patient;
    const fullName = `${firstName} ${lastName}`;
    const titleString = treaterSwitch(
      treater,
      _t('previewPayment.titleDoctor'),
      _t('previewPayment.titleGeneric'),
      _t('previewPayment.titleMidwife'),
      _t('previewPayment.titlePhysiotherapist'),
      _t('previewPayment.titlePsychologist')
    );

    const leadString = treaterSwitch(
      treater,
      _t('payment.cart.leadDoctorWithoutPayment'),
      _t('payment.cart.leadGenericWithoutPayment'),
      _t('payment.cart.leadMidwifeWithoutPayment'),
      _t('payment.cart.leadPhysiotherapistWithoutPayment'),
      _t('payment.cart.leadPsychologistWithoutPayment')
    );

    return (
      <Body>
        <Header>
          <PreviousLink label={_t('guides.previousLink')} onClick={this.back} />
        </Header>

        <Frame>
          <Logo className={classes.logo} />

          <Typography variant="h2" className={classes.title}>
            {titleString}{' '}
            <span className={classes.price}>
              {allowFreePass &&
              config.ShowFreePass &&
              paymentMethod === paymentMethodTypes.FREEPASS ? (
                _t('previewPayment.freePass')
              ) : (
                <FormattedNumber value={price || 0} format={currency} />
              )}
            </span>
          </Typography>

          <Typography variant="h3">
            {config.ShowBirthDate
              ? `${fullName}, ${dayjs(birthDate).format('YYYYMMDD')}`
              : fullName}
          </Typography>

          <div className={classes.divider} />

          {!!childUuid ||
            (paymentMethod === paymentMethodTypes.FREE && (
              <Typography className={classes.section}>
                {!!childUuid ? _t('payment.cart.leadForChild') : leadString}
              </Typography>
            ))}

          {paymentMethod !== paymentMethodTypes.FREE && (
            <div className={classes.section}>
              <Markdown source={_t('payment.cart.leadGeneric')} />
            </div>
          )}

          {!!price && (
            <PaymentSelectorOld
              className={classes.section}
              onPaymentMethodChange={this.handlePaymentMethodChange}
              onFreePassChange={this.handleFreePassDataChange}
              freePass={freePass}
              allowFreePass={allowFreePass}
            />
          )}

          {paymentMethod === paymentMethodTypes.FREE && (
            <Typography className={classes.section}>
              {_t('payment.cart.body.old')}
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!submittable}
            onClick={this.submit}
          >
            {submitting
              ? _t('payment.cart.submitting')
              : paymentMethod === paymentMethodTypes.FREE
                ? _t('payment.cart.submit')
                : _t('payment.cart.complete.old')}
          </Button>

          <ScrollIndicator />
        </Frame>
      </Body>
    );
  }
}

const ConnectedCompleteScreen = connect(
  (state, props) => {
    const { entrywayId } = props;
    const { allowFreePass, requireJournalConsent } =
      getGuideByEntrywayId(state, entrywayId) || {};

    const price = getGuidePrice(state, entrywayId);
    const freePass = getFreePass(state);

    const hasIdentityProtection = profileIdentityProtection(state);
    const contactInfo = contactInformation(state);
    const isVerified = isContactInfoVerified(state, contactInfo.email);
    const { email } = contactInfo;

    return {
      requireJournalConsent,
      email,
      isVerified,
      hasIdentityProtection,
      price,
      allowFreePass,
      freePass,
    };
  },
  { fetchCases, fetchFreePass, submitFreePass, selectContactInfoVerification }
)(CompleteScreen);

export default withLoaderScreen()(
  withStyles(({ spacing }) => ({
    logo: {
      marginBottom: spacing(3),
    },

    title: {
      marginBottom: spacing(),
    },

    price: {
      color: colors.red_500,
    },

    section: {
      marginBottom: spacing(3),
    },

    divider: {
      height: 1,
      backgroundColor: colors.grey_300,
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  }))(ConnectedCompleteScreen)
);
