import { useEffect, useState } from 'react';

import { useAuthentication } from '@mindoktor/patient-app/auth/hooks/useAuthentication';
import { UserStatusStatuses } from '@mindoktor/patient-app/user/api/models/userStatus';
import { useUserStatusApi } from '@mindoktor/patient-app/user/hooks/useUserStatusApi';

import {
  type MarketingAPI,
  type MarketingTrackingAction,
  MarketingTrackingActions,
} from '../api/models/marketingTracking';
import { useMarketingTrackingApi } from '../hooks/useMarketingTrackingApi';
import { useMarketingTrackingIdentification } from '../hooks/useMarketingTrackingIdentification';
import { useMarketingTrackingInitialization } from '../hooks/useMarketingTrackingInitialization';

interface TrackData {
  action: MarketingTrackingAction;
  args: Parameters<MarketingAPI['track']>;
}

export const useAsyncTrackingHandler = () => {
  const [trackQueue, setTrackQueue] = useState<TrackData[]>([]);
  const marketingTrackingApi = useMarketingTrackingApi();
  const authentication = useAuthentication();
  const canCheckUserStatus = authentication.isAuthenticated;
  const userStatus = useUserStatusApi(canCheckUserStatus);
  const { isInitialized } = useMarketingTrackingInitialization();
  const { isIdentificationCompleted } = useMarketingTrackingIdentification({
    isInitialized,
  });

  const conditions = {
    isIdentificationCompleted,
    isInitialized,
    isRegistration:
      userStatus.data?.status !== UserStatusStatuses.UserStatusPending,
  };

  const matchesConditionsToBeSent = (action: MarketingTrackingAction) => {
    switch (action) {
      case MarketingTrackingActions.LoginCompleted: {
        // If it's still registering, we send the LoginCompleted event without waiting identification
        return (
          conditions.isRegistration || conditions.isIdentificationCompleted
        );
      }

      case MarketingTrackingActions.RegistrationCompleted: {
        return conditions.isIdentificationCompleted;
      }

      default:
        return conditions.isInitialized;
    }
  };

  const asyncTrack: MarketingAPI['track'] = async (action, ...otherArgs) => {
    if (matchesConditionsToBeSent(action)) {
      void marketingTrackingApi.track(action, ...otherArgs);
      return;
    }

    setTrackQueue([...trackQueue, { action, args: [action, ...otherArgs] }]);
  };

  useEffect(() => {
    if (trackQueue.length === 0) {
      return;
    }

    const updatedQueue: TrackData[] = [];
    for (const trackData of trackQueue) {
      if (matchesConditionsToBeSent(trackData.action)) {
        try {
          void marketingTrackingApi.track(...trackData.args);
        } catch {
          console.warn(`Failed tracking event ${trackData.action}.`);
        }
      } else {
        // Keep elements that have yet to be tracked
        updatedQueue.push(trackData);
      }
    }

    if (updatedQueue.length !== trackQueue.length) {
      setTrackQueue(updatedQueue);
    }
  }, [trackQueue, conditions]);

  return { asyncTrack };
};
