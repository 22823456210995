import { z } from 'zod';

export const PrescriptionDrugSchema = z.object({
  productName: z.string(),
  strength: z.string(),
  form: z.string(),
  packageSize: z.string(),
  dosage: z.string(),
  limit: z.number().nonnegative(),
  nplPackId: z.string().optional(),
});

export const ArticleDrugSchema = z.object({
  productName: z.string(),
  /** Package Id from Nationellt Produktregister för Läkemedel, e.g. '20080603101018'  */
  nplPackId: z.string(),
});
