import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import colors from '../../../common/colors';
import typography, { fontWeights } from '../../theme/typography';
import redoIconSource from '../images/icons/ic_redo_red.svg';

class PreviousLink extends React.Component {
  render() {
    const { label, onClick, classes, className } = this.props;

    return (
      <Button onClick={onClick} className={classNames(classes.root, className)}>
        <Img alt="undo" src={redoIconSource} className={classes.icon} />

        {label}
      </Button>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    [down('xs')]: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1.5),
    },
    '&:hover': {
      backgroundColor: `${colors.red_500}15`,
    },
    color: colors.red_500,
    ...typography.body1,
    fontWeight: fontWeights.medium,
  },

  icon: {
    width: 21,
    marginRight: spacing(1.5),
    color: colors.red_500,
  },
}))(PreviousLink);
