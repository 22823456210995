import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { userApi } from '@mindoktor/patient-app/user/api/user';
import { TIME } from '@mindoktor/patient-app/utils/time/constants';

import {
  type UserStatusResponse,
  UserStatusStatuses,
} from '../api/models/userStatus';

export const userStatusQueryKey = 'userStatus';

const defaultUserStatus = {
  status: UserStatusStatuses.UserStatusNotVerifiedByAdmin,
  tosAccepted: false,
  privacyAccepted: false,
  contactDetailsComplete: false,
};

/**
 * Provides user status data.
 */
export const useUserStatusApi = (isEnabled: boolean) => {
  const notifications = useNotifications();
  const t = useTranslation('error');

  const queryResult = useQuery<UserStatusResponse, ResponseError>(
    [userStatusQueryKey],
    async () => {
      const response = await userApi.getStatus();
      if (!response?.ok) {
        throw new ResponseError(response);
      }

      return response?.data ?? defaultUserStatus;
    },
    {
      retry: false,
      enabled: isEnabled,
      staleTime: 5 * TIME.MINUTE,
      initialData: defaultUserStatus,
      onError: (_error) => {
        notifications.showWarning(t('unknown'));
      },
    },
  );

  return queryResult;
};
