import Config from '@mindoktor/env/Config';

import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import {
  type BankIDCollectRequest,
  type BankIDCollectResponse,
  type BankIDStartResponse,
} from './models/bankid';
import { type AuthConfigResponse } from './models/config';
import {
  type EmailLoginRequest,
  type EmailLoginResponse,
} from './models/email';
import { type IsAuthenticatedResponse } from './models/isAuthenticated';
import { BankIDCollectSchema, BankIDStartSchema } from './schema/bankid';
import { AuthConfigSchema } from './schema/config';
import { IsAuthenticatedSchema } from './schema/isAuthenticated';

const BASE_URL = '/api/v2/auth';

const getIsAuthenticated = async (): Promise<
  ApiResponse<IsAuthenticatedResponse>
> => {
  return await requestHandler.get(`${BASE_URL}/isauthenticated`, {
    validationSchema: IsAuthenticatedSchema,
  });
};

const getConfig = async (): Promise<ApiResponse<AuthConfigResponse>> => {
  return await requestHandler.get(`${BASE_URL}/config`, {
    validationSchema: AuthConfigSchema,
  });
};

const emailLogin = async (
  request: EmailLoginRequest,
): Promise<ApiResponse<EmailLoginResponse>> => {
  return await requestHandler.post(`${BASE_URL}/email/login`, request);
};

const logout = async (): Promise<ApiResponse<void>> => {
  return await requestHandler.post(`${BASE_URL}/logout`);
};

const bankIDStart = async (): Promise<ApiResponse<BankIDStartResponse>> => {
  return await requestHandler.post(`${BASE_URL}/bankid/start`, {
    validationSchema: BankIDStartSchema,
  });
};

const bankIDCollect = async (
  request: BankIDCollectRequest,
): Promise<ApiResponse<BankIDCollectResponse>> => {
  return await requestHandler.post(`${BASE_URL}/bankid/collect`, request, {
    validationSchema: BankIDCollectSchema,
  });
};

/** Provides the URL to a special BE page that logs the user out when visited. */
const getLogoutPageUrl = (): string => {
  const apiUrl = Config.Urls.base.domain;
  return `${apiUrl}${BASE_URL}/logout-browser`;
};

export const authApi = {
  getIsAuthenticated,
  getConfig,
  emailLogin,
  logout,
  bankIDStart,
  bankIDCollect,
  getLogoutPageUrl,
};
