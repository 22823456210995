import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
// import iconPayActive from '../../../../images/icon/ic_pay_active.svg';
import iconDocActive from '../../../../images/icon/ic_doc_active.svg';
import iconHealthprofileActive from '../../../../images/icon/ic_healthprofile_active.svg';
import iconPayActive from '../../../../images/icon/ic_pay_active.svg';
import iconQuestionsActive from '../../../../images/icon/ic_questions_active.svg';
import iconSendActive from '../../../../images/icon/ic_send_active.svg';
import { Body1, Title1 } from '../../../common/components/typography';
import { Treater } from '../../../state/cases/constants';
import Button from '../../components/button/button';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  iconTimeLineRow: {
    position: 'relative',
    paddingBottom: 20,
  },

  textblock: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },

  bullet: {
    marginTop: 5,
    marginRight: 5,
    fontWeight: 'bold',
  },

  bulletText: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
    paddingLeft: 3,
  },

  title: {
    marginBottom: 16,
  },

  subtitle: {
    marginBottom: 20,
  },

  button: {
    marginBottom: 10,
  },

  imageStyle: {
    alignSelf: 'flex-start',
    marginTop: 2,
    marginRight: 12,
  },
};

function getPatientType(price, maxAge) {
  if (price === 0 && maxAge > 18) {
    return 'adultFree';
  }
  if (price === 0 && maxAge === 18) {
    return 'child';
  }
  return 'adult';
}

// type Props = {
//   treater: AllowedTreater,
//   price: number,
//   currency: Currency,
//   maxAge: number,
// };

export default class ExplanationModal extends Component {
  messages = {
    common: {
      [Treater.doctor]: _t('guides.explanation.seeDoctor'),
      [Treater.generic]: _t('guides.explanation.seeGeneric'),
      [Treater.midwife]: _t('guides.explanation.seeMidwife'),
      [Treater.physiotherapist]: _t('guides.explanation.seePhysiotherapist'),
      [Treater.psychologist]: _t('guides.explanation.seePsychologist'),
      freeOfCharge: _t('common.freeOfCharge'),
      submit: _t('guides.explanation.submit'),
      getHelp: _t('guides.explanation.getHelp'),
      payAfterVisit: _t('guides.explanation.payAfterVisit'),
    },
    adult: {
      answerQuestionnaire: _t('guides.explanation.answerQuestionnaire'),
      fillOutProfile: _t('guides.explanation.fillOutProfile'),
      submit: _t('guides.explanation.payAndSubmit'),
    },
    child: {
      answerQuestionnaire: _t('guides.explanation.answerChildQuestionnaire'),
      fillOutProfile: _t('guides.explanation.fillOutChildProfile'),
      submit: _t('guides.explanation.submit'),
    },
    adultFree: {
      answerQuestionnaire: _t('guides.explanation.answerQuestionnaire'),
      fillOutProfile: _t('guides.explanation.fillOutProfile'),
      submit: _t('guides.explanation.submit'),
    },
    title: _t('guides.explanation.title'),
    subTitle: _t('guides.explanation.subTitle'),
  };

  render() {
    const { price, maxAge } = this.props;

    const patientType = getPatientType(price, maxAge);

    return (
      <Modal
        body={
          <div>
            <Title1 style={styles.title}>{this.messages.title}</Title1>
            <Body1 style={styles.subtitle}>{this.messages.subTitle}</Body1>
            <div style={styles.iconTimeLineRow}>
              <div style={styles.textblock}>
                <Img style={styles.imageStyle} src={iconQuestionsActive} />
                <Body1 style={styles.bullet}>1.</Body1>
                <Body1 style={styles.bulletText}>
                  {this.messages[patientType].answerQuestionnaire}
                </Body1>
              </div>
            </div>
            <div style={styles.iconTimeLineRow}>
              <div style={styles.textblock}>
                <Img style={styles.imageStyle} src={iconHealthprofileActive} />
                <Body1 style={styles.bullet}>2.</Body1>
                <Body1 style={styles.bulletText}>
                  {this.messages[patientType].fillOutProfile}
                </Body1>
              </div>
            </div>
            <div style={styles.iconTimeLineRow}>
              <div style={styles.textblock}>
                <Img style={styles.imageStyle} src={iconSendActive} />
                <Body1 style={styles.bullet}>3.</Body1>
                <Body1 style={styles.bulletText}>
                  {this.messages.common.submit}
                </Body1>
              </div>
            </div>
            <div style={styles.iconTimeLineRow}>
              <div style={styles.textblock}>
                <Img style={styles.imageStyle} src={iconDocActive} />
                <Body1 style={styles.bullet}>4.</Body1>
                <Body1 style={styles.bulletText}>
                  {this.messages.common.getHelp}
                </Body1>
              </div>
            </div>
            <div style={styles.iconTimeLineRow}>
              <div style={styles.textblock}>
                <Img style={styles.imageStyle} src={iconPayActive} />
                <Body1 style={styles.bullet}>5.</Body1>
                <Body1 style={styles.bulletText}>
                  {this.messages.common.payAfterVisit}
                </Body1>
              </div>
            </div>
          </div>
        }
        footer={
          <div>
            <Button type="ghost" color="black" onClick={hideModal}>
              {_t('common.close')}
            </Button>
          </div>
        }
      />
    );
  }
}
