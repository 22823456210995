import { type ThemeOptions } from '@mui/material/styles';

const overrides: ThemeOptions['components'] = {
  MuiFormControl: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        marginBottom: '2rem',
      },
    },
  },
};

const muiFormControlOverrides = overrides.MuiFormControl;
export default muiFormControlOverrides;
