import React, { useEffect, useMemo, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import type { Resource } from 'i18next';
import { createInstance } from 'i18next';

import Config from '@mindoktor/env/Config';

import { DEFAULT_LANGUAGE } from '../constants';
import { getDefaultLocaleForLanguage } from '../getDefaultLocaleForLanguage';

import { LocaleContext } from './LocaleContext';

const getTranslatorInstance = (resources: Resource) =>
  createInstance({
    compatibilityJSON: 'v3',
    debug: Config.IsDev,
    fallbackLng: DEFAULT_LANGUAGE,
    resources,
  });

interface Props {
  initialLocale: string;
  languageResources: Resource;
}

const LocaleProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  initialLocale,
  languageResources,
}) => {
  const [currentLocale, setCurrentLocale] = useState<string>(initialLocale);
  const [currentLanguage, currentCountry] = currentLocale.split('-');
  const translatorInstance = useMemo(() => {
    const instance = getTranslatorInstance(languageResources);
    void instance.init();
    return instance;
  }, []);

  const setDefaultLocaleForLanguage = (language: string) => {
    const localeForLanguage = getDefaultLocaleForLanguage(language);
    setCurrentLocale(localeForLanguage);
  };

  useEffect(() => {
    void translatorInstance.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  if (!translatorInstance.isInitialized) {
    return null;
  }

  return (
    <I18nextProvider i18n={translatorInstance}>
      <LocaleContext.Provider
        value={{
          currentLocale,
          currentCountry,
          currentLanguage,
          setCurrentLocale,
          setDefaultLocaleForLanguage,
        }}
      >
        {children}
      </LocaleContext.Provider>
    </I18nextProvider>
  );
};

export default LocaleProvider;
