import { createContext } from 'react';

interface IUserActivityContext {
  isUserActive: boolean;
  anyOtherTabActive: boolean;
  setIsVideocallActive: (isActive: boolean) => void;
  resetInactivityTimeout: () => void;
}

const initialUserActivityContext: IUserActivityContext = {
  isUserActive: true,
  anyOtherTabActive: false,
  setIsVideocallActive: () => {
    console.log('Please init UserActivityContext');
  },
  resetInactivityTimeout: () => {
    console.log('Please init UserActivityContext');
  },
};

/**
 * Context for keeping track of any user activity.
 */
const UserActivityContext = createContext<IUserActivityContext>(
  initialUserActivityContext,
);

// This doesn't work. Yet. Once this is used properly for native we can use
// below code instead of the pre initialized context setup up above.

// const UserActivityContext = createContext<IUserActivityContext | null>(null);

export default UserActivityContext;
