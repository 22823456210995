import { useEffect, useState } from 'react';

import { useUserProfile } from '@mindoktor/patient-app/adapters/hooks/useUserProfile';

import { type ConversationResult } from './useConversationApi';

const TIMESTAMP_ZERO = new Date(0).toISOString();

interface LoadingReturn {
  isLoading: true;
  id: null;
  created: null;
}

interface ReadyReturn {
  isLoading: false;
  id: number;
  /** Timestamp */
  created: string;
}

export const useLastReadMessage = (
  conversation: ConversationResult,
): LoadingReturn | ReadyReturn => {
  const [isLoading, setIsLoading] = useState(true);
  const [lastReadMessageId, setLastReadMessageId] = useState<number>(0);
  const [lastReadMessageCreated, setLastReadMessageCreated] =
    useState<string>('');
  const userProfile = useUserProfile();

  useEffect(() => {
    if (
      userProfile == null ||
      conversation.isLoading ||
      conversation.isFetching ||
      conversation.data?.participants == null ||
      conversation.data?.messages == null
    ) {
      setIsLoading(true);
      return;
    }

    const { messages, participants } = conversation.data;

    const currentParticipant = participants.find(
      (p) => p.userId === userProfile.userId,
    );
    if (
      currentParticipant == null ||
      currentParticipant.lastReadMessageId === 0
    ) {
      setIsLoading(false);
      return;
    }
    const lastReadMessage = messages
      .slice()
      .reverse()
      .find(({ id }) => id <= currentParticipant.lastReadMessageId);

    setLastReadMessageId(lastReadMessage?.id ?? 0);
    setLastReadMessageCreated(lastReadMessage?.created ?? TIMESTAMP_ZERO);
    setIsLoading(false);
  }, [conversation]);

  if (isLoading) {
    return {
      id: null,
      created: null,
      isLoading,
    };
  }

  return {
    id: lastReadMessageId,
    created: lastReadMessageCreated,
    isLoading,
  };
};
