import React from 'react';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import bloodpressure from '../../../../../images/guides/guide_info/icon_intro_bloodpressure.svg';
import tracing from '../../../../../images/guides/guide_info/icon_intro_contact_tracing.svg';
import exercises from '../../../../../images/guides/guide_info/icon_intro_exercises.svg';
import photo from '../../../../../images/guides/guide_info/icon_intro_pictures.svg';
import photovideo from '../../../../../images/guides/guide_info/icon_intro_pictures_and_video.svg';
import tests from '../../../../../images/guides/guide_info/icon_intro_tests.svg';
import videos from '../../../../../images/guides/guide_info/icon_intro_videos.svg';
import warning from '../../../../../images/guides/guide_info/icon_intro_warning_triangle.svg';
import spacing from '../../../../common/spacing';

const imageMap = {
  photo,
  photovideo,
  bloodpressure,
  tracing,
  exercises,
  tests,
  videos,
  warning,
};

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing.xt,
    marginBottom: 2,
  },

  icon: {
    marginRight: spacing.xs,
    height: 44,
    width: 44,
  },

  alt: {
    fontWeight: 500,
    fontSize: 16,
  },
};

export default function (props) {
  const { src, alt } = props;

  const source = imageMap[src] ? imageMap[src] : src;

  const iconStyles = { ...styles.icon };

  // Warning icon is smaller in the design
  if (src === 'warning') {
    iconStyles.height = 24;
    iconStyles.width = 27;
  }

  // Combined icon is wider in the design
  if (src === 'photovideo') {
    iconStyles.width = 80;
  }

  return (
    <div style={styles.wrapper} className="md-markdown-guide-info">
      <Img style={iconStyles} src={source} alt={alt} />
      <span style={styles.alt} className="md-markdown-guide-info-name">
        {alt}
      </span>
    </div>
  );
}
