import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { animated } from 'react-spring';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import searchIconSrc from '../../../images/icon/icon_search.svg';
import colors from '../../common/colors';
import { Body1, fontFamilies } from '../../common/components/typography';
import spacing from '../../common/spacing';
import FadeTransition from './animation/fade_transition';
import SlideTransition from './animation/slide_transition';
import TextInput from './input/text';
import Layer from './layer';
import ViewportDimensions from './viewport_dimensions';

const styles = {
  backdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.white,
  },
  bodyDesktop: {
    zIndex: 1,
    marginTop: 100,
    width: 600,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'all',
  },
  bodyMobile: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.white,
    pointerEvents: 'all',
  },
  header: {
    backgroundColor: colors.grey_100,
    padding: `${spacing.t}px ${spacing.s}px`,
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    alignItems: 'center',
  },
  searchField: {
    flex: 1,
    marginLeft: spacing.xs,
    borderBottom: 'none',
  },
  buttonReset: {
    border: 'none',
    padding: 0,
    background: 'transparent',
    textAlign: 'left',
  },
  cancel: {
    color: colors.red_500,
    fontFamily: fontFamilies.graphikMedium,
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflowY: 'scroll',
    overflowScrolling: 'touch',
  },
  resultItem: (isLast, isPressed) => ({
    padding: `0 ${spacing.s}px`,
    flexShrink: 0,
    borderBottom: isLast ? 'none' : 'solid 1px ' + colors.grey_200,
    background: isPressed ? colors.grey_200 : colors.white,
  }),
  resultItemSeparator: {
    height: 1,
    marginHorizontal: spacing.s,
    backgroundColor: colors.grey_200,
  },
};

// type State = {
//   itemPressed: ?string,
//   inModalMode: boolean,
// };

export default class SearchModal extends React.Component {
  searchField; // ?{ focus: any => any, blur: any => any };

  state = { itemPressed: false, inModalMode: false };

  Cancel = () => {
    const {
      props: { onClose },
    } = this;
    return (
      <button
        onClick={() => {
          if (onClose) onClose();
          this.searchField && this.searchField.blur();
          this.props.onQueryChange('');
          this.setState({ inModalMode: false });
        }}
        style={styles.buttonReset}
      >
        <Body1 style={styles.cancel}>{_t('common.cancel')}</Body1>
      </button>
    );
  };

  SearchField = () => {
    const {
      Cancel,
      props: { onQueryChange, hint },
    } = this;

    return (
      <div style={styles.header}>
        <Img src={searchIconSrc} />
        <TextInput
          ref={(ref) => (this.searchField = ref)}
          style={styles.searchField}
          onChange={onQueryChange}
          placeholder={hint}
        />
        <Cancel />
      </div>
    );
  };

  Item = ({ item, lastItem }) => {
    const {
      props: { renderItem, onSelect, onClose, itemKeyExtractor },
      state: { itemPressed },
    } = this;

    return (
      <button
        style={{
          ...styles.buttonReset,
          ...styles.resultItem(
            lastItem,
            itemKeyExtractor && itemKeyExtractor(item) === itemPressed
          ),
        }}
        onMouseDown={() =>
          this.setState({ itemPressed: itemKeyExtractor(item) })
        }
        onMouseUp={() => this.setState({ itemPressed: null })}
        onClick={() => {
          if (onClose) onClose();
          this.setState({ inModalMode: false });
          this.props.onQueryChange('');
          onSelect(item);
        }}
      >
        {renderItem(item)}
      </button>
    );
  };

  Results = () => {
    const {
      Item,
      props: { results, itemKeyExtractor },
    } = this;
    return (
      <div style={styles.results}>
        {results.map((item, i, items) => (
          <Item
            key={itemKeyExtractor(item)}
            item={item}
            lastItem={items.length - 1 === i}
          />
        ))}
      </div>
    );
  };

  Backdrop = ({ visible }) => (
    <FadeTransition native visible={visible}>
      {(style) => <animated.div style={{ ...styles.backdrop, ...style }} />}
    </FadeTransition>
  );

  toModalMode = () => {
    this.setState({ inModalMode: true });

    // It help performance to start with a blurred field
    setTimeout(() => this.searchField && this.searchField.focus(), 300);
  };

  DesktopView = ({ height }) => {
    const {
      Backdrop,
      Results,
      SearchField,
      state: { inModalMode },
      props: { renderLaunchField },
    } = this;

    return (
      <React.Fragment>
        {renderLaunchField(this.toModalMode)}
        <Layer>
          <React.Fragment>
            <Backdrop visible={inModalMode} />
            <SlideTransition native from={height} visible={inModalMode}>
              {(style) => (
                <animated.div style={{ ...styles.bodyDesktop, ...style }}>
                  <SearchField />
                  <Results />
                </animated.div>
              )}
            </SlideTransition>
          </React.Fragment>
        </Layer>
      </React.Fragment>
    );
  };

  MobileView = ({ height }) => {
    const {
      SearchField,
      Results,
      state: { inModalMode },
      props: { renderLaunchField },
    } = this;

    return (
      <React.Fragment>
        {renderLaunchField(this.toModalMode)}
        <Layer>
          <SlideTransition
            native
            from={height}
            stiffness={230}
            visible={inModalMode}
          >
            {(style) => (
              <animated.div style={{ ...styles.bodyMobile, ...style }}>
                <SearchField />
                <Results />
              </animated.div>
            )}
          </SlideTransition>
        </Layer>
      </React.Fragment>
    );
  };

  render() {
    const { MobileView, DesktopView } = this;

    return (
      <ViewportDimensions>
        {({ media, height }) =>
          media === 'desktop' ? (
            <DesktopView height={height} />
          ) : (
            <MobileView height={height} />
          )
        }
      </ViewportDimensions>
    );
  }
}
