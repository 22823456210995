import { type Values } from '@mindoktor/patient-app/utils/types/objects';

/**
 * ErrorCodes are normal error codes sent back by our BE apis.
 * The full list can be found in the mindoktor repo files:
 * - BE/api/response/msgcodes.go and
 * - pkg/mderrors/errorcodes.go
 *
 * NOTE: The idea is to fill these as we go instead of including them all from the start.
 */
export const ErrorCodes = {
  // from BE/api/response/msgcodes.go
  LNGErrInternalSrv: 'LNGErrInternalSrv',
  LNGErrLoginUnsuccessful: 'LNGErrLoginUnsuccessful',
  LNGInvalidData: 'LNGInvalidData',
  LNGAccessDenied: 'LNGAccessDenied',
  LNGEmailCollision: 'LNGEmailCollision',

  // from pkg/mderrors/errorcodes.go
  UserEmailInvalid: 'USER_EMAIL_INVALID',
  UserPhoneInvalid: 'USER_PHONE_INVALID',
} as const;

export type ErrorCode = Values<typeof ErrorCodes>;
