import { useCallback, useEffect, useState } from 'react';
import {
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import { NETWORK_ERROR } from 'apisauce';

import Config from '@mindoktor/env/Config';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { MARKETING_CONSENT_QUERY_KEY } from '@mindoktor/patient-app/marketing-consents/hooks/useMarketingConsentApi';
import { platform } from '@mindoktor/patient-app/utils/compatibility/platform/platform';

import { authApi } from '../api/auth';
import {
  type BankIDCollect,
  type BankIDLoginMode,
  BankIDLoginModes,
} from '../api/models/bankid';
import { AuthErrorCodes, type AuthResponseError } from '../api/models/error';
import { BankIDStatuses } from '../api/schema/bankid';
import { openBankIDLink } from '../functions/openBankIDLink';

import { authenticationQueryKey } from './useIsAuthenticatedApi';

const queryKey = 'bankIDLogin';

type BankIDCollectResult = UseQueryResult<BankIDCollect | null, ResponseError>;

const useBankIDCollect = (
  started: boolean,
  orderRef: string | null,
): BankIDCollectResult => {
  const [complete, setComplete] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Reset when ending the "session" (started is false)
    if (!started) {
      setComplete(false);
      setIsError(false);
    }
  }, [started]);

  return useQuery<BankIDCollect | null, ResponseError>(
    [queryKey, orderRef],
    async () => {
      if (orderRef == null) {
        return null;
      }
      const response = await authApi.bankIDCollect({ orderRef });
      if (!response.ok) {
        setIsError(true);
        throw new ResponseError(response);
      }

      return response.data ?? null;
    },
    {
      retry: (failureCount, error) => {
        // Retry on NETWORK_ERROR: this is necessary because the app can go in the
        // background when doing BankID login and network can hence be blocked by the OS.
        if (error.apiError.problem === NETWORK_ERROR && failureCount <= 5) {
          console.warn('BankID Collect received a NetworkError: retrying...');
          return true;
        }

        return false;
      },
      enabled: orderRef != null && started && !complete && !isError,
      refetchInterval: 1000,
      onSuccess: (data: BankIDCollect | null) => {
        if (data?.bankIdStatus === BankIDStatuses.COMPLETE) {
          setComplete(true);
        }
      },
    },
  );
};

export const useBankIDLogin = (loginMode?: BankIDLoginMode) => {
  const [started, setStarted] = useState(false);
  const [orderRef, setOrderRef] = useState<string>();
  const [autoStartToken, setAutoStartToken] = useState<string>();
  const [startError, setStartError] = useState<unknown>();
  const queryClient = useQueryClient();

  const {
    data: collectData,
    isLoading: collectIsLoading,
    error: collectError,
  } = useBankIDCollect(started, orderRef ?? null);

  const { isNative } = platform;

  /**
   * Stop and remove bankID cache when finished, otherwise
   * it could cause troubles on the following log-in attempt.
   **/
  const stopAndCleanup = useCallback(() => {
    setStarted(false);
    setAutoStartToken(undefined);
    setOrderRef(undefined);
    queryClient.removeQueries(['bankIDLogin']);
  }, [queryClient]);

  /**
   * startBankIDLoginSynchronously calls the bankid start api in a synchronous
   * way avoiding anything async/await to satisfy any popup blocker issues/rules
   * that safari and firefox have set up.
   * A successful start will trigger the BankID collect api.
   * @param loginMode if its authentication using the QR code or open up bankid
   * app on this device.
   */
  const startBankIDLoginSynchronously = (loginMode: BankIDLoginMode) => {
    const bankIdStartUrl = `${
      Config.Urls.base.domain
    }/api/v2/auth/bankid/start`;

    try {
      fetch(bankIdStartUrl, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'POST',
      })
        .then((response) => {
          if (!response.ok) {
            throw new ResponseError({ status: response.status, ok: false });
          }
          void response.json().then((body) => {
            const { orderRef, autoStartToken } = body;
            if (orderRef == null || autoStartToken == null) {
              throw new ResponseError({ data: body, ok: false });
            }

            setStarted(true);

            setOrderRef(orderRef as string);
            setAutoStartToken(autoStartToken as string);

            // For login on the same device, we try to open the bankid app.
            if (loginMode === BankIDLoginModes.DEVICE) {
              openBankIDApp(autoStartToken as string);
            }
          });
        })
        .catch(function (res) {
          console.log(res?.json?.());
        });
    } catch (error) {
      console.error(`Failed to start BankID.`, error);
      setStartError(error);
    }
  };

  const openBankIDApp = (autoStartToken: string) => {
    try {
      openBankIDLink(autoStartToken);
    } catch (err) {
      const respErr: AuthResponseError = {
        error: {
          code: AuthErrorCodes.BANKID_START_FAILED,
          message: `Open BankID ${isNative ? 'on device' : 'on web'} failed`,
        },
      };

      setStartError(new ResponseError({ data: respErr, ok: false }));
    }
  };

  useEffect(() => {
    return () => {
      stopAndCleanup();
    };
  }, []);

  useEffect(() => {
    if (collectData?.bankIdStatus === BankIDStatuses.COMPLETE) {
      if (isNative && loginMode === BankIDLoginModes.DEVICE) {
        // For native, if the bankid app was opened, let the bottom sheet linger
        // a bit when coming back to the app to make the UI less jumpy.
        setTimeout(() => {
          void queryClient.invalidateQueries([authenticationQueryKey]);
        }, 500);
      } else {
        void queryClient.invalidateQueries([authenticationQueryKey]);
      }
      void queryClient.invalidateQueries([MARKETING_CONSENT_QUERY_KEY]);
    }
  }, [collectData]);

  const isLoading = collectIsLoading;
  const error = startError ?? collectError;

  return {
    start: startBankIDLoginSynchronously,
    stop: stopAndCleanup,
    qrData: collectData?.qrData,
    autoStartToken,
    bankIdStatus: collectData?.bankIdStatus,
    bankIdHintCode: collectData?.bankIdHintCode,
    isLoading,
    error,
  };
};
