import useMediaQuery from '@mui/material/useMediaQuery';

import { RESPONSIVE_SCREEN_WIDTH_BREAKPOINT } from '../constants';

/**
 * Decides if the current width can be considered small.
 *
 * Use this hook if what you're trying to achieve is responsiveness.
 *
 * **Do not use this if what you're trying to achieve is a
 * different user experience between desktop and mobile phone.**
 */
export const useIsSmallScreen = () => {
  const hasSmallWidth = useMediaQuery(
    `(max-width:${RESPONSIVE_SCREEN_WIDTH_BREAKPOINT}px)`,
  );
  return hasSmallWidth;
};
