import React, { type PropsWithChildren, useState } from 'react';

import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';

import {
  BottomSheetContext,
  type BottomSheetOptions,
} from './BottomSheetContext';

export const BottomSheetProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>();
  const [options, setOptions] = useState<BottomSheetOptions>({
    avoidKeyboard: false,
    mountOnEnter: false,
    unmountOnExit: false,
  });

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <BottomSheetContext.Provider
      value={{
        open,
        close,
        setContent,
        setOptions,
      }}
    >
      <BottomSheet
        isOpen={isOpen}
        onClose={close}
        avoidKeyboard={options.avoidKeyboard}
        mountOnEnter={options.mountOnEnter}
        unmountOnExit={options.unmountOnExit}
      >
        {content}
      </BottomSheet>
      {children}
    </BottomSheetContext.Provider>
  );
};
