import { type Drug } from '../api/models/conversation';

export const getStrengthFormPackageSizeText = (drug: Drug) => {
  let str = drug.strength;
  if (drug.strength !== '' && drug.form !== '') {
    str += ', ';
  }
  str += drug.form;
  if (drug.form !== '' && drug.packageSize !== '') {
    str += ', ';
  }
  str += drug.packageSize;
  return str;
};
