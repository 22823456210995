import React, { useEffect, useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';

import {
  ActionDict,
  ConsentIDDict,
} from '../../../api/models/marketingConsent';
import { useMarketingConsentLogMutator } from '../../../hooks/useMarketingConsentLogMutator';
import { useMarketingConsentMutator } from '../../../hooks/useMarketingConsentMutator';
import DoctorIcon from '../../DoctorIcon/web';

const MarketingConsentBottomSheet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [acceptsNewslettersConsent, setAcceptsNewslettersConsent] =
    useState(false);
  const [acceptsDataUsageConsent, setAcceptsDataUsageConsent] = useState(false);
  const marketingConsentMutator = useMarketingConsentMutator();
  const marketingConsentLogMutator = useMarketingConsentLogMutator();
  const t = useTranslation('marketing', 'consentBottomSheet');

  const submitConsent = () => {
    marketingConsentMutator.mutate({
      consents: [
        {
          id: ConsentIDDict.V1MarketingCommunication,
          enabled: acceptsNewslettersConsent,
        },
        {
          id: ConsentIDDict.V1MarketingTracking,
          enabled: acceptsDataUsageConsent,
        },
      ],
    });
    setIsOpen(false);
  };

  const logConsentViewAction = () => {
    marketingConsentLogMutator.mutate({
      consents: [
        {
          id: ConsentIDDict.V1MarketingCommunication,
          action: ActionDict.ActionViewed,
        },
        {
          id: ConsentIDDict.V1MarketingTracking,
          action: ActionDict.ActionViewed,
        },
      ],
    });
  };

  useEffect(() => {
    if (isOpen) {
      logConsentViewAction();
    }
  }, [isOpen]);

  return (
    <BottomSheet isOpen={isOpen} onClose={submitConsent}>
      <BottomSheetHeader onClose={submitConsent} spacing="2rem">
        <DoctorIcon />
        <Typography variant="h2">{t('title')}</Typography>
      </BottomSheetHeader>
      <BottomSheetContent>
        <Stack spacing="1.5rem">
          <Typography variant="body1">{t('information')}</Typography>

          <Stack spacing="0.75rem">
            <Stack spacing="0.5rem" direction="row" alignItems="center">
              <Checkbox
                checked={acceptsNewslettersConsent}
                onChange={(e) => {
                  setAcceptsNewslettersConsent(e.target.checked);
                }}
              />
              <Typography variant="body1">
                {t('newslettersCheckbox')}
              </Typography>
            </Stack>
            <Stack spacing="0.5rem" direction="row" alignItems="center">
              <Checkbox
                checked={acceptsDataUsageConsent}
                onChange={(e) => {
                  setAcceptsDataUsageConsent(e.target.checked);
                }}
              />
              <Typography variant="body1">{t('dataUsageCheckbox')}</Typography>
            </Stack>
          </Stack>

          <Typography variant="body2" gutterBottom>
            {t('note')}
          </Typography>
          <Button
            disabled={marketingConsentMutator.isLoading}
            onClick={submitConsent}
          >
            {t('submitButton')}
          </Button>
        </Stack>
      </BottomSheetContent>
    </BottomSheet>
  );
};

export default MarketingConsentBottomSheet;
