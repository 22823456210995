import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { type TosResponse } from './models/policy';
import { PrivacySchema, TosSchema } from './schema/policy';

const BASE_URL = '/api/v2/user/policy';

const getTosDocument = async (): Promise<ApiResponse<TosResponse>> => {
  return await requestHandler.get(`${BASE_URL}/tos`, {
    validationSchema: TosSchema,
  });
};

const getPrivacyDocument = async (): Promise<ApiResponse<TosResponse>> => {
  return await requestHandler.get(`${BASE_URL}/privacy`, {
    validationSchema: PrivacySchema,
  });
};

const acceptTos = async (): Promise<ApiResponse<void>> => {
  return await requestHandler.post(`${BASE_URL}/tos`);
};

const acceptPrivacy = async (): Promise<ApiResponse<void>> => {
  return await requestHandler.post(`${BASE_URL}/privacy`);
};

export const policyApi = {
  getTosDocument,
  getPrivacyDocument,
  acceptTos,
  acceptPrivacy,
};
