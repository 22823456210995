import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Divider, Stack, Typography } from '@mui/material';

import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';

import PrescriptionIcon from './PrescriptionIcon/PrescriptionIcon';
import {
  type PrescriptionItemProps,
  type PrescriptionSheetContentProps,
} from './types';

const PrescriptionItem: React.FC<PrescriptionItemProps> = ({ drug }) => {
  const { productName, strength, form, dosage, packageSize, limit } = drug;

  const t = useTranslation('messaging', 'prescriptionMessage');
  return (
    <Stack spacing={'0.5rem'}>
      <Stack>
        <Typography variant="h3" sx={{ marginBottom: '0.25rem' }}>
          {productName}
        </Typography>
        <Typography>
          {strength}
          {strength !== '' && form !== '' && ', '}
          {form}
        </Typography>
        <Typography>{packageSize}</Typography>
      </Stack>
      <Stack>
        <Typography>
          {`${t('dosage')}: `}
          <Typography component={'span'} fontWeight={'medium'}>
            {dosage}
          </Typography>
        </Typography>
        <Typography>
          {`${t('withdrawals')}: `}
          <Typography component={'span'} fontWeight={'medium'}>
            {limit}
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
};

const PrescriptionSheetContent: React.FC<PrescriptionSheetContentProps> = ({
  onClose,
  drugs,
}) => {
  const t = useTranslation('messaging', 'prescriptionMessage');
  const tCommon = useTranslation('common');

  return (
    <>
      <BottomSheetHeader
        icon={<PrescriptionIcon fontSize={2.5} />}
        ariaLabelForClose={tCommon('close')}
        onClose={onClose}
      >
        <Typography marginTop={'-0.5rem'} variant={'h2'}>
          {t('title')}
        </Typography>
      </BottomSheetHeader>
      <BottomSheetContent>
        <Stack spacing={2}>
          {drugs.map((drug, index) => (
            <Stack key={index} spacing={2}>
              <>
                {index !== 0 && <Divider />}
                <PrescriptionItem drug={drug} />
              </>
            </Stack>
          ))}
        </Stack>
      </BottomSheetContent>
    </>
  );
};

export default PrescriptionSheetContent;
