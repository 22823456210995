import { type ThemeOptions } from '@mui/material/styles';

import { colors } from '../../../colors';
import {
  borderWidth,
  checkboxVisualSize,
  checkboxWrapperSize,
  margin,
} from '../constants';

const overrides: ThemeOptions['components'] = {
  MuiCheckbox: {
    defaultProps: {
      color: 'hero',
    },
    styleOverrides: {
      root: () => ({
        padding: 0,

        '&.Mui-checked .MuiSvgIcon-root': {
          fontSize: checkboxWrapperSize,
        },

        '&:not(.Mui-checked)': {
          fontSize: checkboxVisualSize,
        },

        // The path is not editable enough, so we hide it
        '&:not(.Mui-checked) path': {
          visibility: 'hidden',
        },

        // We create our unchecked icon using borders,
        // we compensate the sizing adding back the missing margin
        '&:not(.Mui-checked) svg': {
          borderWidth: `${borderWidth}px`,
          borderStyle: 'solid',
          borderRadius: '4px',
          borderColor: colors.gray[500],
          display: 'block',
          margin: `${margin}px`,
        },
      }),
    },
  },
};

const checkboxOverrides = overrides?.MuiCheckbox;
export default checkboxOverrides;
