import { type z } from 'zod';

import { type UserStatusSchema } from '../schema/userStatus';

export const UserStatusStatuses = {
  UserStatusNotVerifiedByAdmin: 0,
  UserStatusLoginAllowed: 1,
  UserStatusDeleted: 2,
  UserStatusPending: 3,
  UserStatusDeactivated: 4,
} as const;

export type UserStatusStatusesKey = keyof typeof UserStatusStatuses;
export type UserStatusStatusesValue =
  (typeof UserStatusStatuses)[UserStatusStatusesKey];

export type UserStatusResponse = z.infer<typeof UserStatusSchema>;
