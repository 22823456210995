import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import ContainerLink from '@mindoktor/pulse/src/components/ContainerLink/web';
import TextAlignedChevronIcon from '@mindoktor/pulse/src/icons/ChevronIcon/TextAlignedChevronIcon';

import { openExternalMinDoktorURL } from '@mindoktor/patient-app/utils/externalRouting/web';

import { mdVaccinationInfoURL } from '../../../constants';

const InfoLink: React.FC = () => {
  const t = useTranslation('vaccinationBook', 'downloadPage');
  return (
    <ContainerLink
      spacing="3rem"
      onClick={() => {
        openExternalMinDoktorURL(mdVaccinationInfoURL);
      }}
      endIcon={<TextAlignedChevronIcon variant="body1" />}
    >
      {t('infoLinkText')}
    </ContainerLink>
  );
};

export default InfoLink;
