import { type ThemeOptions } from '@mui/material/styles';

const overrides: ThemeOptions['components'] = {
  MuiFormHelperText: {
    defaultProps: {},
    styleOverrides: {
      root: {
        // FIXME
        // Setting a negative margin here makes sure the helper text doesn't
        // push things down below it. We might want to revisit this and find a
        // better solution. The value 23 comes from helper height + its top margin.
        marginBottom: -23,
        '&.Mui-error': {
          fontWeight: 600,
        },
      },
    },
  },
};

const muiFormHelperTextOverrides = overrides.MuiFormHelperText;
export default muiFormHelperTextOverrides;
