import React from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MDLogoHeartIcon from '@mindoktor/pulse/src/icons/MDLogoHeartIcon/MDLogoHeartIcon.web';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { formatShortDateTime } from '../../../../utils/time/formatShortDateTime';

import { type SystemMessageCardComponent } from './types';

const sharpCornerRadius = 2;

const SystemCardLayout: React.FC<SystemMessageCardComponent> = ({
  icon: Icon,
  title,
  message,
  children,
  backgroundColor = colors.white,
  avatarIcon: AvatarIcon = MDLogoHeartIcon,
}) => {
  return (
    <Stack
      direction="row"
      spacing="0.5rem"
      justifyContent="flex-start"
      sx={{ overflow: 'visible' }}
    >
      <Stack direction="column" justifyContent="flex-end">
        <AvatarIcon />
      </Stack>
      <Stack maxWidth="100%" width="100%" paddingRight="0.75rem" gap={0.5}>
        <Typography
          fontSize={11}
          color={({ palette }) => palette.grey[700]}
          paddingX={0.5}
          textAlign="right"
        >
          {formatShortDateTime(message.created)}
        </Typography>
        <Stack alignItems="stretch">
          <Card
            sx={{
              '&.MuiPaper-rounded': {
                paddingBottom: '1.5rem',
                borderBottomLeftRadius: sharpCornerRadius,
                backgroundColor,
              },
            }}
          >
            <Stack
              direction="column"
              spacing="1rem"
              paddingTop="1rem"
              width="100%"
            >
              {(Icon != null || title != null) && (
                <Stack direction="column" alignItems="center" spacing="0.5rem">
                  {Icon != null && <Icon fontSize={2.5} />}
                  {title != null && (
                    <Typography
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      gutterBottom
                    >
                      {title}
                    </Typography>
                  )}
                </Stack>
              )}
              {children}
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SystemCardLayout;
