import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useAuthentication } from '@mindoktor/patient-app/auth/hooks/useAuthentication';
import { TIME } from '@mindoktor/patient-app/utils/time/constants';

import { type UserDetailsResponse } from '../api/models/details';
import { userApi } from '../api/user';

export const userDetailsQueryKey = 'userDetails';

/**
 * useUserDetails hook fetches basic user details used during registration.
 */
export const useUserDetailsApi = () => {
  const authentication = useAuthentication();
  const notifications = useNotifications();
  const t = useTranslation('error');

  return useQuery<UserDetailsResponse | undefined, ResponseError>(
    [userDetailsQueryKey],
    async () => {
      const response = await userApi.getDetails();
      if (!response?.ok) {
        throw new ResponseError(response);
      }

      return response?.data;
    },
    {
      retry: false,
      staleTime: 1 * TIME.HOUR,
      enabled: authentication.isAuthenticated,
      onError: (_error) => {
        notifications.showWarning(t('unknown'));
      },
    },
  );
};
