import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { type ConversationResponse } from './models/conversation';
import {
  type MarkLastReadRequest,
  type MessageRequest,
  type MessageResponse,
} from './models/message';
import { ConversationResponseSchema } from './schema/conversationSchema';

const BASE_URL = '/api/v1/gws/patient';

const getConversation = async (
  conversationId: number,
): Promise<ApiResponse<ConversationResponse>> => {
  return await requestHandler.get(
    `${BASE_URL}/conversations/${conversationId}`,
    { validationSchema: ConversationResponseSchema },
  );
};

const postMarkMessageRead = async (
  conversationId: number,
  payload: MarkLastReadRequest,
): Promise<ApiResponse<void>> => {
  return await requestHandler.post(
    `${BASE_URL}/conversations/${conversationId}/messages/last-read`,
    payload,
  );
};

const postMessage = async (
  conversationId: number,
  message: MessageRequest,
): Promise<ApiResponse<MessageResponse>> => {
  return await requestHandler.post(
    `${BASE_URL}/conversations/${conversationId}/messages`,
    message,
  );
};

export const messagingApi = {
  getConversation,
  postMarkMessageRead,
  postMessage,
};
