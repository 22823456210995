import { type z } from 'zod';

import {
  type APHMessageSchema,
  type AttachmentSchema,
  type AuthorSchema,
  type ConversationResponseSchema,
  type FreepassMessageSchema,
  type GreetingCaseMessageSchema,
  type InvoiceMessageSchema,
  type MessageSchema,
  type ParticipantsSchema,
  type PrescriptionMessageSchema,
  type TextMessageSchema,
  type ThankYouMessageSchema,
} from '../schema/conversationSchema';

export enum MessageType {
  TextMessage = 'MessageTypeText',
  GreetingCase = 'MessageTypeGreetingCase',
  ThankYou = 'MessageTypeThankYou',
  Invoice = 'MessageTypeInvoice',
  Freepass = 'MessageTypeFreepass',
  Prescription = 'MessageTypePrescription',
  APH = 'MessageTypePharmacyAPH',
}

export type Author = z.infer<typeof AuthorSchema>;
export type ConversationResponse = z.infer<typeof ConversationResponseSchema>;
export type Conversation = ConversationResponse['data'];
export type Participants = z.infer<typeof ParticipantsSchema>;
export type Message = z.infer<typeof MessageSchema>;

export type Attachment = z.infer<typeof AttachmentSchema>;
export type TextMessage = z.infer<typeof TextMessageSchema>;
export type InvoiceMessage = z.infer<typeof InvoiceMessageSchema>;
export type GreetingCaseMessage = z.infer<typeof GreetingCaseMessageSchema>;
export type ThankYouMessage = z.infer<typeof ThankYouMessageSchema>;
export type FreepassMessage = z.infer<typeof FreepassMessageSchema>;
export type PrescriptionMessage = z.infer<typeof PrescriptionMessageSchema>;
export type APHMessage = z.infer<typeof APHMessageSchema>;

export const isOwnMessage = (
  author: Author | null | undefined,
  userId: number | null | undefined,
): boolean => userId != null && author?.userId === userId;
