import React, { useEffect, useState } from 'react';
import { DEFAULT_LOCALE } from '@mindoktor/localization/src/constants';
import LocaleProvider from '@mindoktor/localization/src/context/LocaleProvider';
import { useLocale } from '@mindoktor/localization/src/hooks/useLocale';

import { getPersistentStore } from '@mindoktor/patient-app/store/store';

import { resources } from '../resources';

const LOCALE_STORE_KEY = 'locale';

const LocaleCookieManager: React.FC = () => {
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const locale = useLocale();
  const store = getPersistentStore();

  // Read cookies
  useEffect(() => {
    const f = async () => {
      const persistedLocale = await store.get(LOCALE_STORE_KEY);
      if (persistedLocale != null) {
        locale.setCurrentLocale(persistedLocale);
      }
      setHasLoadedOnce(true);
    };
    void f();
  }, []);

  // Write cookies
  useEffect(() => {
    if (!hasLoadedOnce) {
      return;
    }

    const f = async () => {
      await store.set(LOCALE_STORE_KEY, locale.currentLocale);
    };
    void f();
  }, [locale.currentLocale, hasLoadedOnce]);
  return null;
};

const LocalizationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <LocaleProvider
      initialLocale={DEFAULT_LOCALE}
      languageResources={resources}
    >
      <LocaleCookieManager />
      {children}
    </LocaleProvider>
  );
};

export default LocalizationProvider;
