import { createTheme, type ThemeOptions } from '@mui/material/styles';

import { breakpoints } from '../breakpoints';
import { palette } from '../colors';
import { weights } from '../fonts';
import muiButtonOverrides from '../overrides/Button/web/buttonOverrides';
import muiCheckboxOverrides from '../overrides/Checkbox/web/checkboxOverrides';
import muiContainerOverrides from '../overrides/Container/web/containerOverrides';
import muiFormControlOverrides from '../overrides/FormControl/web/formControlOverrides';
import muiFormHelperTextOverrides from '../overrides/FormHelperText/web/formHelperTextOverrides';
import muiIconButtonOverrides from '../overrides/IconButton/web/iconButtonOverrides';
import muiFilledInputOverrides from '../overrides/Input/web/filledInputOverrides';
import muiInputLabelOverrides from '../overrides/Input/web/inputLabelOverrides';
import muiInputOverrides from '../overrides/Input/web/inputOverrides';
import muiLinkOverrides from '../overrides/Link/web/linkOverrides';
import muiPaperOverrides from '../overrides/Paper/web/paperOverrides';
import muiSkeletonOverrides from '../overrides/Skeleton/web/skeletonOverrides';
import muiTextFieldOverrides from '../overrides/TextField/web/textFieldOverrides';
import muiTypographyOverrides from '../overrides/Typography/web/typographyOverrides';

export const pulseThemeOptions: ThemeOptions = {
  palette,

  // Font and texts
  typography: {
    fontFamily: [
      '"Graphik"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Roboto',
      'Roboto',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: weights.light,
    fontWeightRegular: weights.regular,
    fontWeightMedium: weights.medium,
    fontWeightSemiBold: weights.semibold,
    fontWeightBold: weights.bold,
  },

  components: {
    MuiIconButton: muiIconButtonOverrides,
    MuiButton: muiButtonOverrides,
    MuiContainer: muiContainerOverrides,
    MuiInput: muiInputOverrides,
    MuiPaper: muiPaperOverrides,
    MuiSkeleton: muiSkeletonOverrides,
    MuiTextField: muiTextFieldOverrides,
    MuiInputLabel: muiInputLabelOverrides,
    MuiFilledInput: muiFilledInputOverrides,
    MuiFormControl: muiFormControlOverrides,
    MuiFormHelperText: muiFormHelperTextOverrides,
    MuiTypography: muiTypographyOverrides,
    MuiLink: muiLinkOverrides,
    MuiCheckbox: muiCheckboxOverrides,
  },
  breakpoints: {
    values: breakpoints,
  },
};

const pulseTheme = createTheme(pulseThemeOptions);

export default pulseTheme;
