/* eslint-disable react/prop-types */
import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component, useEffect } from 'react';

import { loadGuideQuestionsAndFreePass } from '../../../state/guides/actions';
import {
  getGuideByEntrywayId,
  getGenderMessageByEntrywayId,
} from '../../../state/guides/selectors';

import { hasReachedCaseLimit } from '../../../state/cases/actions';

import { fetchInquisition } from '../../../state/inquisitions/actions';

import { validateRevisitId } from '../../../state/profile/actions';
import {
  getPatientNameAndBirthDate,
  getValidGuideRevisitInvitation,
  getChild,
  profileIdentityProtection,
} from '../../../state/profile/selectors';
import { getAge } from '../../../state/profile/profileUtils';

import { openRoute, routes as legacyRoutes } from '../../../state/routing';

import colors from '../../../common/colors';

import { showModal } from '../../routing';

import BoxedScreen from '../../components/boxed_screen';
import { Title1 } from '../../../common/components/typography';
import Markdown from '../../components/markdown';
import UserInfoPlate from '../../../common/components/UserInfoPlate';
import Button from '../../components/button/button';
import Checkbox from '../../../common/components/input/Checkbox';

import Warning from '../../components/warning';

import { getGuideImageByEntrywayId } from '../utils/images';

import GuideBar from '../components/bar';
import MediaQuery from '../../components/media_query';

import CaseLimitModal from './case_limit_modal';

import {
  getInformedConsentsByEntrywayId,
  isInformedConsentRequired,
} from '../../../state/consents/selectors';
import { setInformedConsent } from '../../../state/consents/actions';
import constants from '../../../common/constants';
import { getDomainPublicInfo } from '../../../state/api/selectors';

import { GuideStartOrigin } from '../../../state/tracking/constants';
import {
  MarketingConsentHandler,
  MARKETING_CONSENT_FEATURE_FLAG,
} from '@mindoktor/patient-app/marketing-consents/web';
import { Invitation } from '../../../state/profile/types_definitions';
import { hasFeatureFlag } from '../../../state/featureflags/selectors';
import { Placings } from '@mindoktor/patient-app/marketing-consents/components/MarketingConsentHandler/web';
import { WithTrackPageViewProps } from '@mindoktor/patient-app/tracking/HOC/withTrackPageView';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';
import { getFreePass } from '../../../state/freepass/selectors';
import {
  isValidEFrikort,
  isValidFreePass,
} from '../../../state/freepass/utils';
import { getPaymentMethod } from '../../../state/payment/selectors';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useParams } from '@mindoktor/patient-app/routing/hooks/useParams/useParams';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { useQueryParams } from '@mindoktor/patient-app/routing/hooks/useQueryParams/web';
import { isSkandiaSite } from '@mindoktor/patient-app/layout/components/company/SkandiaAttribution';
import SkandiaLoginBottomSheet from '@mindoktor/patient-app/skandia/components/SkandiaLoginBottomSheet';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';

const styles = {
  intro: {
    padding: 20,
  },

  journal: {
    color: colors.red_500,
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: 10,
  },

  user: {
    marginBottom: 20,
  },

  body: {
    maxWidth: 600,
    marginBottom: constants.spacing16,
  },

  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  buttonWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    width: 300,
    margin: '0 10 10 10',
  },
  buttonWide: {
    width: 'calc(100% - 20px)',
    margin: '0 10 10 10',
  },
} as const;

interface OwnProps {
  entrywayId: number;
  childUuid?: string;
  revisitId?: number;
  dynamicCode?: string;

  preferredCaregiverId?: unknown;
}

type PropsFromRedux = any;
type Props = OwnProps & PropsFromRedux & WithTrackPageViewProps;

export class PrestartScreen extends Component<Props> {
  state = {
    submitting: false,
    informedConsent: false,
  };

  updateInformedConsent = () => {
    this.setState({ informedConsent: !this.state.informedConsent });
  };

  openQuestions = () => {
    const {
      entrywayId,
      childUuid,
      revisitId,
      dynamicCode,
      requireBloodPressure,
      hasDynamicCode,
      inquisitionId,
      openRoute,
      preferredCaregiverId,
      category,
      navigation: { navigate },
    } = this.props;

    const routeParams = {
      childUuid,
      revisitId,
      dynamicCode,
      origin: GuideStartOrigin.GUIDE_PICKER,
      preferredCaregiverId,
    };

    const routeProps = {
      entrywayId,
      category,
    };

    if (requireBloodPressure) {
      navigate(routes.GUIDES_BLOOD_PRESSURE, routeProps);
    } else if (hasDynamicCode && !!dynamicCode) {
      navigate(routes.GUIDES_DYNAMIC_CODE, routeProps);
    } else {
      if (inquisitionId) {
        openRoute(legacyRoutes.INQUISITION, { ...routeProps, ...routeParams });
        return;
      }
      navigate(routes.GUIDES_QUESTIONS, routeProps, routeParams);
    }
  };

  openGuides = () => {
    const {
      childUuid,
      navigation: { navigate },
    } = this.props;

    navigate(routes.GUIDES, { entrywayId: childUuid ? 'child' : 'adult' });
  };

  backToIntro = () => {
    const {
      navigation: { navigate },
      entrywayId,
      revisitId,
      dynamicCode,
    } = this.props;

    navigate(
      routes.GUIDES,
      {
        entrywayId,
      },
      {
        revisitId,
        dynamicCode,
      }
    );
  };

  showCaseLimitModal = () => showModal(CaseLimitModal);

  openRevisitGuide = (invitation: Invitation) => {
    const {
      navigation: { navigate },
    } = this.props;

    const {
      guideParams: { entrywayId, guideType },
      patientUUID: patientUuid,
      id: revisitId,
    } = invitation;

    navigate(
      routes.GUIDES_QUESTIONS,
      {
        entrywayId,
      },
      {
        childUuid: guideType === 'children' ? patientUuid : undefined,
        revisitId,
        origin: GuideStartOrigin.GUIDE_PICKER,
      }
    );
  };

  proceed = async () => {
    const {
      entrywayId,
      childUuid,
      revisitId,
      hasReachedCaseLimit,
      validateRevisitId,
      setInformedConsent,
    } = this.props;

    this.setState({ submitting: true });

    const { blocked, error } = await hasReachedCaseLimit(entrywayId, childUuid);

    await setInformedConsent(entrywayId, 'first', true);

    if (error || blocked) {
      this.setState({ submitting: false });

      if (blocked) this.showCaseLimitModal();

      return;
    }

    if (revisitId) {
      const { valid } = await validateRevisitId(revisitId);

      if (valid) {
        this.openQuestions();
      } else {
        this.setState({ submitting: false });
      }

      return;
    }

    const { invitation } = this.props;

    // if there's an pending invitation on this guide start it instead.
    const childInvitation =
      invitation && !!childUuid && invitation.patientUUID === childUuid;
    const adultInvitation = invitation && !!!childUuid;

    this.setState({ submitting: false });
    if (childInvitation || adultInvitation) {
      this.openRevisitGuide(invitation);
    } else {
      this.openQuestions();
    }
  };

  load = () => {
    const {
      entrywayId,
      childUuid,
      revisitId,
      dynamicCode,
      inquisitionId,
      loadGuideQuestionsAndFreePass,
      fetchInquisition,
    } = this.props;

    loadGuideQuestionsAndFreePass({
      entrywayId,
      childUuid,
      revisitId,
      dynamicCode,
    });

    if (inquisitionId) {
      fetchInquisition(inquisitionId, childUuid);
    }
  };

  componentDidMount() {
    const { loadedGuide } = this.props;

    if (loadedGuide) {
      this.load();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { loadedGuide } = newProps;
    const { loadedGuide: previouslyLoadedGuide } = this.props;

    if (loadedGuide !== previouslyLoadedGuide) this.load();
  }

  render() {
    const {
      loadedGuide,
      loadedProfile,
      iconSource,
      title,
      body,
      genderMessage,
      fullName,
      birthDate,
      ageGroup,
      informedConsentRequired,
      identityProtection,
      isMarketingConsentFlagOn,
    } = this.props;
    const { submitting, informedConsent } = this.state;

    const isSkandia = isSkandiaSite();

    const proceedButton = () => {
      const button = (
        <Button
          hero
          disabled={submitting || (informedConsentRequired && !informedConsent)}
          onClick={this.proceed}
        >
          {_t('guides.continue')}
        </Button>
      );
      return (
        <div style={styles.buttonWrap}>
          <MediaQuery maxWidth={600}>
            <div style={styles.buttonWide}>{button}</div>
          </MediaQuery>

          <MediaQuery minWidth={601}>
            <div style={styles.button}>{button}</div>
          </MediaQuery>
        </div>
      );
    };

    return (
      <BoxedScreen
        header={
          <GuideBar
            title={title}
            icon="back"
            iconSource={iconSource}
            onCancel={this.backToIntro}
          />
        }
        body={
          <div style={styles.intro}>
            <Title1 mb="s">{title}</Title1>
            <Markdown style={styles.body} source={body} />
            {identityProtection && (
              <Markdown
                style={styles.body}
                source={_t('identityProtection.photo.upload.info')}
              />
            )}
            {isMarketingConsentFlagOn && (
              <MarketingConsentHandler placing={Placings.intro} />
            )}
            {informedConsentRequired && (
              <Checkbox
                onChange={this.updateInformedConsent}
                checked={this.state.informedConsent}
                label={_t('consent.informed.intro')}
                labelStyle={{ color: colors.red_500 }}
              />
            )}
            {isSkandia && <SkandiaLoginBottomSheet />}
          </div>
        }
        footer={
          <div>
            {!!genderMessage && <Warning text={genderMessage} />}
            {!ageGroup.appropriateAge && (
              <Warning text={ageGroup.appropriateAgeText} />
            )}
            {loadedGuide &&
              loadedProfile &&
              !genderMessage &&
              ageGroup.appropriateAge && (
                <div style={styles.footer}>
                  <UserInfoPlate fullName={fullName} birthDate={birthDate} />
                  {proceedButton()}
                </div>
              )}
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

const PrestartScreenWrapper = () => {
  const { entrywayId } = useParams();
  const queryParams = useQueryParams();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const childUuid = queryParams.get('childUuid');
  const revisitId = Number(queryParams.get('revisitId'));
  const dynamicCode = queryParams.get('dynamicCode');
  const marketingTracking = useMarketingTracking();

  const props = {
    ...useAppSelector((state) => {
      const _entrywayId = parseInt(entrywayId ?? '', 10);

      const guide = getGuideByEntrywayId(state, _entrywayId);

      const {
        displayTitle: title,
        introText: body,
        requireBloodPressure,
        resultCodeInput,
        inquisitionId,
        minAge,
        maxAge,
      } = guide || {};
      const { initials, fullName, birthDate } = getPatientNameAndBirthDate(
        state,
        childUuid
      );
      const age = !childUuid
        ? state.profile.age
        : getAge(getChild(state, childUuid).birthDate).yearsFloat;
      const appropriateAge = age >= minAge && age <= maxAge;
      const appropriateAgeText =
        age < minAge
          ? _t('errorMessage.guide.tooYoung')
          : age > maxAge
            ? _t('errorMessage.guide.tooOld')
            : '';
      const identityProtection = profileIdentityProtection(state);
      const informedConsent = getInformedConsentsByEntrywayId(
        entrywayId,
        state
      );
      const informedConsentRequired = isInformedConsentRequired(state);
      const domainPublicInfo = getDomainPublicInfo(state);
      const isOpeningHours = domainPublicInfo
        ? Boolean(domainPublicInfo.isOpeningHours)
        : true;
      return {
        isOpeningHours,
        loadedGuide: !!guide,
        loadedProfile: !!initials,
        iconSource: getGuideImageByEntrywayId(_entrywayId),
        title,
        body,
        genderMessage: guide
          ? getGenderMessageByEntrywayId(state, _entrywayId)
          : undefined,
        requireBloodPressure,
        isMarketingConsentFlagOn: hasFeatureFlag(
          state,
          MARKETING_CONSENT_FEATURE_FLAG
        ),
        hasDynamicCode: !!resultCodeInput,
        initials,
        fullName,
        birthDate,
        ageGroup: { appropriateAge, appropriateAgeText },
        freePass: getFreePass(state),
        invitation: getValidGuideRevisitInvitation(
          state,
          _entrywayId,
          childUuid
        ),
        informedConsent,
        informedConsentRequired,
        inquisitionId,
        identityProtection,
        paymentMethod: getPaymentMethod(state, _entrywayId),
      };
    }),
    navigation,
    entrywayId,
    childUuid,
    revisitId,
    dynamicCode,
    hasReachedCaseLimit: (...args: any) =>
      dispatch(hasReachedCaseLimit(...args)),
    validateRevisitId: (...args: any) => dispatch(validateRevisitId(...args)),
    loadGuideQuestionsAndFreePass: (...args: any) =>
      dispatch(loadGuideQuestionsAndFreePass(...args)),
    openRoute: (...args: any) => dispatch(openRoute(...args)),
    setInformedConsent: (...args: any) => dispatch(setInformedConsent(...args)),
    fetchInquisition: (...args: any) => dispatch(fetchInquisition(...args)),
  };

  useTrackPageView({
    entrywayId: entrywayId?.toString(),
    childUuid: childUuid?.toString(),
    hasValidFreePass: isValidFreePass(props.freePass)?.toString(),
    hasValidEFrikort: isValidEFrikort(props.freePass)?.toString(),
    paymentMethod: props.paymentMethod?.toString(),
  });

  const isChild = !!childUuid;

  useEffect(() => {
    marketingTracking.track(MarketingTrackingActions.GuideIntroBViewed, {
      label: isChild ? 'child' : 'adult',
    });
  }, [entrywayId]);

  return <PrestartScreen {...props} />;
};

export default PrestartScreenWrapper;
