import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ContainerLink from '@mindoktor/pulse/src/components/ContainerLink/web';
import TextAlignedChevronIcon from '@mindoktor/pulse/src/icons/ChevronIcon/TextAlignedChevronIcon';

import { useUserProfile } from '@mindoktor/patient-app/adapters/hooks/useUserProfile';
import { routes, useNavigation } from '@mindoktor/patient-app/routing/web';

import { useConversationId } from '../../../hooks/useConversationId';
import UserCardLayout from '../../layout/UserCardLayout/UserCardLayout';

import { type GreetingCaseCardProps } from './types';

const GreetingCaseCard: React.FC<GreetingCaseCardProps> = ({ message }) => {
  const t = useTranslation('messaging', 'caseGreeting');
  const userProfile = useUserProfile();
  const conversationId = useConversationId();

  const { caseId, isMDK } = message.payload;

  const navigation = useNavigation();

  return (
    <UserCardLayout message={message}>
      <Stack direction="column" spacing="1rem">
        <Typography variant="body1" whiteSpace="pre-wrap">
          {t(isMDK ? 'titleMDK' : 'title', {
            firstName: userProfile?.firstName,
          })}
        </Typography>
        <Typography variant="body1" whiteSpace="pre-wrap">
          {t(isMDK ? 'bodyMDK' : 'body')}
        </Typography>
        <Stack alignItems="flex-end">
          <ContainerLink
            spacing="0.75rem"
            onClick={() => {
              navigation.navigate(
                routes.CASE_ANSWERS,
                {
                  caseId,
                },
                { conversationId },
              );
            }}
            endIcon={<TextAlignedChevronIcon variant="body1" />}
          >
            {t('seeAnamnesis')}
          </ContainerLink>
        </Stack>
      </Stack>
    </UserCardLayout>
  );
};

export default GreetingCaseCard;
