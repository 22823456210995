import { useLocation } from 'react-router-dom';

import { getSessionId } from '@mindoktor/patient-app/api';

import { type DataTrackingEventBaseRequest } from '../api/models/dataTracking';

export const useDataTrackingRequestComposer = () => {
  const location = useLocation();

  /** Prepares a data tracking request based on the platform */
  const getTrackingRequest = (): DataTrackingEventBaseRequest => ({
    sessionId: getSessionId(),
    referrer: document.referrer,
    createdAt: new Date().toISOString(),
    uri: {
      full: window.location.href,
      schema: window.location.protocol.slice(0, -1),
      host: window.location.hostname,
      path: location.pathname,
      query: location.search.slice(1),
      fragment: location.pathname + location.search,
    },
  });

  return {
    getTrackingRequest,
  };
};
