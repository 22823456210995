import { useLegacyNavigation } from '@mindoktor/patient-app/adapters/hooks/useLegacyNavigation/web';

import { type LegacyRoute } from '../../../../adapters/routes/legacyRoutes.web';
import { type Route } from '../../../constants/webRoutes';
import { isRoute as isNewRoute } from '../../../utils/isRoute';
import { useNavigation } from '../../useNavigation/web';

/** Navigates to routes, falling back on the legacy navigator for unknown routes. */
export const useNavigationWithLegacyFallback = () => {
  const navigation = useNavigation();
  const legacyNavigation = useLegacyNavigation();

  const navigate = (route: Route | LegacyRoute, replace = false) => {
    if (isNewRoute(route)) {
      navigation.navigate(route, undefined, undefined, { replace });
      return;
    }

    // Legacy fallback
    legacyNavigation.navigate(route);
  };

  return {
    navigate,
    isNewRoute,
  };
};
