import React from 'react';
import ArticleStock from '@mindoktor/aph/src/components/ArticleStock/ArticleStock';
import APHLocalizationProvider from '@mindoktor/aph/src/localization/components/APHLocalizationProvider';
import APHThemeProvider from '@mindoktor/aph/src/style/APHThemeProvider/APHThemeProvider';
import { aphColors } from '@mindoktor/aph/src/style/colors';
import { type NavigateToInStockPharmaciesFunction } from '@mindoktor/aph/src/types';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Stack, Typography } from '@mui/material';

// TODO: Useful when completing the web implementation
// import { legacyRoutes } from '@mindoktor/patient-app/adapters/routes/legacyRoutes.web';
// import { useNavigationWithLegacyFallback } from '@mindoktor/patient-app/routing/hooks/useNavigationWithLegacyFallback/web';
import SystemCardLayout from '../../layout/SystemCardLayout/SystemCardLayout';

import APHFullLogoIcon from './APHFullLogoIcon/APHFullLogoIcon';
import APHHeartIcon from './APHHeartIcon/APHHeartIcon';
import { type APHCardProps } from './types';

const APHCard: React.FC<APHCardProps> = ({ message }) => {
  const { drugs, showInfoText } = message.payload;
  const t = useTranslation('messaging', 'aph');

  // TODO: Useful when completing the web implementation
  // const navigation = useNavigationWithLegacyFallback();
  const navigateToInStockPharmacies: NavigateToInStockPharmaciesFunction = (
    params,
  ) => {
    // TODO: Mocked as starting point when completing the web implementation
    // navigation.navigate(legacyRoutes.APH_IN_STOCK_PHARMACIES, params);
  };

  return (
    <SystemCardLayout
      message={message}
      backgroundColor={aphColors.bright}
      avatarIcon={APHHeartIcon}
    >
      <APHThemeProvider>
        <APHLocalizationProvider>
          <Stack width="100%" paddingBottom={'0.5rem'} alignItems="center">
            <APHFullLogoIcon fontSize={1.5} />
          </Stack>
          {showInfoText && <Typography>{t('infoText')}</Typography>}

          {drugs.map((drug) => (
            <ArticleStock
              navigateToInStockPharmacies={navigateToInStockPharmacies}
              article={drug}
              key={drug.nplPackId}
            />
          ))}
        </APHLocalizationProvider>
      </APHThemeProvider>
    </SystemCardLayout>
  );
};

export default APHCard;
