import { makeStyles } from '@material-ui/core/styles';
import { _t } from '@mindoktor/patient-legacy/i18n';
import classNames from 'classnames';
import React, { useState } from 'react';
import Markdown from '../../components/markdown';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import billogramIcon from '../../../../images/billogram/billogram_icon_grey.svg';
import infoIcon from '../../../../images/icon/ic_info_alt.svg';
import constants from '../../../common/constants';

import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetActions from '@mindoktor/pulse/src/components/BottomSheetActions/web';
import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';

interface Props {
  classes?: any;
}

const InvoiceInfo: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openInfoModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={classes.textContainer}>
        <Typography variant="body2" fontWeight="semiBold" gutterBottom>
          {_t('payment.paymentSelection.title')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {_t('payment.paymentSelection.bodyPart1')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {_t('payment.paymentSelection.bodyPart2')}
        </Typography>
      </div>

      <div
        className={classNames(classes.icon, classes.infoIcon)}
        onClick={openInfoModal}
      >
        <Img src={infoIcon} />
      </div>
      <div className={classNames(classes.icon, classes.billogramIcon)}>
        <Img src={billogramIcon} />
      </div>

      <BottomSheet isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <BottomSheetHeader
          ariaLabelForClose={_t('common.close')}
          onClose={() => setIsModalOpen(false)}
        >
          <Typography variant="h2">
            {_t('payment.paymentSelection.invoiceInfoModal.header')}
          </Typography>
        </BottomSheetHeader>
        <BottomSheetContent>
          <Typography variant="body1" gutterBottom>
            {_t('payment.paymentSelection.invoiceInfoModal.bodyPart1')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {_t('payment.paymentSelection.invoiceInfoModal.bodyPart2')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {_t('payment.paymentSelection.invoiceInfoModal.bodyPart3')}
          </Typography>
          <Typography variant="body1">
            <Markdown
              source={_t('payment.paymentSelection.invoiceInfoModal.bodyPart4')}
            />
          </Typography>
        </BottomSheetContent>
        <BottomSheetActions
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: { display: 'none' },
          })}
        >
          <Button
            onClick={() => setIsModalOpen(false)}
            color="hero"
            aria-label={_t('common.close')}
          >
            {_t('common.close')}
          </Button>
        </BottomSheetActions>
      </BottomSheet>
    </>
  );
};

const useStyles = makeStyles({
  textContainer: {
    width: '90%',
  },
  title: {
    marginBottom: constants.spacingExtraSmall / 2,
  },
  body: {
    marginBottom: constants.spacingExtraSmall,
  },

  icon: {
    position: 'absolute',
  },
  infoIcon: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    top: 16,
    right: 16,
  },
  billogramIcon: {
    bottom: 16,
    right: 16,
  },
});

export default InvoiceInfo;
