import React from 'react';

import { Alert } from '@mui/material';

import { type SnackbarProps } from './types';

const Snackbar: React.FC<SnackbarProps> = ({ type, text, onClose }) => {
  return (
    <Alert
      variant="filled"
      severity={type}
      icon={false}
      onClose={onClose}
      sx={{
        '&.MuiPaper-rounded': {
          borderRadius: '0',
          padding: '0.75rem',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      {text}
    </Alert>
  );
};

export default Snackbar;
