import apotekHjartatEn from './locales/en/apotekHjartat.json';
import apotekHjartatSv from './locales/sv/apotekHjartat.json';

export const resources = {
  en: {
    apotekHjartat: apotekHjartatEn,
  },
  sv: {
    apotekHjartat: apotekHjartatSv,
  },
};
