import React, { useEffect, useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetActions from '@mindoktor/pulse/src/components/BottomSheetActions/web/BottomSheetActions';
import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';

import { SKANDIA_COMPANY_ID } from '@mindoktor/patient-legacy/src/state/api/constants';

import LegacyMarkdown from '@mindoktor/patient-app/adapters/components/LegacyMarkdown/LegacyMarkdown';

import { useApiSession } from '../../adapters/hooks/useApiSession';

const SkandiaLoginBottomSheet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslation('skandia', 'loginWarningSheet');

  const session = useApiSession();

  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    // If this is a skandia patient (from skandia.mindoktor.se) we check that
    // they have an external id and that the user is logged in via SSO.
    // Otherwise we show the bottom sheet directing them to skandias login site
    // for online care.
    if (
      session?.companyId === SKANDIA_COMPANY_ID &&
      (session?.externalId == null ||
        session?.externalId === '' ||
        session?.method !== 'sso')
    ) {
      open();
    }
  }, [session]);

  return (
    <BottomSheet isOpen={isOpen} onClose={close}>
      <BottomSheetHeader onClose={close}>
        <Typography variant="h2">{t('title')}</Typography>
      </BottomSheetHeader>
      <BottomSheetContent>
        <Stack spacing="1rem">
          <Typography variant="body1">{t('body')}</Typography>

          <LegacyMarkdown source={t('linkText')} />
        </Stack>
      </BottomSheetContent>
      <BottomSheetActions sx={{ justifyContent: 'center' }}>
        <Button onClick={close}>{t('close')}</Button>
      </BottomSheetActions>
    </BottomSheet>
  );
};

export default SkandiaLoginBottomSheet;
