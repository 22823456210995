import { type Store } from './types';

const getWebStore: () => Store = () => ({
  get: async (key) => window.localStorage.getItem(key),
  set: async (key, value) => {
    window.localStorage.setItem(key, value);
    return value;
  },
  remove: async (key) => {
    window.localStorage.removeItem(key);
  },
});

export default getWebStore;
