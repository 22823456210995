import { useEffect, useRef, useState } from 'react';

import Config from '@mindoktor/env/Config';

import { type FormKeyboardItem } from '@mindoktor/patient-app/utils/forms/hooks/types';
import { useFormKeyboardActions } from '@mindoktor/patient-app/utils/forms/hooks/useFormKeyboardActions';

import { type EmailLoginForm } from '../api/models/email';
import { EmailLoginFormSchema } from '../api/schema/email';

interface EmailLoginErrors {
  email?: string[];
  password?: string[];
}

/**
 * Hook that handles email login form validation and keyboard enter key
 * configuration.
 */
export const useEmailLoginForm = (onSubmit: () => Promise<void>) => {
  const [values, setValues] = useState<EmailLoginForm>({
    email: '',
    password: '',
  });
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState<EmailLoginErrors>({});

  const passwordRef = useRef(null);

  const formKeyMapping: FormKeyboardItem[] = [
    { name: 'email', action: 'next' },
    { name: 'password', action: 'go', ref: passwordRef },
  ];

  const { onEnterKey } = useFormKeyboardActions(formKeyMapping, onSubmit);

  const onChange = (key: string, value: string) => {
    const newValues = {
      ...values,
      [key]: value,
    };
    setValues(newValues);
    validate(newValues);
  };

  const validate = (input: EmailLoginForm) => {
    const result = EmailLoginFormSchema.safeParse(input);
    if (result.success) {
      setErrors({});
      setIsValid(true);
      return;
    }
    setErrors(result.error.flatten().fieldErrors);
    setIsValid(false);
  };

  useEffect(() => {
    // Prefill any configured dev mail.
    // NOTE: this affects both wapp and napp email login.
    if (Config.LoginWithEmailAndPassword && Config.IsDev) {
      const { LoginEmail = '', LoginPassword = '' } = Config;
      const prefillValues = {
        email: LoginEmail,
        password: LoginPassword,
      };
      setValues(prefillValues);
      validate(prefillValues);
    } else {
      validate(values);
    }
  }, []);

  return {
    values,
    errors,
    isValid,
    onChange,
    onEnterKey,
    inputRefs: { passwordRef },
  };
};
