import { useContext } from 'react';

import { type Message } from '../api/models/conversation';
import { MessageReadContext } from '../contexts/MessageReadContext';
import { isTimestampBefore } from '../functions/isTimestampBefore';

import { type ConversationResult } from './useConversationApi';
import { useLastReadMessage } from './useLastReadMessage';

export const useMarkMessageAsRead = (conversation: ConversationResult) => {
  const lastReadMessage = useLastReadMessage(conversation);
  const { pushReadMessageReceipt } = useContext(MessageReadContext);

  const markMessageAsRead = (message: Message) => {
    if (
      lastReadMessage.isLoading ||
      isTimestampBefore(message.created, lastReadMessage.created) ||
      lastReadMessage.id === message.id
    ) {
      // We either already know about this message or lastReadMessage is not ready.
      return;
    }

    pushReadMessageReceipt({
      messageId: message.id,
      messageCreated: message.created,
    });
  };

  return markMessageAsRead;
};
