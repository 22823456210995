import { type ThemeOptions } from '@mui/material/styles';

const overrides: ThemeOptions['components'] = {
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {},
    },
  },
};

const muiTextFieldOverrides = overrides.MuiTextField;
export default muiTextFieldOverrides;
