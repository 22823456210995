import React from 'react';

import {
  type DataTrackingEventData,
  DataTrackingEvents,
} from '../api/models/dataTracking';
import { useDataTracking } from '../hooks/useDataTracking';

type TrackPageViewFun = (data: DataTrackingEventData) => void;

interface Props {
  trackPageView: TrackPageViewFun;
}

export const withTrackPageView = <PropsT extends Props = Props>(
  Component: React.ComponentType<PropsT>,
) => {
  type PropsWithoutHOCProps = Omit<PropsT, keyof Props>;
  const ComponentWithHOCProps: React.FC<PropsWithoutHOCProps> = (baseProps) => {
    const dataTracking = useDataTracking();
    const props = {
      ...baseProps,
      trackPageView: (data: DataTrackingEventData = {}) => {
        dataTracking.track(DataTrackingEvents.PageViewed, data);
      },
    };
    // See why coercion is needed: https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return <Component {...(props as PropsT)} />;
  };

  return ComponentWithHOCProps;
};

export type { Props as WithTrackPageViewProps };
