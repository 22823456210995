import type React from 'react';
import { useEffect } from 'react';

import { DEFAULT_ROUTE } from '../../../constants/webRoutes';
import { useNavigationWithLegacyFallback } from '../../../hooks/useNavigationWithLegacyFallback/web';

/** Special route to redirect to the default route */
const RedirectToDefaultRoute: React.FC = () => {
  const navigationWithLegacyFallback = useNavigationWithLegacyFallback();
  useEffect(() => {
    navigationWithLegacyFallback.navigate(DEFAULT_ROUTE);
  }, []);
  return null;
};

export default RedirectToDefaultRoute;
