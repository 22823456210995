// @ts-check

import test from './test';

/** @typedef {import("../../types").Config} Config */

/** @type {Config} */
const Config = {
  ...test,
  ConfigName: 'se-docker',
  Urls: {
    base: {
      domain: 'https://api.se-prev.doclydev.io',
    },
    web: {
      domain: 'https://se-prev.doclydev.io',
    },
    kiosk: {
      domain: 'https://kiosk.se.doclytest.io',
    },
    guides: {
      domain: 'https://guides.se-prev.doclydev.io',
    },
    inquisitor: {
      domain: 'https://develop-inquisitor.now.sh',
    },
  },
};

export default Config;
