import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import supportSource from '../../../../images/icon/icon_support@4x.png';
import logoSource from '../../../../images/logo/heart_alone@2x.png';
import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import { WEB_URL } from '../../../state/api';
import { getSiteOptions } from '../../utils/site';
import MediaQuery from '../media_query';
import Link from './link';
import SimpleFooter from './simple';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  root: {
    backgroundColor: colors.grey_100,
  },

  inner: {
    maxWidth: 960,
    margin: '0 auto',
    padding: 20,
  },

  wide: {
    display: 'flex',
    flexDirection: 'row',
  },

  logoWrapper: {
    flex: 1,
    paddingBottom: 20,
  },

  logo: {
    display: 'block',
    width: 35,
    height: 27,
  },

  links: {
    minWidth: 200,
    paddingBottom: 20,
  },

  title: {
    fontWeight: 'bold',
    paddingBottom: 7,
  },

  company: {
    minWidth: 180,
  },

  service: {
    minWidth: 140,
  },

  support: {
    display: 'flex',
    flexDirection: 'row',
  },

  supportImageWrapper: {
    marginRight: 20,
  },

  supportImage: {
    display: 'block',
    height: 74,
    width: 74,
  },

  disclaimer: {
    opacity: 0.5,
  },
};

const Logo = () => (
  <div style={styles.logoWrapper}>
    <Img src={logoSource} style={styles.logo} />
  </div>
);

const CompanyLinks = () => (
  <div style={{ ...styles.links, ...styles.company }}>
    <Body3 style={styles.title}>{_t('footer.company.title')}</Body3>

    <div>
      <Link href={`${WEB_URL}/about`}>{_t('footer.company.about')}</Link>
    </div>

    <div>
      <Link href={`${WEB_URL}/jobs`}>{_t('footer.company.jobs')}</Link>
    </div>

    <div>
      <Link href={`${WEB_URL}/press`}>{_t('footer.company.press')}</Link>
    </div>
  </div>
);

const ServiceLinks = () => (
  <div style={{ ...styles.links, ...styles.service }}>
    <Body3 style={styles.title}>{_t('footer.service.title')}</Body3>

    <div>
      <Link href={`${WEB_URL}/cookies`}>{_t('footer.service.cookies')}</Link>
    </div>

    <div>
      <Link href={`${WEB_URL}/policy`}>{_t('footer.service.policy')}</Link>
    </div>

    <div>
      <Link href={`${WEB_URL}/anvandarregler`}>
        {_t('footer.service.acceptableUsePolicy')}
      </Link>
    </div>
  </div>
);

const SupportLinks = () => (
  <div style={{ ...styles.links, ...styles.support }}>
    <MediaQuery minWidth={801}>
      <div style={styles.supportImageWrapper}>
        <Img src={supportSource} style={styles.supportImage} />
      </div>
    </MediaQuery>

    <div>
      <Body3 style={styles.title}>{_t('footer.support.title')}</Body3>

      <div>
        <Link href={`${WEB_URL}/faq`}>{_t('footer.support.faq')}</Link>
      </div>

      <div>
        <Link href={`${WEB_URL}/kontakta-oss`}>
          {_t('footer.support.contact')}
        </Link>
      </div>

      <div>
        <Link href={`${WEB_URL}/synpunkter-och-klagomal`}>
          {_t('footer.support.complaints')}
        </Link>
      </div>
    </div>
  </div>
);

export default class FullFooter extends Component {
  render() {
    const { style } = this.props;

    if (config.ShowSimpleFooter || getSiteOptions().showSimpleFooter) {
      return <SimpleFooter style={style} />;
    }

    const sections = [
      <Logo key="logo" />,

      <CompanyLinks key="company" />,

      <ServiceLinks key="service" />,

      <SupportLinks key="support" />,
    ];

    return (
      <div style={{ ...styles.root, ...style }}>
        <div style={styles.inner}>
          <MediaQuery maxWidth={800}>{sections}</MediaQuery>

          <MediaQuery minWidth={801}>
            <div style={styles.wide}>{sections}</div>
          </MediaQuery>

          <Body3 style={styles.disclaimer}>
            {_t('info.wetterhalsanFooter')} © {new Date().getFullYear()} MD
            International AB
          </Body3>
        </div>
      </div>
    );
  }
}
