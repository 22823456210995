import {
  useMutation,
  type UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { MARKETING_CONSENT_QUERY_KEY } from '../../marketing-consents/hooks/useMarketingConsentApi';
import { authApi } from '../api/auth';
import {
  type EmailLoginForm,
  type EmailLoginRequest,
  type EmailLoginResponse,
} from '../api/models/email';

import { authenticationQueryKey } from './useIsAuthenticatedApi';

type EmailLoginMutation = UseMutationResult<
  EmailLoginResponse | undefined,
  ResponseError,
  EmailLoginForm
>;

export const useEmailLoginMutator = (): EmailLoginMutation => {
  const queryClient = useQueryClient();

  return useMutation(
    async (request: EmailLoginRequest) => {
      const response = await authApi.emailLogin(request);
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([authenticationQueryKey]);
        await queryClient.invalidateQueries([MARKETING_CONSENT_QUERY_KEY]);
      },
    },
  );
};
