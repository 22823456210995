import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import Typography from '@mui/material/Typography';

import SystemCardLayout from '../../layout/SystemCardLayout/SystemCardLayout';

import FreepassIcon from './FreepassIcon/FreepassIcon';
import { type FreepassCardProps } from './types';

const FreepassCard: React.FC<FreepassCardProps> = ({ message }) => {
  const t = useTranslation('messaging', 'freepass');

  return (
    <SystemCardLayout icon={FreepassIcon} title={t('title')} message={message}>
      <Typography variant="body1" whiteSpace="pre-wrap">
        {t('body')}
      </Typography>
    </SystemCardLayout>
  );
};

export default FreepassCard;
