import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { TIME } from '@mindoktor/patient-app/utils/time/constants';

import { type Privacy } from '../api/models/policy';
import { policyApi } from '../api/policyApi';

const queryKey = 'privacyDocument';

/**
 * usePrivacyDocument hook fetches the latest privacy document.
 */
export const usePrivacyDocumentApi = (enabled: boolean) => {
  const notifications = useNotifications();
  const t = useTranslation('error');

  return useQuery<Privacy | undefined, ResponseError>(
    [queryKey],
    async () => {
      const response = await policyApi.getPrivacyDocument();
      if (!response?.ok) {
        throw new ResponseError(response);
      }

      return response?.data;
    },
    {
      retry: false,
      enabled,
      staleTime: 1 * TIME.HOUR,
      onError: (_error) => {
        notifications.showWarning(t('unknown'));
      },
    },
  );
};
