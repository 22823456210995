import { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useLegacyAuth } from '@mindoktor/patient-app/adapters/hooks/useLegacyAuth';
import { useAuthentication } from '@mindoktor/patient-app/auth/hooks/useAuthentication';

import { UserStatusStatuses } from '../api/models/userStatus';
import {
  type AuthorizationStatus,
  AuthorizationStatuses,
} from '../authorizations';

import { userStatusQueryKey, useUserStatusApi } from './useUserStatusApi';

/**
 * Useful for handling user routing and redirection in the login and registration process.
 *
 * **Note:** only try to fetch data when the user is authorized, using the `authorized` param.
 */
export const useAuthorization = () => {
  const legacyAuth = useLegacyAuth();
  const prevLegacyIsLoggedIn = useRef(false);
  const authentication = useAuthentication();
  const queryClient = useQueryClient();
  const canCheckUserStatus = authentication.isAuthenticated;
  const userStatus = useUserStatusApi(canCheckUserStatus);
  const isLoading =
    authentication.isLoading ||
    // It's never fetched when cannot check user status
    (canCheckUserStatus && !userStatus.isFetched);

  const getAuthorizationStatus = (): AuthorizationStatus => {
    // This function makes incremental checks to understand a user's
    // authorization status.
    // Whenever a check passes, it elevates the status and checks for the next;
    // whenever a check fails, it returns the last achieved status before the check.
    let status: AuthorizationStatus = AuthorizationStatuses.NONE;

    if (isLoading) {
      return status;
    }

    if (!(authentication.isAuthenticated && userStatus.isFetched)) {
      return status;
    }

    // We cannot rely on user status if it failed
    if (userStatus.isError) {
      console.warn('UserStatus is in error state: keeping unauthorized.');
      // Logout to try to possibly solve any mismatching situation
      void authentication.logout();

      return status;
    }

    status = AuthorizationStatuses.AUTHENTICATED;

    if (!(userStatus.data.tosAccepted && userStatus.data.privacyAccepted)) {
      return status;
    }
    status = AuthorizationStatuses.ACCEPTED_TOS_PRIVACY;

    if (
      !(
        userStatus.data.contactDetailsComplete &&
        userStatus.data.status === UserStatusStatuses.UserStatusLoginAllowed
      )
    ) {
      return status;
    }
    status = AuthorizationStatuses.COMPLETE;

    return status;
  };

  const status = getAuthorizationStatus();
  const isAuthorized = status === AuthorizationStatuses.COMPLETE;

  useEffect(() => {
    if (
      authentication.isAuthenticated &&
      userStatus.data.status === UserStatusStatuses.UserStatusLoginAllowed
    ) {
      // inform legacy that we are authorized, i.e. login in legacy terms
      legacyAuth.dispatchLogin();
    }
  }, [userStatus.data.status, authentication.isAuthenticated]);

  useEffect(() => {
    void queryClient.invalidateQueries([userStatusQueryKey]);
  }, [authentication.isAuthenticated]);

  useEffect(() => {
    // We are logged in but legacy thinks we are not anymore
    // We are in a bad state, so logout to clean up
    if (
      authentication.isAuthenticated &&
      prevLegacyIsLoggedIn.current &&
      !legacyAuth.isLoggedIn
    ) {
      void authentication.logout();
    }

    prevLegacyIsLoggedIn.current = legacyAuth.isLoggedIn;
  }, [legacyAuth.isLoggedIn]);

  return {
    /** Is fully authorized: authenticated, TOS/privacy accepted and registration is complete. */
    isAuthorized,
    status,
    isLoading,
  };
};
