import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { type CasesResponse } from './models/cases';
import { CasesSchema } from './schema/casesSchema';

const BASE_URL = 'api/v1/user/cases';

interface Cases {
  getCases: (locale?: string | null) => Promise<ApiResponse<CasesResponse>>;
}

const getCases = async (
  locale?: string | null,
): Promise<ApiResponse<CasesResponse>> => {
  const queryParam = locale != null ? `?locale=${locale}` : '';
  return await requestHandler.get(`${BASE_URL}${queryParam}`, {
    validationSchema: CasesSchema,
  });
};

export const casesApi: Cases = {
  getCases,
};
