import { type ThemeOptions } from '@mui/material/styles';

import { colors } from '../../../colors';
import muiTypographyOverrides from '../../Typography/web/typographyOverrides';

const overrides: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      color: 'hero',
      disableElevation: true,
      variant: 'contained',
      disableRipple: true,
      size: 'medium',
    },
    styleOverrides: {
      root: ({ ownerState: { color }, theme: { palette } }) => ({
        borderRadius: '999px',
        fontWeight: 500,
        textTransform: 'none',

        '&:disabled': {
          backgroundColor: colors.gray[300],
        },

        '&.MuiButton-sizeSmall': {
          minWidth: '5.35rem',
          minHeight: '2rem',
          paddingX: '1rem',
          fontSize: 13,
        },

        '&.MuiButton-sizeMedium': {
          minWidth: '13.75rem',
          minHeight: '2.5rem',
          paddingX: '1.5rem',
          fontSize: 18,
        },

        '&.MuiButton-sizeLarge': {
          minWidth: '14.75rem',
          minHeight: '3.15rem',
          paddingX: '1.5rem',
          fontSize: 20,
        },

        '&.MuiButton-containedHero': {
          color: colors.white,
          backgroundColor: palette.hero.main,
          '&:hover': {
            backgroundColor: palette.hero.light,
          },
          '&:active': {
            backgroundColor: palette.hero.dark,
          },
          '&:disabled': {
            color: palette.hero.contrastText,
            backgroundColor: palette.hero.disabled,
          },
        },

        '&.MuiButton-containedPrimary': {
          backgroundColor: palette.primary.light,
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
          '&:active': {
            backgroundColor: palette.primary.dark,
          },
          '&:disabled': {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.disabled,
          },
        },

        '&.MuiButton-containedSecondary': {
          border: '1px solid',
          borderColor: colors.gray[500],
          backgroundColor: palette.secondary.main,
          '&:hover': {
            borderColor: colors.black,
            backgroundColor: palette.secondary.light,
          },
          '&:active': {
            borderColor: colors.black,
            backgroundColor: palette.secondary?.dark,
          },
          '&:disabled': {
            color: colors.gray[200],
            borderColor: colors.gray[200],
            backgroundColor: palette.secondary.disabled,
          },
        },

        '&.MuiButton-containedTransparent': {
          color: colors.red[100],
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: colors.gray[100],
          },
          '&:active': {
            backgroundColor: colors.gray[200],
          },
          '&:disabled': {
            color: palette.hero.light,
            backgroundColor: palette.secondary.disabled,
          },
        },

        '&.MuiButton-textSizeMedium': {
          minWidth: 'auto',
          fontSize: muiTypographyOverrides?.styleOverrides?.body1.fontSize,
          '& .MuiButton-endIcon *': {
            fontSize: '0.7em',
            paddingTop: '0.3em',
          },
        },

        // Other custom colors not specifically defined (e.g. success)
        ...(color !== undefined &&
          color !== 'inherit' &&
          color !== 'hero' &&
          color !== 'primary' &&
          color !== 'secondary' &&
          color !== 'transparent' && {
            backgroundColor: palette[color].main,
            color: palette[color].contrastText,
            '&:hover': {
              backgroundColor: palette[color]?.light,
            },
            '&:active': {
              backgroundColor: palette[color]?.dark,
            },
            '&:disabled': {
              color: palette[color].contrastText,
            },
          }),
      }),
    },
  },
};

const buttonOverrides = overrides?.MuiButton;
export default buttonOverrides;
