import { useContext } from 'react';

import { LocaleContext } from '../context/LocaleContext';

export const useLocale = () => {
  const locale = useContext(LocaleContext);
  if (locale == null) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return locale;
};
