import {
  type ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';
import {
  getStringifiedObject,
  type Stringified,
} from '@mindoktor/patient-app/utils/types/strings';

import {
  type DataTrackingEventRequest,
  type DataTrackingEventResponse,
} from './models/dataTracking';

type StringifiedDataTrackingEventRequest =
  Stringified<DataTrackingEventRequest>;

const postDataTrackingEvent = async (
  trackEventData: DataTrackingEventRequest,
): Promise<ApiResponse<DataTrackingEventResponse>> => {
  return await requestHandler.post<
    DataTrackingEventResponse,
    unknown,
    StringifiedDataTrackingEventRequest
  >(
    '/api/v1/track-event',
    getStringifiedObject(trackEventData, { skipUndefined: true }),
  );
};

export const trackingApi = {
  postDataTrackingEvent,
};
