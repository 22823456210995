import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Button, Stack, Typography } from '@mui/material';

import BottomSheetActions from '@mindoktor/pulse/src/components/BottomSheetActions/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { openMediaPicker } from '../../../functions/mediaPicker/mediaPicker';
import { MediaType } from '../../../functions/mediaPicker/types';
import CameraOutlinedIcon from '../CameraOutlinedIcon/CameraOutlinedIcon';

import { type MediaChooserSheetContentProps } from './types';

const MediaChooserSheetContent: React.FC<MediaChooserSheetContentProps> = ({
  onClose,
  onChangeMediaAsset,
  onError,
}) => {
  const t = useTranslation('messaging');

  const handlePickPhoto = async () => {
    try {
      const files = await openMediaPicker({
        library: true,
        cameraOptions: {
          mediaType: MediaType.Photo,
          limit: 1,
        },
      });

      if (files == null) {
        // User cancelled the picker
        return;
      }

      onChangeMediaAsset(files[0]);
    } catch (e) {
      onError(new Error('Error opening image picker', { cause: e }));
    }
  };

  const handleCapturePhoto = () => {
    console.log('handleCapturePhoto');
  };

  return (
    <>
      <BottomSheetHeader
        onClose={onClose}
        icon={
          <Stack alignSelf="center">
            <CameraOutlinedIcon color={colors.white} />
          </Stack>
        }
      >
        <Typography variant="h3" alignSelf="center">
          {t('mediaPicker.bottomSheet.title')}
        </Typography>
      </BottomSheetHeader>
      <BottomSheetActions>
        <Button color="primary" onClick={handleCapturePhoto}>
          {t('mediaPicker.bottomSheet.capturePhoto')}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            void handlePickPhoto();
          }}
        >
          {t('mediaPicker.bottomSheet.pickPhoto')}
        </Button>
      </BottomSheetActions>
    </>
  );
};

export default MediaChooserSheetContent;
