import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { messagingApi } from '../api/messaging';
import { type Conversation } from '../api/models/conversation';

export type ConversationResult = UseQueryResult<
  Conversation | null,
  ResponseError
>;

export const CONVERSATION_QUERY_KEY = 'Conversation';

export const useConversationApi = (
  conversationId: number | null,
): ConversationResult => {
  return useQuery<Conversation | null, ResponseError>(
    [conversationId, CONVERSATION_QUERY_KEY],
    async () => {
      if (conversationId == null) {
        return null;
      }

      const response = await messagingApi.getConversation(conversationId);
      if (!response.ok) {
        throw new ResponseError(response);
      }

      if (response.data?.data == null) {
        return null;
      }

      return response.data.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
