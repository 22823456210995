import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import { Body2, Header2, pulseFonts } from '../../common/components/typography';
import Markdown from '../components/markdown';
import Button from '../components/button/button';
import { _t } from '@mindoktor/patient-legacy/i18n';
import spacing from '../../common/spacing';

import logoHeartAlone from '../../../images/logo/heart_alone.svg';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const padding = 2.5; // rem
const useStyles = makeStyles((theme) => ({
  root: {
    padding: padding + 'rem',
    paddingBottom: '0.5rem',
    width: `calc(100% - ${padding * 2}rem)`,
    maxWidth: 760,
    margin: '0 auto',
  },
  header: { ...(pulseFonts.header1 as CSSProperties), marginTop: spacing.s },
  body: { marginTop: spacing.s, fontWeight: 400 },
  buttonContainer: {
    width: '100%',
    overflowY: 'auto',
    marginTop: spacing.l,
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      minWidth: 340,
    },
  },
}));

interface GetTranslationsProps {
  successful: boolean;
  info: string;
  firstName: string;
  verified: boolean;
}
const getTranslations = ({
  successful,
  firstName,
  info,
  verified,
}: GetTranslationsProps) => {
  if (verified) {
    return {
      title: _t('contactinfo.verification.bylink.email.alreadyverified.title'),
      goToHome: _t('contactinfo.verification.bylink.go.home'),
    };
  }
  if (successful) {
    return {
      title: _t('contactinfo.verification.email.success.title'),
      text: _t('contactinfo.verification.email.success.text', {
        firstName,
        email: info,
        // We use count to tell gettext how to handle a missing name
        count: firstName ? 1 : 0,
      }),
      goToHome: _t('contactinfo.verification.bylink.go.home'),
    };
  }
  return {
    title: _t('contactinfo.verification.bylink.email.fail.title'),
    text: _t('contactinfo.verification.bylink.email.fail.text'),
    goToHome: _t('contactinfo.verification.bylink.go.home'),
  };
};

type Props = GetTranslationsProps;

/*
 * This is the screen users end up when they click the link we sent them (as email or sms)
 */
const VerifyByLinkResult: React.FC<Props> = ({
  successful,
  info,
  firstName,
  verified,
}) => {
  const classes = useStyles();

  const translations = getTranslations({
    successful,
    firstName,
    info,
    verified,
  });

  const handleGoHomeButton = async () => {
    window.location.href = '/';
  };

  return (
    <div className={classes.root}>
      <Img src={logoHeartAlone} />
      <Header2 className={classes.header}>{translations.title}</Header2>
      <Body2 className={classes.body}>
        <Markdown source={translations.text} />
      </Body2>
      <div className={classes.buttonContainer}>
        <Button className={classes.button} onClick={handleGoHomeButton}>
          {translations.goToHome}
        </Button>
      </div>
    </div>
  );
};

export default VerifyByLinkResult;
