import React from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import IconButton from '@mui/material/IconButton';
import Stack, { type StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BackChevronIcon from '@mindoktor/pulse/src/icons/BackChevronIcon/BackChevronIcon';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useNavigation } from '@mindoktor/patient-app/routing/web';

interface Props {
  marginBottom?: StackProps['marginBottom'];
  sx?: StackProps['sx'];
}

const NavigationHeaderDesktop: React.FC<Props> = ({ marginBottom, sx }) => {
  const navigation = useNavigation();
  const t = useTranslation('vaccinationBook', 'downloadPage');

  return (
    <Stack
      direction="row"
      borderBottom={`1px solid ${colors.gray[300]}`}
      alignItems="center"
      marginBottom={marginBottom}
      sx={sx}
    >
      <Stack direction="row" justifyContent="flex-start" width="5rem">
        <IconButton
          edge="start"
          color="transparent"
          size="large"
          onClick={() => {
            navigation.goBack();
          }}
        >
          <BackChevronIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" justifyContent="center" flexGrow={1}>
        <Typography variant="body2">{t('title')}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" width="5rem"></Stack>
    </Stack>
  );
};

export default NavigationHeaderDesktop;
