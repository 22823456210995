import React, { type PropsWithChildren, useState } from 'react';

import NotificationList from '../components/NotificationList';
import { type Notification, type NotificationWithId } from '../types';

import { NotificationContext } from './NotificationContext';

export const NotificationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<NotificationWithId[]>([]);

  const push = (notification: Notification) => {
    const id = Date.now();
    setNotifications((notifications) => [
      ...notifications,
      { id, ...notification },
    ]);
  };

  const pushError = (text: string) => {
    push({ text, type: 'error' });
  };

  const pushWarning = (text: string) => {
    push({ text, type: 'warning' });
  };

  const pushInfo = (text: string) => {
    push({ text, type: 'info' });
  };

  const pushSuccess = (text: string) => {
    push({ text, type: 'success' });
  };

  const remove = (id: number) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id),
    );
  };

  return (
    <NotificationContext.Provider
      value={{
        pushError,
        pushWarning,
        pushInfo,
        pushSuccess,
      }}
    >
      <NotificationList
        notifications={notifications}
        onCloseNotification={remove}
      />
      {children}
    </NotificationContext.Provider>
  );
};
