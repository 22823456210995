import React from 'react';

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Icon } from '../icon/Doctor.svg';

const DoctorIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    inheritViewBox
    component={Icon}
    sx={[
      { width: 95, height: 99 },
      // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default DoctorIcon;
