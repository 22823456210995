import { useCallback } from 'react';

import { useLocale } from './useLocale';

export const useFormatNumber = () => {
  const locale = useLocale();
  const formatNumber = useCallback(
    (value: number): string => {
      switch (locale.currentLanguage) {
        case 'en':
          return `${value}`;
        default:
          return `${value}`.replace('.', ',');
      }
    },
    [locale.currentLanguage],
  );

  return formatNumber;
};
