import { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { type FormKeyboardItem } from '@mindoktor/patient-app/utils/forms/hooks/types';
import { useFormKeyboardActions } from '@mindoktor/patient-app/utils/forms/hooks/useFormKeyboardActions';

import { type RegistrationFormType } from '../api/models/registration';
import {
  UserFinalizeRegistrationProtectedSchema,
  UserFinalizeRegistrationSchema,
} from '../api/schema/registration';

import { useUserDetailsApi } from './useUserDetailsApi';

export interface RegistrationErrors {
  email?: string[];
  telephone?: string[];
  address?: string[];
  postalCode?: string[];
  city?: string[];
}
export type RegistrationErrorKey = keyof RegistrationErrors;

const defaultValues = {
  address: '',
  city: '',
  postalCode: '',
  telephone: '',
  email: '',
};

export const useRegistrationForm = (onSubmit: () => Promise<void>) => {
  const [values, setValues] = useState<RegistrationFormType>(defaultValues);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState<RegistrationErrors>({});
  const { data: userDetails } = useUserDetailsApi();
  const t = useTranslation('user', 'registration');

  // Refs to handle input focusing from the keyboard.
  const postalCodeRef = useRef(null);
  const cityRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  const formKeyMapping: FormKeyboardItem[] = [
    { name: 'address', action: 'next' },
    { name: 'postalCode', action: 'next', ref: postalCodeRef },
    { name: 'city', action: 'next', ref: cityRef },
    { name: 'email', action: 'next', ref: emailRef },
    { name: 'telephone', action: 'done', ref: phoneRef },
  ];

  const { onEnterKey } = useFormKeyboardActions(formKeyMapping, onSubmit);

  useEffect(() => {
    validate(values);
  }, []);

  useEffect(() => {
    // initialize and validate values once we have access to them.
    if (userDetails != null) {
      const initValues = {
        address: userDetails.address,
        city: userDetails.city,
        postalCode: userDetails.postalCode,
        telephone: userDetails.telephone,
        email: userDetails.email,
      };
      setValues(initValues);
      validate(initValues);
    }
  }, [userDetails]);

  const onChange = (key: RegistrationErrorKey, value: string) => {
    const newValues = {
      ...values,
      [key]: value,
    };
    setValues(newValues);
    validate(newValues);
  };

  const validate = (input: RegistrationFormType) => {
    let result;
    if (userDetails?.identityProtection === true) {
      result = UserFinalizeRegistrationProtectedSchema.safeParse(input);
    } else {
      result = UserFinalizeRegistrationSchema.safeParse(input);
    }

    if (result.success) {
      setErrors({});
      setIsValid(true);
      return;
    }

    setErrors(result.error.flatten().fieldErrors);
    setIsValid(false);
  };

  const isFieldInvalid = (key: RegistrationErrorKey) =>
    errors[key]?.length != null;

  const fieldErrorMessage = (key: RegistrationErrorKey) => {
    const errString = errors[key] ?? [];
    return isFieldInvalid(key) ? t(errString[0]) : '';
  };

  return {
    values,
    errors,
    isValid,
    onChange,
    isFieldInvalid,
    fieldErrorMessage,
    onEnterKey,
    inputRefs: { postalCodeRef, cityRef, emailRef, phoneRef },
  };
};
