import React, { useState } from 'react';
import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';

import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import TextLink from '@mindoktor/pulse/src/components/TextLink/web';

import { useAuthConfigApi } from '../../../hooks/useAuthConfigApi';
import { type LoginMethod, LoginMethods } from '../../../types';
import BankIDLogin from '../../BankIDLogin/web/BankIDLogin';
import EmailLogin from '../../EmailLogin/web';

const LoginCardContentMobile = () => {
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(
    LoginMethods.bankid,
  );
  const [bankIDLoginStarted, setBankIDLoginStarted] = useState(false);

  const t = useTranslation('auth');
  const authConfig = useAuthConfigApi();
  const isEmailAuthEnabled = authConfig.data?.isEmailEnabled ?? false;

  const toggleLoginMethod = () => {
    setLoginMethod(
      loginMethod === LoginMethods.bankid
        ? LoginMethods.email
        : LoginMethods.bankid,
    );
  };

  return (
    <Stack position="relative" spacing="1rem" minWidth="100%" minHeight="100%">
      <Typography textAlign={'center'} variant="h2">
        {t('common.login')}
      </Typography>
      <Stack flexGrow={1} width="100%">
        {loginMethod === LoginMethods.bankid && (
          <BankIDLogin onLoginProcessChange={setBankIDLoginStarted} />
        )}
        {loginMethod === LoginMethods.email && <EmailLogin />}

        {!bankIDLoginStarted && isEmailAuthEnabled && (
          <Stack
            flexGrow={1}
            alignItems="center"
            justifyContent="flex-end"
            marginTop="1rem"
          >
            <TextLink
              noUnderline
              onClick={toggleLoginMethod}
              sx={{ marginBottom: '0.5rem' }}
            >
              {loginMethod === LoginMethods.bankid
                ? t('email.login')
                : t('common.cancel')}
            </TextLink>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default LoginCardContentMobile;
