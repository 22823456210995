import { colors } from './colors';
import { rem } from './conversions';

export interface ElevationShadowProps {
  shadowElevation?: number | 'default';
  shadowColor?: string;
  shadowOffsetX?: number;
}

/**
 * See more on Android elevation: https://reactnative.dev/docs/view-style-props#elevation-android
 * See more on ReactNative shadow props: https://reactnative.dev/docs/shadow-props
 */
interface PulseShadow {
  color: string;
  /** Web and iOS */
  offsetX: number;
  /** Web and iOS */
  offsetY: number;
  /** Only Android */
  elevation: number;
  /** Only iOS */
  radius: number;
  /** Only Web */
  blur: number;
  /** Only Web */
  spread: number;
}

const defaultElevationShadowProps = {
  shadowElevation: 6,
  shadowColor: colors.gray.S900,
  shadowOffsetX: 0,
};

interface GetElevationShadowFunction {
  (props: ElevationShadowProps): PulseShadow;
  (props: undefined): undefined;
}

/**
 * Provides a shadow object to get consistent shadowing across platforms based on elevation.
 *
 * It's inspired from how ReactNative uses elevation to bring shadows on Android.
 * See more on Android elevation: https://reactnative.dev/docs/view-style-props#elevation-android
 * See more on ReactNative shadow props: https://reactnative.dev/docs/shadow-props
 */
export const getElevationShadow = ((
  props: ElevationShadowProps | undefined,
): PulseShadow | undefined => {
  if (props?.shadowElevation == null) {
    return undefined;
  }

  const elevation =
    props.shadowElevation === 'default'
      ? defaultElevationShadowProps.shadowElevation
      : props.shadowElevation;
  const color = props.shadowColor ?? defaultElevationShadowProps.shadowColor;
  const offsetX =
    props.shadowOffsetX ?? defaultElevationShadowProps.shadowOffsetX;

  const offsetY = Math.round((4 / 6) * elevation);
  const blur = 4 + Math.round((elevation - 6) / 3);
  const spread = 0;

  // TODO: exact value to be tuned
  const radius = spread;

  return {
    color,
    offsetX: rem(offsetX, null),
    offsetY,
    elevation,
    radius,
    blur,
    spread,
  };
}) as GetElevationShadowFunction;

interface GetShadowStringFunction {
  (shadow: PulseShadow | 'default', isFilter?: boolean): string;
  (shadow: undefined, isFilter?: boolean): 'none';
}

/**
 * Provides a shadow string for box-shadow or filter drop-shadow.
 */
export const getShadowString = ((
  shadow: PulseShadow | 'default' | undefined,
  isFilter: boolean = false,
): string => {
  if (shadow == null) {
    return 'none';
  }

  const { offsetX, offsetY, blur, spread, color } =
    shadow === 'default'
      ? getElevationShadow({ shadowElevation: 'default' })
      : shadow;

  if (isFilter) {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/filter-function/drop-shadow
    return `drop-shadow(${offsetX}px ${offsetY}px ${blur}px ${color})`;
  }

  // https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow
  return `${offsetX}px ${offsetY}px ${blur}px ${spread}px ${color}`;
}) as GetShadowStringFunction;

interface NativeShadowProps {
  elevation: number;
  shadowColor: string;
  shadowOffset: {
    width: number;
    height: number;
  };
  shadowRadius: number;
}

interface GetNativeShadowPropsFunction {
  (shadow: PulseShadow | 'default'): NativeShadowProps;
  (shadow: undefined): undefined;
}

/**
 * Provides a shadow for Native platform props
 */
export const getNativeShadowProps = ((
  shadow: PulseShadow | 'default',
): NativeShadowProps | undefined => {
  if (shadow == null) {
    return undefined;
  }

  const { elevation, color, offsetX, offsetY, blur } =
    shadow === 'default'
      ? getElevationShadow({ shadowElevation: 'default' })
      : shadow;

  return {
    elevation,
    shadowColor: color,
    shadowOffset: {
      width: offsetX,
      height: offsetY,
    },
    shadowRadius: blur,
  };
}) as GetNativeShadowPropsFunction;
