import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { rem } from '../../styles/conversions';
import { getMargin } from '../../styles/margin';
import { getElevationShadow, getShadowString } from '../../styles/shadows';

import { iconRotations } from './constants';
import {
  type CrateIconFunction,
  type PulseIconProps,
  type WebSvgIcon,
} from './types';

export const createIcon: CrateIconFunction = (Icon) => {
  const WebIcon = Icon as WebSvgIcon;
  const PulseIcon: React.FC<PulseIconProps> = ({
    color,
    fontSize,
    direction,
    onClick,
    ...otherProps
  }) => {
    const marginProps = getMargin(otherProps, 'px');
    const shadowProps = getElevationShadow(otherProps);
    const StyledIcon = () => (
      <SvgIcon
        inheritViewBox
        component={WebIcon}
        role={onClick != null ? 'button' : undefined}
        tabIndex={onClick != null ? 0 : undefined}
        onClick={onClick}
        sx={{
          ...(onClick != null && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
          ...(direction != null && {
            transform: `rotate(${iconRotations[direction]}deg)`,
          }),
          ...(color != null && {
            '&.MuiSvgIcon-root path': {
              fill: color,
            },
          }),
          ...(fontSize != null && {
            fontSize: rem(fontSize, 'px'),
          }),
          '&.MuiSvgIcon-root': {
            width: 'fit-content',
            height: fontSize != null ? rem(fontSize, 'px') : 'fit-content',
          },
          ...marginProps,
          ...(shadowProps != null && {
            filter: getShadowString(shadowProps, true),
          }),
        }}
      />
    );
    return <StyledIcon />;
  };

  return PulseIcon;
};
