import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  AuthorizationStatuses,
  useAuthorization,
} from '@mindoktor/patient-app/user';
import { assertUnreachable } from '@mindoktor/patient-app/utils/typechecking/assertUnreachable';

import routes from '../../../constants/routes';
import { type Route } from '../../../constants/webRoutes';
import { useNavigationWithLegacyFallback } from '../../../hooks/useNavigationWithLegacyFallback/web';
import { useRedirectionNavigation } from '../../../hooks/useRedirectionNavigation';
import {
  getFullHashRoute,
  getRedirectRoute,
} from '../../../utils/getRedirectRoute/web';

const specialLoginRegistrationRoutes: string[] = [
  routes.LOGIN,
  routes.TOS_PRIVACY,
  routes.REGISTER,
];

interface NavigateWithRedirectProps {
  to: Route;

  /**
   * If `true`, the current entry in the history will be replaced.
   * NOTE: Works only with new routes.
   */
  replace?: boolean;
}

/** Navigates using the legacy router as fallback. */
const NavigateWithLegacyFallback: React.FC<NavigateWithRedirectProps> = ({
  to,
  replace,
}) => {
  const navigationWithLegacyFallback = useNavigationWithLegacyFallback();
  useEffect(() => {
    navigationWithLegacyFallback.navigate(to, replace);
  }, []);
  return null;
};

/** Navigates to the current redirection target route. */
const NavigateToRedirectionRoute: React.FC = () => {
  const redirectionNavigation = useRedirectionNavigation();
  useEffect(() => {
    redirectionNavigation.redirect();
  }, []);
  return null;
};

/**
 * Navigates to a route adding the current location as redirect.
 *
 * **Note:** If a redirect is already set, it won't be replaced. Also,
 * no redirect will be added for root `/` or registration and login routes.
 */
const NavigateWithRedirect: React.FC<NavigateWithRedirectProps> = ({
  to,
  replace,
}) => {
  const location = useLocation();

  const redirectRoute = getRedirectRoute();
  // If a redirect is present, keep it.
  if (redirectRoute != null) {
    return (
      <NavigateWithLegacyFallback
        to={`${to}?state=${encodeURIComponent(redirectRoute)}` as Route}
        replace={replace}
      />
    );
  }

  // Do not add a redirect for root or special routes
  if ([...specialLoginRegistrationRoutes, '/'].includes(location.pathname)) {
    return <NavigateWithLegacyFallback to={to} replace={replace} />;
  }

  // No state is set, get the redirect from the hash
  const hashRoute = getFullHashRoute();

  return (
    <NavigateWithLegacyFallback
      to={`${to}?state=${encodeURIComponent(hashRoute)}` as Route}
      replace={replace}
    />
  );
};

/**
 * Protects routes from missing authentication or authorization.
 */
const ProtectedRoutesHandler: React.FC = () => {
  const authorization = useAuthorization();
  const location = useLocation();

  switch (authorization.status) {
    case AuthorizationStatuses.NONE:
      switch (location.pathname) {
        // Allowed routes
        case routes.LOGIN:
          return <Outlet />;
        default:
          return <NavigateWithRedirect to={routes.LOGIN} replace />;
      }

    case AuthorizationStatuses.AUTHENTICATED:
      switch (location.pathname) {
        // Allowed routes
        case routes.TOS_PRIVACY:
          return <Outlet />;
        default:
          return <NavigateWithRedirect to={routes.TOS_PRIVACY} />;
      }

    case AuthorizationStatuses.ACCEPTED_TOS_PRIVACY:
      switch (location.pathname) {
        // Allowed routes
        case routes.REGISTER:
        case routes.TOS_PRIVACY:
          return <Outlet />;
        default:
          return <NavigateWithRedirect to={routes.REGISTER} />;
      }

    case AuthorizationStatuses.COMPLETE:
      // If authorized, redirect out from login/registration routes
      if (specialLoginRegistrationRoutes.includes(location.pathname)) {
        return <NavigateToRedirectionRoute />;
      }

      return <Outlet />;

    default:
      return assertUnreachable(authorization.status);
  }
};

export default ProtectedRoutesHandler;
