import type React from 'react';
import { useEffect, useState } from 'react';

import type { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

import { isScrollAtBottom } from './scrollPositioning';

interface Options {
  bottomDetectionThreshold?: number;
  initialIsBottomState?: boolean;
}

interface OnScrollParams {
  scrollOffsetY: number;
  contentHeight: number;
  scrollElementHeight: number;
}

const defaultOptions = {
  bottomDetectionThreshold: 0,
  initialIsBottomState: false,
};

export const useScrollPositioning = ({
  bottomDetectionThreshold = defaultOptions.bottomDetectionThreshold,
  initialIsBottomState = defaultOptions.initialIsBottomState,
}: Options = defaultOptions) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [scrollElementHeight, setScrollElementHeight] = useState(
    // In order for the system to be self-consistent, if the initialIsBottomState is false
    // then the container has to be taller than then bottomDetectionThreshold
    bottomDetectionThreshold + (initialIsBottomState ? 1 : 0),
  );
  const [scrollOffsetY, setScrollOffsetY] = useState(0);
  const [isScrollBottom, setIsScrollBottom] = useState(initialIsBottomState);

  useEffect(() => {
    onScroll({ scrollOffsetY, contentHeight, scrollElementHeight });
  }, [scrollElementHeight, contentHeight]);

  const onScroll = ({
    scrollOffsetY,
    contentHeight,
    scrollElementHeight,
  }: OnScrollParams) => {
    setScrollOffsetY(scrollOffsetY);
    setIsScrollBottom(
      isScrollAtBottom(
        scrollOffsetY,
        contentHeight,
        scrollElementHeight,
        bottomDetectionThreshold,
      ),
    );
  };

  return {
    scrollOffsetY,
    isScrollBottom,
    scrollElementHeight,
    setScrollElementHeight,
    contentHeight,
    setContentHeight,
    onScroll,
  };
};

export const adaptWeb = ({
  currentTarget: { scrollTop, scrollHeight, clientHeight },
}: React.UIEvent<HTMLDivElement, UIEvent>): OnScrollParams => ({
  scrollOffsetY: scrollTop,
  contentHeight: scrollHeight,
  scrollElementHeight: clientHeight,
});

export const adaptNative = ({
  nativeEvent: { layoutMeasurement, contentOffset, contentSize },
}: NativeSyntheticEvent<NativeScrollEvent>): OnScrollParams => ({
  scrollOffsetY: contentOffset.y,
  contentHeight: contentSize.height,
  scrollElementHeight: layoutMeasurement.height,
});
