const pxToRem = 16;

type UnitConversionFunction = <
  ValueType extends number | undefined,
  UnitType extends string | null | undefined,
>(
  value: ValueType,
  unit?: UnitType,
) => ValueType extends undefined
  ? undefined
  : UnitType extends null | undefined
    ? number
    : string;

/**
 * Returns a pixel size based on a rem one.
 *
 * @example
 * // returns '40px'
 * rem(2.5)
 */
export const rem = ((
  value: number | undefined,
  unit: string | null | undefined = 'px',
) => {
  if (value == null) {
    return undefined;
  }

  return withUnit(value * pxToRem, unit);
}) as UnitConversionFunction;

/**
 * Convert a decimal number to a percentage string.
 */
export const percent = (value: number) => `${value * 100}%`;

/**
 * Adds an optional unit to the given value.
 *
 * @example withUnit(3, 'px') // '3px'
 * @example withUnit(3, null) // 3
 */
const withUnit = ((
  value: number | undefined,
  unit?: string | null | undefined,
) => {
  if (value == null) {
    return undefined;
  }

  if (unit == null) {
    return value;
  }

  return `${value}${unit}`;
}) as UnitConversionFunction;
