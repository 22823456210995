import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { colors } from '@mindoktor/pulse/src/styles/colors';

import BaseLayout from '@mindoktor/patient-app/layout/components/BaseLayout/web';
import { getVisibleHeight } from '@mindoktor/patient-app/layout/functions/getActualHeight/web';
import { useResizeEffect } from '@mindoktor/patient-app/layout/hooks/useResizeEffect/web/useResizeEffect';
import { ScrollableContentLayout } from '@mindoktor/patient-app/layout/web';

import { BottomSheetProvider } from '../../contexts/BottomSheetProvider';
import MessageComposerContainer from '../Composer/MessageComposer.container';
import ConversationHeader from '../ConversationHeader/ConversationHeader';
import ConversationList from '../ConversationView/ConversationView';

import { type MessagingScreenProps } from './types';

const SCREEN_BG_COLOR = colors.gray[100];

/**
 * If the height of the visible screen is below this threshold,
 * there might not be enough room for showing the message composer.
 * This may happen when a user is writing a message using the soft keyboard in
 * landscape mode. Acting on this enhances the likelihood of the
 * user being able to read while they type, even in this scenario.
 *
 * The current value has been determined experimentally.
 */
const TINY_HEIGHT_THRESHOLD = 130; // px

const MessagingScreen: React.FC<MessagingScreenProps> = ({
  conversationId,
}) => {
  const [visibleHeight, setVisibleHeight] = useState(getVisibleHeight());
  useResizeEffect(() => {
    setVisibleHeight(getVisibleHeight());
  });

  const isHeaderHidden = visibleHeight < TINY_HEIGHT_THRESHOLD;
  return (
    <BottomSheetProvider>
      <ScrollableContentLayout>
        <BaseLayout backgroundColor={SCREEN_BG_COLOR}>
          <Stack
            flexDirection="column"
            height="100%"
            width="100%"
            bgcolor={SCREEN_BG_COLOR}
          >
            {!isHeaderHidden && <ConversationHeader />}
            <ConversationList conversationId={conversationId} />
            <Box
              sx={{
                paddingX: '0.75rem',
                paddingTop: 0,
                paddingBottom: '1.5rem',
              }}
            >
              <MessageComposerContainer />
            </Box>
          </Stack>
        </BaseLayout>
      </ScrollableContentLayout>
    </BottomSheetProvider>
  );
};

export default MessagingScreen;
