import Config from '@mindoktor/env/Config';

export const BASE_URL = Config.Urls.base.domain;
export const PARAMS = {
  [BASE_URL]: Config.Urls.base.params ?? {},
};
export const WEB_URL = Config.Urls.web.domain;

// do we need to separate these in the near future?
export const API_URL = BASE_URL;

// used for images:
export const API_URL_FILES = API_URL + '/api/v1/files/';

// use website FAQ:
export const URL_SUPPORT = `${WEB_URL}/faq`;

// commonly used HTTP response statuses
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const INTERNAL_SERVER_ERROR = 500;

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Requested-With': 'XMLHttpRequest',
};

/**
 * http://stackoverflow.com/a/27755
 * [
 *   1: url
 *   2: protocol
 *   3: host
 *   4: path
 *   6: file
 *   7: query
 *   8: hash
 * ]
 */
const urlRegex =
  /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
const resourceRegex = /^http[s]?:\/\/[^\/]*(.*)/;

/**
 * @typedef {Object} constructUrlArgs
 * @property {string} url should start with forward slash
 * @property {Object} params
 * @property {string=} baseUrl
 * @property {Object=} configParams
 *
 * Constructs a url from a given path.
 *
 * Default base url is BASE_URL
 * Includes params set for the current base url (default is BASE_URL), as well as
 * passed in params, as query string params.
 *
 * @param {constructUrlArgs} param0 - URL should start with forward slash
 */
export const constructUrl = ({
  url,
  params,
  baseUrl = BASE_URL,
  configParams = PARAMS[url],
} = {}) => {
  if (!url) {
    return '';
  }

  const cleanUrl = url[0] === '/' ? url : `/${url}`;

  const combinedParams = {
    ...configParams,
    ...params,
  };

  const paramString = Object.keys(combinedParams)
    .map((param) => `${param}=${combinedParams[param]}`)
    .join('&');

  const separator = paramString.length
    ? cleanUrl.includes('?')
      ? '&'
      : '?'
    : '';

  return `${baseUrl}${cleanUrl}${separator}${paramString}`;
};

export const parseUrl = (url) => {
  const parts = urlRegex.exec(url);
  return parts;
};

export const stripDomainFromUrl = (url) => {
  if (url.startsWith('/')) return url;

  const parts = resourceRegex.exec(url);

  if (parts && parts.length > 1) {
    return parts[1];
  }

  return url;
};

// export const serializeCookies = (cookies: { [key: string]: string }) =>
//   Object.keys(cookies)
//     .map(key => key + '=' + cookies[key])
//     .join(';');
//
// export const extractCookies = (response: any) => {
//   const setCookie =
//     response &&
//     response.headers &&
//     response.headers.map &&
//     response.headers.map['set-cookie'];
//
//   const cookies = {};
//
//   // Ideally set-cookie would have each cookie separated in an array.
//   // Unfortunately they're appended on to the first item in the array,
//   // simply separated by a ','.
//   setCookie &&
//     setCookie.forEach((item: string) =>
//       item.split(',').forEach(cookie => {
//         const parts = cookie.split(';')[0].split('=');
//
//         if (parts.length > 1) {
//           cookies[parts[0].trim()] = parts[1].trim();
//         }
//       })
//     );
//
//   return cookies;
// };
