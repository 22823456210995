import { useTranslation } from '@mindoktor/localization/src/hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { TIME } from '@mindoktor/patient-app/utils/time/constants';

import { authApi } from '../api/auth';
import { type AuthConfig } from '../api/models/config';

const queryKey = 'authConfig';

export const useAuthConfigApi = () => {
  const notifications = useNotifications();
  const t = useTranslation('error');

  return useQuery<AuthConfig | undefined, ResponseError>(
    [queryKey],
    async () => {
      const response = await authApi.getConfig();
      if (!response?.ok) {
        throw new ResponseError(response);
      }

      return response?.data;
    },
    {
      retry: false,
      staleTime: 30 * TIME.MINUTE,
      onError: (_error) => {
        notifications.showWarning(t('unknown'));
      },
    },
  );
};
