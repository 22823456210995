import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { type ThemeProviderProps } from '@mui/system';

import pulseTheme from './theme';

type PulseThemeProviderProps = Omit<ThemeProviderProps, 'theme'>;

const PulseThemeProvider: React.FC<PulseThemeProviderProps> = ({
  children,
  ...props
}) => (
  <ThemeProvider theme={pulseTheme} {...props}>
    {children}
  </ThemeProvider>
);

export default PulseThemeProvider;
