import { rem } from './conversions';

interface BaseMargin {
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
}

interface ExtraMargin {
  margin?: number;
  marginX?: number;
  marginY?: number;
}

type ReturnMargin<T extends string | number> = {
  [k in keyof BaseMargin]?: T;
};

export interface MarginProps extends BaseMargin, ExtraMargin {}

export const getMargin = <TUnitType extends string | null>(
  {
    margin,
    marginX,
    marginY,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  }: MarginProps,
  unit: TUnitType,
): ReturnMargin<TUnitType extends string ? string : number> | undefined => {
  if (
    ![
      margin,
      marginX,
      marginY,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
    ].some((m) => m != null)
  ) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    marginTop: rem(marginTop ?? marginY ?? margin, unit),
    marginRight: rem(marginRight ?? marginX ?? margin, unit),
    marginBottom: rem(marginBottom ?? marginY ?? margin, unit),
    marginLeft: rem(marginLeft ?? marginX ?? margin, unit),
  } as ReturnMargin<TUnitType extends string ? string : number>;
};
