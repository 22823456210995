import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { type ResponseError } from '@mindoktor/patient-app/api/responseError';

import { marketingConsentApi } from '../api/marketingConsent';
import { type MarketingConsentResponse } from '../api/models/marketingConsent';

export type MarketingConsentResult = UseQueryResult<
  MarketingConsentResponse['data'] | null,
  ResponseError
>;

export const MARKETING_CONSENT_QUERY_KEY = 'MarketingConsent';

export const useMarketingConsentApi = (): MarketingConsentResult => {
  return useQuery<MarketingConsentResponse['data'] | null, ResponseError>(
    [MARKETING_CONSENT_QUERY_KEY],
    async () => {
      const response = await marketingConsentApi.getMarketingConsent();
      if (!response.ok) {
        return null;
      }

      return response.data?.data ?? null;
    },
    {
      staleTime: Infinity, // never consider this data stale
      cacheTime: Infinity, // don't garbage collect this data
    },
  );
};
