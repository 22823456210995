import authEn from './locales/en/auth.json';
import commonEn from './locales/en/common.json';
import errorEn from './locales/en/error.json';
import journalEn from './locales/en/journal.json';
import mainEn from './locales/en/main.json';
import marketingEn from './locales/en/marketing.json';
import menuEn from './locales/en/menu.json';
import messagingEn from './locales/en/messaging.json';
import skandiaEn from './locales/en/skandia.json';
import userEn from './locales/en/user.json';
import vaccinationBookEn from './locales/en/vaccinationBook.json';
import authSv from './locales/sv/auth.json';
import commonSv from './locales/sv/common.json';
import errorSv from './locales/sv/error.json';
import journalSv from './locales/sv/journal.json';
import mainSv from './locales/sv/main.json';
import marketingSv from './locales/sv/marketing.json';
import menuSv from './locales/sv/menu.json';
import messagingSv from './locales/sv/messaging.json';
import skandiaSv from './locales/sv/skandia.json';
import userSv from './locales/sv/user.json';
import vaccinationBookSv from './locales/sv/vaccinationBook.json';

export const resources = {
  en: {
    auth: authEn,
    common: commonEn,
    error: errorEn,
    journal: journalEn,
    main: mainEn,
    marketing: marketingEn,
    menu: menuEn,
    messaging: messagingEn,
    user: userEn,
    vaccinationBook: vaccinationBookEn,
    skandia: skandiaEn,
  },
  sv: {
    auth: authSv,
    common: commonSv,
    error: errorSv,
    journal: journalSv,
    main: mainSv,
    marketing: marketingSv,
    menu: menuSv,
    messaging: messagingSv,
    user: userSv,
    vaccinationBook: vaccinationBookSv,
    skandia: skandiaSv,
  },
};
