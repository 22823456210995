import Config from '@mindoktor/env/Config';

import { ErrorCodes } from './models/error';
import { ApiErrorSchema, LegacyApiErrorSchema } from './schema/errorSchema';
import { type ResponseError } from './responseError';

export const getLegacyErrorMsgKey = (error: ResponseError) => {
  const msg = getLegacyErrorMsgRaw(error);
  return legacyErrorMsgKey(msg);
};

export const getLegacyErrorMsgRaw = (error: ResponseError) => {
  const legacyErrorParseResult = LegacyApiErrorSchema.safeParse(
    error.apiError.data,
  );
  if (legacyErrorParseResult.success) {
    return legacyErrorParseResult.data.msg;
  }
  return 'unknown';
};

export const getErrorMsgKey = (
  error: ResponseError,
  errorMsgKeyProvider: (code: string) => string,
) => {
  const apiErrorParseResult = ApiErrorSchema.safeParse(error.apiError.data);
  if (apiErrorParseResult.success) {
    const error = apiErrorParseResult.data.error;
    if (Config.IsDev) {
      console.log(
        `Backend responded with an error: message: ${error.message} code: ${error.code}`,
      );
    }
    return errorMsgKeyProvider(error.code);
  }
  return getLegacyErrorMsgKey(error);
};

/**
 * legacyErrorMsgKey returns translations key for common error messages based on the
 * provided error code.
 *
 * NOTE: each module/package can have their own implementation of these if
 * desired (see auth and user package).
 */
const legacyErrorMsgKey = (errorCode: string) => {
  switch (errorCode) {
    case ErrorCodes.LNGAccessDenied:
      return 'accessDenied';

    case ErrorCodes.LNGErrInternalSrv:
      return 'internalError';

    case ErrorCodes.LNGErrLoginUnsuccessful:
      return 'loginUnsuccessful';

    case ErrorCodes.LNGInvalidData:
      return 'internalError';

    case ErrorCodes.LNGEmailCollision:
      return 'emailCollision';

    case ErrorCodes.UserEmailInvalid:
      return 'invalidEmail';

    case ErrorCodes.UserPhoneInvalid:
      return 'invalidPhone';

    default:
      return 'unknown';
  }
};
