import {
  type CameraOptions,
  type MediaAsset,
  type MediaPickerOptions,
  MediaType,
} from './types';

export const removeMediaAsset = async (asset: MediaAsset) => {
  URL.revokeObjectURL(asset.uri);
  if (asset.file != null) {
    delete asset.file;
  }
};

const getInputAccept = (mediaType: MediaType) => {
  switch (mediaType) {
    case MediaType.Photo:
      return 'image/*';
    case MediaType.Video:
      return 'video/*,video/mp4';
  }
};

const hasUnsupportedFiles = (files: File[], mediaType: MediaType) => {
  switch (mediaType) {
    case MediaType.Photo:
      return files.some((file) => !file.type.startsWith('image/'));
    case MediaType.Video:
      return files.some((file) => !file.type.startsWith('video/'));
  }
};

const openMediaLibrary = async (
  cameraOptions: CameraOptions,
): Promise<MediaAsset[] | null> => {
  return await new Promise((resolve, reject) => {
    const existingElement = document.querySelector('#mediaPickerInputElement');
    if (existingElement != null) {
      window.document.body.removeChild(existingElement);
    }

    const inputEl = document.createElement('input');

    inputEl.type = 'file';
    inputEl.accept = getInputAccept(cameraOptions.mediaType);
    inputEl.multiple = cameraOptions.limit != null && cameraOptions.limit > 1;
    inputEl.style.display = 'none';
    inputEl.id = 'mediaPickerInputElement';

    inputEl.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      if (target?.files == null || target.files.length === 0) {
        return null;
      }

      const files = Array.from(target.files);

      if (hasUnsupportedFiles(files, cameraOptions.mediaType)) {
        reject(new Error('Unsupported file type'));
        return;
      }

      const mediaAssets = files.map((file) => ({
        name: file.name,
        uri: URL.createObjectURL(file),
        type: file.type,
        file,
      }));

      // TODO: Is this enough for web?
      if (cameraOptions.limit != null) {
        mediaAssets.splice(cameraOptions.limit);
      }

      resolve(mediaAssets);
    };

    window.document.body.appendChild(inputEl);
    inputEl.click();
  });
};

export const openMediaPicker = async ({
  library,
  cameraOptions,
}: MediaPickerOptions): Promise<MediaAsset[] | null> => {
  if (library) {
    return await openMediaLibrary(cameraOptions);
  }

  throw new Error('Camera is not supported in web atm.');
};
