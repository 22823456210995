import { type IPlatform } from './types';

const isWeb = true;
const isNative = false;

const userAgent = (navigator as { userAgent: string }).userAgent;

const isIos =
  /iPad|iPhone|iPod/i.test(userAgent) &&
  !(window as unknown as { MSStream: boolean }).MSStream;

const isAndroid = /Android/i.test(userAgent);

export const platform: IPlatform = { isAndroid, isIos, isNative, isWeb };
