import { type CompatImgSrc } from './types';

export const getImageSrc = (importedImage: CompatImgSrc): string => {
  if (importedImage == null) {
    throw new Error('No image imported');
  }

  if (typeof importedImage === 'string') {
    return importedImage;
  }

  return importedImage.src;
};
