import React, {
  createContext,
  type PropsWithChildren,
  useContext as reactUseContext,
  useEffect,
  useState,
} from 'react';
import Base64 from 'base-64';
import { Centrifuge } from 'centrifuge';

export interface Context {
  centrifuge: Centrifuge | null;
  connected: boolean;
}

// Define a default context value that matches the Context interface
const defaultContextValue: Context = {
  centrifuge: null,
  connected: false,
};

export const RealtimeMessagingContext =
  createContext<Context>(defaultContextValue);

export const useContext = () => reactUseContext(RealtimeMessagingContext);

// To be on the safe-side, update token well before expiry (24h)
const TOKEN_UPDATE_IN = 43200000; // 12h * 60min * 60s * 1000ms;

const shouldUpdateToken = (lastUpdate: number) => {
  return Date.now() - lastUpdate > TOKEN_UPDATE_IN;
};

interface Props {
  token: string | undefined;
}

const extractHostFromToken = (token: string): string | null => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw Error('Invalid RTM token.');
  }
  const data = JSON.parse(Base64.decode(parts[1]));
  const rtmHost: string = data?.rtmHost;
  if (rtmHost != null) {
    return 'wss://' + rtmHost;
  }
  return null;
};

const RealtimeMessagingProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  token,
}) => {
  const [centrifuge, setCentrifuge] = useState<Centrifuge | null>(null);
  const [connected, setConnected] = useState(false);
  const [tokenUpdatedAt, setTokenUpdatedAt] = useState(0);

  useEffect(() => {
    if (token != null) {
      const host = extractHostFromToken(token);
      if (host != null) {
        // check whether necessary and if so, update token:
        if (centrifuge != null && shouldUpdateToken(tokenUpdatedAt)) {
          centrifuge.setToken(token);
          setTokenUpdatedAt(Date.now());
        }
        if (centrifuge == null) {
          const cent = new Centrifuge(host + '/connection/websocket', {
            debug: true,
          });

          setCentrifuge(cent);
          cent.setToken(token);
          setTokenUpdatedAt(Date.now());

          cent.on('connected', () => {
            setConnected(true);
          });
          cent.on('disconnected', () => {
            setConnected(false);
          });
          cent.connect();
        }
      }
    }
  }, [token]);

  useEffect(() => {
    if (token == null && centrifuge != null) {
      centrifuge.disconnect();
      setCentrifuge(null);
    }
  }, [token, centrifuge]);

  return (
    <RealtimeMessagingContext.Provider
      value={{
        centrifuge,
        connected,
      }}
    >
      {children}
    </RealtimeMessagingContext.Provider>
  );
};

export default RealtimeMessagingProvider;
