import React from 'react';

import { ThemeProvider } from '@mui/material/styles';

import { aphTheme } from '../theme.web';

const APHThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={aphTheme}>{children}</ThemeProvider>
);

export default APHThemeProvider;
