import React from 'react';

import { type SxProps, type Theme } from '@mui/material';
import Stack from '@mui/material/Box';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const BottomSheetContent: React.FC<Props> = ({ children, sx }) => (
  <Stack
    sx={[
      {
        paddingTop: '1rem',
        paddingBottom: '2.5rem',
        paddingX: '2rem',
        // Scrolling props
        overflowY: 'scroll',
        // Hide scrollbars
        scrollbarWidth: 'none', // Standard, Firefox
        '&::-webkit-scrollbar': {
          // Webkit, Blink
          width: 0,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
  </Stack>
);

export default BottomSheetContent;
