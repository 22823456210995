import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import closeIconSource from '../images/icons/ic_close.svg';

class Close extends React.Component {
  render() {
    const { onClick, classes, className } = this.props;

    return (
      <IconButton
        color="secondary"
        onClick={onClick}
        className={classNames(classes.root, className)}
      >
        <Img alt="icon" src={closeIconSource} className={classes.icon} />
      </IconButton>
    );
  }
}

export default withStyles(() => ({
  root: {
    height: 56,
    width: 56,
    padding: 0,
  },

  icon: {
    height: 18,
  },
}))(Close);
