import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { colors } from '@mindoktor/pulse/src/styles/colors';
import { percent, rem } from '@mindoktor/pulse/src/styles/conversions';

import { useUserProfile } from '@mindoktor/patient-app/adapters/hooks/useUserProfile';

import { formatShortDateTime } from '../../../../utils/time/formatShortDateTime';
import { formatFullName } from '../../../../utils/user/formatFullName';
import { isOwnMessage } from '../../../api/models/conversation';
import { MAX_USER_CARD_SCREEN_WIDTH_RATIO } from '../../../constants';
import MessageAvatar from '../../MessageAvatar/MessageAvatar.web';

import { type UserMessageCardProps } from './types';

const ownCardBgColor = colors.red[200];
const sharpCornerRadius = 2;

interface InnerMessageCardProps {
  isOwn?: boolean;
  align?: 'left' | 'right';
  avatar?: React.ReactNode;
  author?: { firstName: string; lastName: string } | null;
  created: string;
  padding?: number;
  backgroundColor?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
}

const InnerMessageCard: React.FC<InnerMessageCardProps> = ({
  isOwn = false,
  align = 'left',
  avatar,
  author,
  created,
  padding = 1,
  backgroundColor,
  fullWidth = false,
  children,
}) => {
  return (
    <Stack
      direction={align === 'left' ? 'row' : 'row-reverse'}
      spacing="0.5rem"
      justifyContent="flex-start"
      sx={{ overflow: 'visible' }}
    >
      {avatar != null && (
        <Stack direction="column" justifyContent="flex-end">
          {avatar}
        </Stack>
      )}
      <Stack
        maxWidth={percent(MAX_USER_CARD_SCREEN_WIDTH_RATIO)}
        width={fullWidth ? percent(MAX_USER_CARD_SCREEN_WIDTH_RATIO) : 'unset'}
        paddingRight="unset"
        gap={0.5}
        alignItems={isOwn ? 'flex-end' : undefined}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1}
          paddingX={0.5}
          color={({ palette }) => palette.grey[700]}
        >
          <Box>
            {author != null && !isOwn && (
              <Typography fontSize={11}>
                {formatFullName(author.firstName, author.lastName)}
              </Typography>
            )}
          </Box>
          <Typography fontSize={11}>{formatShortDateTime(created)}</Typography>
        </Stack>
        <Stack width="100%">
          <Card
            sx={{
              ...(isOwn ? { backgroundColor: ownCardBgColor } : {}),
              ...(backgroundColor != null ? { backgroundColor } : {}),
              '&.MuiPaper-rounded': {
                borderBottomLeftRadius:
                  align === 'left' ? sharpCornerRadius : undefined,
                borderBottomRightRadius:
                  align === 'left' ? undefined : sharpCornerRadius,
                padding: rem(padding),
              },
            }}
          >
            {children ?? null}
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};

const UserMessageCard: React.FC<
  React.PropsWithChildren<UserMessageCardProps>
> = ({ message, padding, backgroundColor, fullWidth, children }) => {
  const userProfile = useUserProfile();
  const author = message?.author;
  const isOwn = isOwnMessage(message.author, userProfile?.userId);

  if (isOwn) {
    return (
      <InnerMessageCard
        isOwn={isOwn}
        align="right"
        author={author}
        created={message.created}
        padding={padding}
        backgroundColor={backgroundColor}
        fullWidth={fullWidth}
      >
        {children}
      </InnerMessageCard>
    );
  }

  return (
    <InnerMessageCard
      author={author}
      created={message.created}
      avatar={
        <MessageAvatar
          firstName={author?.firstName}
          lastName={author?.lastName}
          src={author?.avatarURL}
        />
      }
      padding={padding}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
    >
      {children}
    </InnerMessageCard>
  );
};

export default UserMessageCard;
